import { MONTHS } from './utilsGeneral';
import { icGraduation, icIbero, icLogo, icWedding } from '../assets';
import { colors } from '../values/colors';

export const amountFormat = (amount) => {
  if (typeof amount === 'string' || amount instanceof String) {
    amount = parseFloat(amount);
  }

  const strAmount = spanishSeparator(amount.toFixed(2));

  return `$${strAmount}`;
};

function spanishSeparator(str) {
  return addSeparatorsNF(str, '.', '.', ',');
}

function addSeparatorsNF(nStr, inD, outD, sep) {
  nStr += '';
  const dpos = nStr.indexOf(inD);
  let nStrEnd = '';
  if (dpos !== -1) {
    nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
    nStr = nStr.substring(0, dpos);
  }
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(nStr)) {
    nStr = nStr.replace(rgx, '$1' + sep + '$2');
  }
  return nStr + nStrEnd;
}

export const getDayDate = (date) => {
  const dateFormat = new Date(date);
  const getDay = dateFormat.getDate();
  return getDay;
};

export const getMonthsFormat = (date) => {
  const dateFormat = new Date(date);
  const getMonth = dateFormat.getMonth();
  return MONTHS[getMonth];
};

export const dateFormatDDHH = (date) => {
  const dateFormat = new Date(date);
  const dateDDHH = dateFormat.toLocaleString();
  return dateDDHH;
};

export const dateFormatDateTimeMX = (date) => {
  const dateFormat = new Date(date);
  const formattedDate = `${dateFormat.getDate().toString().padStart(2, '0')}-${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}-${dateFormat.getFullYear().toString()} ${dateFormat.getHours().toString().padStart(2, '0')}:${dateFormat.getMinutes().toString().padStart(2, '0')}:${dateFormat.getSeconds().toString().padStart(2, '0')}`;
  return formattedDate;
};

export const getIcon = (type) => {
  let img = null;
  switch (type) {
    case 1:
    case 5:
      img = icIbero;
      break;
    case 2:
    case 6:
      img = icLogo;
      break;
    case 3:
    case 7:
      img = icWedding;
      break;
    case 4:
    case 8:
      img = icGraduation;
      break;
    default:
      img = icIbero;
      break;
  }
  return img;
};

export const getColor = (type) => {
  let color = null;
  switch (type) {
    case 1:
    case 5:
      color = colors.red;
      break;
    case 2:
    case 6:
      color = colors.blue;
      break;
    case 3:
    case 7:
      color = colors.yellow;
      break;
    case 4:
    case 8:
      color = colors.purple;
      break;
    default:
      color = colors.yellow;
      break;
  }
  return color;
};

export const getTitle = (type) => {
  let title = null;
  switch (type) {
    case 1:
    case 5:
      title = 'Graduación 1';
      break;
    case 2:
    case 6:
      title = 'Graduación 2';
      break;
    case 3:
    case 7:
      title = 'Boda 1';
      break;
    case 4:
    case 8:
      title = 'Graduación 3';
      break;
    default:
      title = 'Graduación 1';
      break;
  }
  return title;
};

export const getUrlParams = (params) => {
  const queryParamsString = Object.keys(params)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
  return queryParamsString;
};

import React, { useState, useEffect, useRef } from 'react';
import { InputAdornment, IconButton, Grid, Button, FormControlLabel, Checkbox } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  CustomizedFormHelperText,
  CustomizedTextField,
  CustomizedTypography,
  CustomizedLoadingButton,
  SimpleLoadingButton,
  Loader,
  AlertCustom,
  CheckBoxCircule
} from '../../../../componets';

/**UTILS**/
import validations from './validations';
import initialValues from './initialValues';
import { colors } from '../../../../values/colors';
import k from '../../../../values/key';
import s from '../../../../strings/index';

import ValidUser from '../ValidUser';
import { LOGIN_API, VALIDATE_USER_API } from '../../../../api';
import { getMessageError } from '../../../../api/conf';
import {
  getUserRecord,
  removeUserRecord,
  setSession,
  setUserRecord
} from '../../../../utils/fuctionUserSession';
import './styles.css';
import { styled, useTheme } from '@mui/material/styles';

const FormLogin = () => {

  const navigate = useNavigate();
  const params = useParams();
  const loginForm = useRef();
  const theme = useTheme();

  console.log('theme =>', theme);

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [isScreenValidation, setIsScreenValidation] = useState(false);
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [dataUser, setDataUser] = useState(null);
  const [checkSelectedRecord, setCheckSelectedRecord] = useState(false);

  useEffect(() => {
    setLoadingScreen(true);
    const getUserRecordLocal = async () => {
      const valuesRecordUser = await getUserRecord();
      if (valuesRecordUser && valuesRecordUser !== '{}') {
        const { email, password } = valuesRecordUser;
        loginForm?.current?.setFieldValue('email', email);
        loginForm?.current?.setFieldValue('password', password);
        setCheckSelectedRecord(true);
        window.setTimeout(() => {
          loginForm?.current?.setFieldTouched('email', true);
          loginForm?.current?.setFieldTouched('password', true);
        }, 100);
        setLoadingScreen(false);
      } else {
        setLoadingScreen(false);
      }
    };
    getUserRecordLocal();
  }, []);

  useEffect(() => {
    setLoadingScreen(true);
    if (params && params.id && params.token) {
      setLoadingScreen(true);
      const { id, token } = params;
      VALIDATE_USER_API(id, token, onSuccessValidate, onErrorValidate, onDone);
    }
  }, [params]);

  const onSuccessValidate = async (response) => {
    const {
      data: { data, success }
    } = response;
    if (success) {
      setDataUser(data);
      setIsScreenValidation(true);
      setLoadingScreen(false);
    } else {
      setLoadingScreen(true);
      setLoadingScreen(false);
    }
  };

  const goToMainSingin = async () => {
    await setSession(dataUser);
    setIsScreenValidation(false);
    navigate(k.navigation.events, { replace: true });
    window.location.reload();
  };

  const onErrorValidate = (error) => {
    setIsLoadingButton(false);
    setLoadingScreen(false);
    //navigate(k.navigation.login, { replace: true });
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      showError(message);
    } else {
      showError(s.errorGeneral);
    }
  };

  const loginAction = async (values, resetForm) => {
    setIsLoadingButton(true);
    const { email, password } = values;
    const data = {
      username: email,
      password
    };
    await LOGIN_API(
      data,
      (response) => loginOnSuccess(response, resetForm, values),
      onErrorLogin,
      onDone
    );
  };

  const loginOnSuccess = async (response, resetForm, values) => {
    try {
      const {
        data: { data, success }
      } = response;
      if (success) {
        if (checkSelectedRecord) {
          await setUserRecord(values);
        } else {
          await removeUserRecord();
        }
        await setSession(data);
        resetForm();
        setIsLoadingButton(false);
        setLoadingScreen(false);
        navigate(k.navigation.events, { replace: true });
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
      setIsLoadingButton(false);
      setLoadingScreen(false);
    }
  };

  const onErrorLogin = (error) => {
    setIsLoadingButton(false);
    setLoadingScreen(false);
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      showError(message || getMessageError(error));
    } else {
      showError(getMessageError(error));
    }
  };

  const onDone = (response) => {
    setIsLoadingButton(false);
    setLoadingScreen(false);
  };

  const showError = (message) => {
    setErrorMessage(message);
    setShowAlert(true);
    setLoadingScreen(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (loadingScreen) {
    return <Loader />;
  }

  return (
    <>
      {isScreenValidation ? (
        <ValidUser
          dataUser={dataUser}
          isLoadingButton={isLoadingButton}
          actionValidUser={async () => {
            goToMainSingin();
          }}
        />
      ) : (
        <>
          {showAlert && <AlertCustom errorMessage={errorMessage} />}
          <Formik
            innerRef={loginForm}
            validationSchema={validations}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => loginAction(values, resetForm)}>
            {({ values, errors, touched, isValid }) => (
              <Form className='content'>
                <CustomizedTypography size={'1.7rem'} variant={'h2'} component={'div'} colors={colors.blackTicket}>
                  Ingresa tus datos
                </CustomizedTypography>
                <Grid item xs={12} lg={12}>
                  <>
                    <Field
                      sx={{ mt: 4 }}
                      as={CustomizedTextField}
                      variant={'filled'}
                      margin={'normal'}
                      fullWidth
                      name={'email'}
                      label={s.email}
                      id={'email'}
                      autoComplete={'email'}
                    />
                    {touched.email && errors.email && (
                      <CustomizedFormHelperText color={colors.blackTicket}>{errors.email}</CustomizedFormHelperText>
                    )}
                  </>
                  <Field
                    sx={{ mt: 4 }}
                    as={CustomizedTextField}
                    type={showHidePassword ? 'text' : 'password'}
                    variant={'filled'}
                    margin={'normal'}
                    fullWidth
                    name={'password'}
                    label={s.password}
                    id={'password'}
                    autoComplete={'current-password'}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position={'end'}>
                          <IconButton
                            color={'primary'}
                            aria-label={'toggle password visibility'}
                            onClick={() => setShowHidePassword(!showHidePassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge={'end'}>
                            {showHidePassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {touched.password && errors.password && (
                    <CustomizedFormHelperText color={colors.blackTicket}>{errors.password}</CustomizedFormHelperText>
                  )}
                  <CheckBoxCircule
                    actionCheck={() => {
                      setCheckSelectedRecord(!checkSelectedRecord);
                    }}
                    checked={checkSelectedRecord}
                    color={values.email && values.password ? colors.white : colors.textDisabled}
                    disabled={!(values.email && values.password)}
                    fontSizeCheck={'1.5rem'}
                    isCheckdefault={true}
                    sizeTitleCheck={'0.9rem'}
                    title={s.record}
                  />
                  <div className='linkStyle'>
                    <div>
                      <CustomizedTypography size={'1.2rem'} mr={0.5} variant={'h6'} colors={colors.blackTicket}>
                        {s.questionAccount}
                      </CustomizedTypography>
                    </div>
                    <div
                      onClick={() => {
                        navigate(k.navigation.createAccount);
                      }}>
                      <CustomizedTypography
                        size={'1.2rem'}
                        fontWeight={700}
                        colors={theme.myColors.blue}
                        variant={'h6'}
                      >
                        {s.registre}
                      </CustomizedTypography>
                    </div>
                  </div>
                  <div className='margin'>
                    <SimpleLoadingButton 
                      color="disabled" 
                      disabled={!isValid}
                      fullWidth
                      loading={isLoadingButton}
                      type={'submit'}
                      variant='contained'
                      widthbottom={'20rem'}
                    >
                      {s.enter}
                    </SimpleLoadingButton>
                  </div>
                  <div
                    onClick={() => {
                      navigate(k.navigation.terms);
                    }}
                    className='containerTerminus'>
                    <CustomizedTypography size={'1rem'} mr={0.5} variant={'h6'} colors={colors.blackTicket}>
                      {s.terminus}
                    </CustomizedTypography>
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default FormLogin;

import React from 'react';
import {
  CheckBoxCircule,
  CustomizedFormHelperText,
  CustomizedTextField
} from '../../../../../componets';
import { colors } from '../../../../../values/colors';
import s from '../../../../../strings';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

export const CheckBoxComponent = ({
  setCheckSelectedTerms,
  checkSelectedTerm,
  setFieldValue,
  actionTitle,
  actionSubtitle=() => console.log('Sin acción'),
  onlyTitle=false,
  textColor=colors.white
}) => {
  return (
    <CheckBoxCircule
      actionCheck={() => {
        setFieldValue('terminusConditions', !checkSelectedTerm);
        setCheckSelectedTerms(!checkSelectedTerm);
      }}
      actionSubtitle={actionSubtitle}
      actionTitle={actionTitle}
      color={textColor}
      fontSizeCheck={'1.5rem'}
      isCheckdefault={true}
      sizeSubtitleCheck={'0.9rem'}
      sizeTitleCheck={'0.9rem'}
      subtitle={onlyTitle ? '' : 'y aviso de privacidad'}
      subtitleColors={textColor}
      title={onlyTitle ? s.acceptTermsAndConditions : s.terminus}
    />
  );
};

export const FieldPasswordCustom = ({
  showHidePassword,
  setShowHidePassword,
  handleMouseDownPassword,
  touched,
  errors,
  id
}) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Field
        as={CustomizedTextField}
        autoComplete={'password'}
        fullWidth
        id={id}
        label={s.password}
        margin={'normal'}
        name={'password'}
        type={showHidePassword ? 'text' : 'password'}
        variant={'filled'}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position={'end'}>
              <IconButton
                color={'primary'}
                aria-label={'toggle password visibility'}
                onClick={() => setShowHidePassword(!showHidePassword)}
                onMouseDown={handleMouseDownPassword}
                edge={'end'}>
                {showHidePassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {touched.password && errors.password && (
        <CustomizedFormHelperText color={colors.blackTicket}>{errors.password}</CustomizedFormHelperText>
      )}
    </Grid>
  );
};

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { fabIcon } from '../assets';

const HeaderFavIcon = ({ title }) => {
  const titleCom = title ? title : 'Matatena Tickets';
  return (
    <Helmet>
      <link rel={'shortcut icon'} href={fabIcon} type={'image/x-icon'} />
      <title>{titleCom}</title>
    </Helmet>
  );
};

export default HeaderFavIcon;

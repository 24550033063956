import React, { useCallback } from 'react';

export const useCommission = () => {

	const stringToNumber = useCallback(
		(value, decimals=2) => (typeof(value) === 'string') ? Number(parseFloat(value).toFixed(decimals)) : value
	);
	
	const roundNumber = useCallback(
		(value, decimals=2, acceptCents=true) => {
			value = stringToNumber(value);
			if (acceptCents) {
				return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
			} else {
				const roundedValue = Math.ceil(value * 2) / 2; // Redondeo hacia arriba a múltiplos de 0.50
				return Number(roundedValue.toFixed(decimals));
			}
		},
		[stringToNumber]
	);
	
	const calcularComision = useCallback(
		(amount = 100, percent = 3.9, tarifaFija = 0, iva = 16, redondear = true) => {
			
			amount = stringToNumber(amount);
			percent = stringToNumber(percent);
			tarifaFija = stringToNumber(tarifaFija);
			iva = stringToNumber(iva);

			percent = percent / 100; // Convertir a decimal
			iva = iva / 100; // Convertir a decimal
			const comision = ((amount * percent) + tarifaFija) * ( 1 + iva);
			return redondear ? roundNumber(comision, 2) : comision;
		},
		[stringToNumber, roundNumber]
	);

	const calcularMontoRecibido = useCallback(
		(amount = 100, percent = 3.9, tarifaFija = 0, iva = 16, redondear = true) => {
			amount = stringToNumber(amount);
			
			const restante = amount - calcularComision(amount, percent, tarifaFija, iva, false);
			return redondear ? roundNumber(restante, 2) : restante;
		},
		[stringToNumber, roundNumber, calcularComision]
	);

	const calcularMontoACobrar = useCallback(
		(restanteDeseado = 100, percent = 3.9, tarifaFija = 0, iva = 16, acceptCents = true, redondear = true) => {
			iva /= 100;		// Convertir a decimal
			percent /= 100;	// Convertir a decimal

			restanteDeseado = stringToNumber(restanteDeseado);
			percent = stringToNumber(percent);
			tarifaFija = stringToNumber(tarifaFija);
			iva = stringToNumber(iva);

			const up = restanteDeseado+((1+iva)*tarifaFija)
			const down = ((-1-iva)*percent)+1

			console.log('up =>', up)
			console.log('down =>', down)

			return redondear ? roundNumber(up/down, 2, acceptCents) : restante;
		},
		[stringToNumber, roundNumber]
	);
	
	return { calcularComision, calcularMontoRecibido, calcularMontoACobrar, roundNumber };
}

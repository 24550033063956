import * as React from 'react';
import { Select, FormControl, MenuItem, InputLabel, OutlinedInput } from '@mui/material';
import { colors } from '../../values/colors';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '7rem'
    }
  }
};

const MultipleSelect = ({ data, valor, handleChangeSelect, title, colorValue, widthSelect, isActive=true }) => {


  return (
    <div>
      <FormControl sx={{ width: widthSelect ? widthSelect : '7rem' }} disabled={!isActive}>
        {title && <InputLabel id={'demo-multiple-name-label'}>{title}</InputLabel>}
        <Select
          key={'1'}
          sx={{
            width: widthSelect ? widthSelect : '7rem',
            height: '2.5rem',
            color: colorValue ? colorValue : colors.black,
            fontSize: '1.2rem'
          }}
          labelId={'demo-multiple-checkbox-label'}
          id={'demo-multiple-checkbox'}
          value={valor}
          onChange={handleChangeSelect}
          input={<OutlinedInput label={'Tag'} />}
          MenuProps={MenuProps}>
          {data.map((valueData, index) => {

            return (
              <MenuItem
                sx={{
                  color: colorValue ? colorValue : colors.black,
                  fontSize: '0.8rem'
                }}
                key={valueData.id}
                value={(valueData.amount) ? valueData.amount : valueData.id}>
                {(valueData.name) ? valueData.name : valueData.id}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelect;

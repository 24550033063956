import { colors } from '../../colors';

export default {
  primary: {
    light: colors.yellow,
    main: colors.yellow,
    dark: colors.yellow,
    contrastText: colors.black
  },
  secondary: {
    light: '#ff7961',
    main: '#f44336',
    dark: '#ba000d',
    contrastText: colors.white
  },
  custom: {
    light: '#ffa726',
    main: '#f57c00',
    dark: '#ef6c00',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  }
};

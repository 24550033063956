import { Box, Grid } from '@mui/material';
import { SubtitleTitleEvent } from '../../DetailsEventScreen';
import { icDelete, icEdit } from '../../../../assets';
import { CheckBoxCircule, CustomizedButton } from '../../../../componets';
import { colors } from '../../../../values/colors';
import '../styles.css';

const SectionItem = ({ title, subtitle, fontSize }) => {
  return (
    <Grid item xs={12} mr={2}>
      <SubtitleTitleEvent fontSize={'0.8rem'} subtitle={title} />
      <SubtitleTitleEvent fontSize={fontSize ? fontSize : '1rem'} subtitle={subtitle} />
    </Grid>
  );
};

const ListGuests = ({ listGuest, setListGuest, setCheckSelected, editGuest, deleteGuest }) => {
  return (
    <Box className='containerList'>
      {listGuest.map((guest) => (
        // eslint-disable-next-line react/jsx-key
        <Box className='containerBoxList} key={guest.id'>
          <Grid container item xs={7} className='card'>
            <Grid item xs={10}>
              <Grid item xs={12} className='containerItem'>
                {guest.full_name && (
                  <Grid item xs={8}>
                    <SubtitleTitleEvent fontSize={'1.3rem'} subtitle={guest.full_name} />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <SubtitleTitleEvent fontSize={'0.7rem'} subtitle={'Liga de pago enviada'} />
                </Grid>
              </Grid>
              <Grid item xs={10} className='containerItem'>
                {guest.tickets && <SectionItem title={'Boletos'} subtitle={guest.tickets} />}
                {guest.tables && <SectionItem title={'Mesas'} subtitle={guest.tables} />}
                {guest.email && <SectionItem title={'Correo'} subtitle={guest.email} />}
                {guest.phone && <SectionItem title={'Teléfono'} subtitle={guest.phone} />}
              </Grid>
            </Grid>
            <Grid item xs={2} className='containerAction'>
              <Grid item xs={2}>
                <div onClick={() => editGuest(guest)}>
                  <img src={icEdit} className='iconEdit' alt='edit' />
                </div>
                <div onClick={() => deleteGuest(guest)}>
                  <img src={icDelete} className='iconDelete' alt='delete' />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={5} className='cardButton'>
            <CheckBoxCircule
              setCheckSelected={setCheckSelected}
              actionCheck={() => {
                setListGuest(
                  listGuest.map((itemGuest) => {
                    if (itemGuest.id === guest.id) {
                      return { ...itemGuest, selected: !itemGuest.selected };
                    }
                    return itemGuest;
                  })
                );
              }}
              isCheckdefault={true}
            />
            <CustomizedButton
              bgcolor={colors.redLight}
              width={'7.5rem'}
              disabled={!guest.selected}
              maxwidth={'15rem'}
              maxheight={'3.3rem'}
              colortext={colors.white}
              bgcolorinactive={colors.grayInactive}
              variant={'contained'}>
              Enviar línea de pago
            </CustomizedButton>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default ListGuests;

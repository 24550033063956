import React, { useEffect, useState } from 'react';
import { AlertCustom, CustomizedTypography, Loader } from '../../../componets';
import { Box, Grid } from '@mui/material';
import CustomizedCardContainer from '../../../componets/CustomizedComponents/CustomizedCardContainer';
import { GET_PRIVACY_API } from '../../../api';
import { colors } from '../../../values/colors';
import { getMessageError } from '../../../api/conf';
import './styles.css';

const PrivacyPolicy = () => {

  const [privacy, setPrivacy] = useState(null);
  const [isLoadingView, setIsLoadingView] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const getPrivacy = async () => {
      await GET_PRIVACY_API(onSuccessGetPrivacy, onErrorGetPrivacy, onDoneGetPrivacy);
    };
    getPrivacy();
  });

  const onSuccessGetPrivacy = (response) => {
    const {
      data: { body }
    } = response;
    setPrivacy(body);
    setIsLoadingView(false);
  };
  const onErrorGetPrivacy = (error) => {
    const {
      response: {
        data: { message }
      }
    } = error;
    showAlertMessage(message || getMessageError(error));
  };
  const showAlertMessage = (message) => {
    setErrorMessage(message);
    setShowAlert(true);
  };
  const onDoneGetPrivacy = () => {
    setIsLoadingView(false);
  };
  if (isLoadingView) {
    return (
      <Box className='containerLoader'>
        <Grid container item xs={12}>
          <Loader />
        </Grid>
      </Box>
    );
  }
  return (
    <Box className='root'>
      {showAlert && <AlertCustom errorMessage={errorMessage} />}
      <Grid container className='containerRoot'>
        <CustomizedCardContainer padding={'1rem'} width={'100%'} isbgbackgroundblack={'true'}>
          <Grid container item xs={12} className='containerBodyCard'>
            {privacy && (
              <CustomizedTypography
                colors={colors.black}
                size={'1rem'}
                dangerouslySetInnerHTML={{ __html: privacy }}
              />
            )}
          </Grid>
        </CustomizedCardContainer>
      </Grid>
    </Box>
  );
};

export default PrivacyPolicy;

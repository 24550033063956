import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Avatar, Box, Grid, List, ListItem, ListItemText, Typography, ListItemIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Loader, AlertCustom, CustomizedCardContainer, EventHeader, CustomizedTypography } from '../../../componets';
import CardHeaderTitle from '../../../componets/CardHeaderTitle';
import { BoxRoot, GridHeader } from './styled_components';
import { BodyCard, CardRootCustom } from '../../../componets/CardCustom';
import CardHeaderDetailEvent from '../../../componets/CardHeaderDetailEvent';
import { colors } from '../../../values/colors';
import { useAuth } from '../../../contexts/AuthContext';
import CheckIcon from '@mui/icons-material/Check';

const SelectionCompleted = () => {

	const { user } = useAuth();
	console.log("<<<<<<<<<< message user: ", user);

	const params = useParams();
	console.log("<<<<<<<<<< message params: ", params);

	const navigate = useNavigate();
	console.log("<<<<<<<<<< message navigate: ", navigate);

	const location = useLocation();
	console.log("<<<<<<<<<< message location: ", location);
	const {state: {choosenTables, event}} = useLocation();

	const [isLoadingView, setIsLoadingView] = useState(false);
	const theme = useTheme();

	const large_xs = (event?.image) ? 9 : 12;
	const large_sm = (event?.image) ? 8 : 12;

	const GridLoader = () => {
	  return (
		<Grid container item xs={12} className={theme.myStyles.containerLoader}>
			<Loader />
		</Grid>
	  )
	}
	
	const Body = () => {

		console.log("<<<<<< event", event);

		return (<>
			<CardHeaderTitle title={'Selección de mesas completada'} />
			<Grid mt={2} container item xs={12} sm={12} md={12}>
				<Grid item xs={12} sm={12} md={12}>
					<CardRootCustom>
						<CardHeaderDetailEvent data={event} />
						<BodyCard bgColor={colors.white}>
							<Grid mt={2} container item xs={12} sm={12} md={12}>
								{
									event?.image && (
										<Grid container item xs={3} sm={4}>
											<Box
												component="img" 
												src={event.image} 
												sx={{
													maxWidth: '100%',
													height: 'auto',
												}} 
												loading="lazy" 
												alt={event.image}
											/>
										</Grid>
									)
								}
								
								<Grid container item xs={large_xs} sm={large_sm}>
									<Grid item xs={12}>
										<Typography variant="h3" gutterBottom align="center">
											{user.name}
										</Typography>
										<CustomizedTypography align='left' size={'1rem'} colors='#000' px={1}>
											{'Confirmamos tu reservación:'}
										</CustomizedTypography>
										<List>
											{
												choosenTables.map(table => (
													<ListItem key={table.id}>
														<ListItemText 
															primary={`${table.name} para ${table.size} personas`}
															primaryTypographyProps={{
																fontSize: 20,
															}}
														/>
													</ListItem>
												))
											}
										</List>
										<CustomizedTypography align='left' size={'1rem'} colors='#000'>
											{`*En caso de requierir alguna aclaración, comunícate al correo: ${event?.contact_email}`}
										</CustomizedTypography>
									</Grid>
								</Grid>
							</Grid>
						</BodyCard>
					</CardRootCustom>
				</Grid>
			</Grid>
		</>)
	}

	/* return (
		<Box className={classes.containerRootShopping}>
			<Grid container item xs={12} className={classes.titleHeaderContainer}>
				<CustomizedCardContainer
					padding={isLoader ? '10rem' : '2rem'}
					width={'95%'}
					isbgbackgroundblack={'true'}>
					{showAlert && <AlertCustom typeAlert={typeAlert} errorMessage={errorMessage} />}
					<>
						{(isLoadingView && GridLoader())}
						{(isLoadingView && <GridLoader/>)}
					</>
				</CustomizedCardContainer>
			</Grid>
		</Box>
	) */

	return (
		<BoxRoot>
			<GridHeader item xs={12}>
				<CustomizedCardContainer
					padding={isLoadingView ? '10rem' : '2rem'}
					width={'95%'}
					isbgbackgroundblack={'true'}>
						{isLoadingView ? (<GridLoader/>) : (<Body/>)}
				</CustomizedCardContainer>
			</GridHeader>
		</BoxRoot>
	)
	
	return (
		<Box className={theme.myStyles.cardRoot}>
			<Grid container className={theme.myStyles.containerRoot}>
				<CustomizedCardContainer padding={'1rem'} width={'100%'} isbgbackgroundblack={'true'}>
					<EventHeader eventData={event} isActive/>
          			<Grid container item xs={12} className={theme.containerBodyCard}>
						<GridLoader/>
					</Grid>
				</CustomizedCardContainer>
			</Grid>
		</Box>
	)
}

export default SelectionCompleted;
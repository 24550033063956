import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

const CountdownTimer = ({countType='minutes', minutes=10, dateStart=new Date(), dateEnd=null}) => {
	const type_list = ['minutes', 'dates'];
	const selected_type = type_list.includes(countType) ? countType : type_list[0];
	const [timer, setTimer] = useState('');

	if(selected_type === 'minutes'){
		dateEnd = new Date(dateStart.valueOf())
		dateEnd.setMinutes(dateEnd.getMinutes() + minutes);
	}

	useEffect(() => {
		const interval = setInterval(() => {
			const remainingTime = dateEnd - dateStart;
			if (remainingTime <= 0) {
				clearInterval(interval);
				setTimer('Finalizado');
			} else {
				const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
				const hours = Math.floor(
					(remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				const minutes = Math.floor(
					(remainingTime % (1000 * 60 * 60)) / (1000 * 60)
				);
				const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
				let countdownText = '';
				if (days > 0) {
					countdownText += `${days}d `;
				}
				if (hours > 0) {
					countdownText += `${hours}h `;
				}
				if (minutes > 0) {
					countdownText += `${minutes}m `;
				}
				countdownText += `${seconds}s`;
				setTimer(countdownText);
				dateStart.setSeconds(dateStart.getSeconds() + 1);
			}
		}, 1000);
		
		return () => clearInterval(interval);
	}, [dateStart, dateEnd]);

	return {timer, setTimer}
	
	/* return (
		<Box>
			{timer}
		</Box>
	); */
}

export default CountdownTimer;
import { GET_AUTH, POST_AUTH, getMessageError } from '../conf';
import paths from './paths';

export const POST_BOOK_EVENT_API = async (code, data, onSuccess, onError, onDone) => {
  await POST_AUTH(`${paths.event}${code}/book_seats/`, data, onSuccess, onError, onDone);
};

export const GET_EVENT_PRIVATE_USER_API = async (code, onSuccess, onError, onDone) => {
  await GET_AUTH(`${paths.event}${code}/private_user/`, onSuccess, onError, onDone);
};

export const GET_EVENTS_BY_CODE_API = async (code, onSuccess, onError, onDone) => {
  await GET_AUTH(`${paths.event}${code}`, onSuccess, onError, onDone);
};

export const GET_EVENTS_BY_FILTERS_API = async (page, rowPage, onSuccess, onError, onDone) => {
  let url = paths.event;
  if (page && rowPage) {
    url = `${paths.event}?p=${page}&page_size=${rowPage}`;
  }
  await GET_AUTH(url, onSuccess, onError, onDone);
};

export const GET_CATEGORY_EVENT_API = async (onSuccess, onError, onDone) => {
  await GET_AUTH(`${paths.category}`, onSuccess, onError, onDone);
};

export const GET_EVENTS_BY_FILTERS = async (
  page,
  rowPage,
  date_start,
  date_end,
  category,
  onSuccess,
  onError,
  onDone
) => {
  let url = paths.event;
  /**PAGINACION*/
  if (page && rowPage) {
    url = `${paths.event}?p=${page}&page_size=${rowPage}`;
  }
  /**CATEGORIA Y FECHA INICIO Y FECHA FIN*/
  if (date_start && date_end && category) {
    url = `${paths.event}?date_start=${date_start}&date_end=${date_end}&${category}`;
  } else {
    /**SOLO FECHA*/
    if (date_start || date_end) {
      if (date_start && date_end) {
        url = `${paths.event}?date_start=${date_start}&date_end=${date_end}`;
      } else if (date_start) {
        url = `${paths.event}?date_start=${date_start}`;
      } else if (date_end) {
        url = `${paths.event}?date_end=${date_end}`;
      }
    }
    /**SOLO CATEGORIA*/
    if (category) {
      url = `${paths.event}?${category}`;
    }
  }
  await GET_AUTH(url, onSuccess, onError, onDone);
};

export const GET_MY_EVENTS = async (userId, onSuccess, onError, onDone) => {
  const url = paths.myEvents.replace(':id_user:', userId);
  await GET_AUTH(url, onSuccess, onError, onDone);
}

export const GET_ORDER_OF_MY_EVENTS = async (userId, code, onSuccess, onError, onDone) => {
  const url = paths.myEventOrders.replace(':id_user:', userId).replace(':code:', code);
  await GET_AUTH(url, onSuccess, onError, onDone);
}

export const GET_EVENT_PAY_METHODS = async (code, onSuccess, onError, onDone) => {
  await GET_AUTH(`${paths.event}${code}/pay_methods/`, onSuccess, onError, onDone);
};

export const DEFAULT_ERROR_PROCESS = (error) => {
  if (error?.response?.data) {
    const {
      response: {
        data: { message }
      }
    } = error;
    return message || getMessageError(error);
  } else {
    return getMessageError(error);
  }
}
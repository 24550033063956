import { GET_AUTH, POST_AUTH } from '../conf';
import path from './path';

export const PAYMENT_EVENT_API = async (eventCode, data, onSuccess, onError, onDone) => {
  const url = `${path.paymentEvent}${eventCode}${path.payment}`;
  await POST_AUTH(url, data, onSuccess, onError, onDone);
};

export const PAYMENT_EVENT_ORDER_API = async (orderNumber, data, onSuccess, onError, onDone) => {
  await POST_AUTH(
    `${path.orders}${orderNumber}${path.addTransaction}`,
    data,
    onSuccess,
    onError,
    onDone
  );
};

export const GET_PAYMENTS_ORDER = async (orderNumber, data, onSuccess, onError, onDone) => {
  await GET_AUTH(
    `${path.orders}${orderNumber}${path.transaction}`,
    data,
    onSuccess,
    onError,
    onDone
  );
};

import React, { useState, useRef } from 'react';
// import { useStyles, useStylesSx } from './styles';
import { GridContainerNoData, GridContainerEvent, ImgImgFilter, useStylesSx } from './styled';
import { Grid, IconButton } from '@mui/material';
import clsx from 'clsx';
import {
  CustomizedCardContainer,
  CustomizedTypography,
  AlertCustom,
  MenuFilters,
  SearchComponent,
  LoaderSkeleton
} from '../../../componets';
import ItemEvent from './ItemEvent';
import { colors } from '../../../values/colors';
import { useNavigate } from 'react-router-dom';
import { icFilter, icFilterDark } from '../../../assets';
import k from '../../../values/key';
import useLazyLoad from './hooks/useLazyLoad';
import { Typography } from '@material-ui/core';
import { EventRepeat } from '@mui/icons-material';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import { useAuth } from '../../../contexts/AuthContext';


const MainRoot = ({is_buy=true}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const eventsRef = useRef();
  const triggerRef = useRef(null);
  // eslint-disable-next-line
  const [{ events, loading }, errorMessage, showAlert, handleChangeSearch, eventsDispatch] = useLazyLoad({ triggerRef });

  const goToDetails = (event) => {
    let where = `${k.navigation.selectTables}${event.code}`
    if(is_buy){
      where = (event.category === 2) ? `${k.navigation.detailsGraduation}${event.code}` : `${k.navigation.detailsEvent}${event.code}`
    }
    navigate(where);
  };

  const handleOpenUserMenu = (event) => {
    console.log('event-handleOpenUserMenu ' + event);
    setShowFilter(true);
    setAnchorElUser(event?.currentTarget);
  };

  const handleCloseUserMenu = async () => {
    setAnchorElUser(null);
    setShowFilter(false);
  };

  return (
    <GridContainerEvent ref={eventsRef} item xs={12} sm={12}>
      <CustomizedCardContainer width={"98%"} isbgbackgroundblack={"true"}>
        {showAlert && <AlertCustom errorMessage={errorMessage} />}
        <>
          <Grid container item xs={12}>
            <Grid item xs={5} sm={5} md={7} sx={useStylesSx.containerEventRoot}>
              <CustomizedTypography
                colors={colors.red}
                size={"2rem"}
                fontWeight={700}
                sx={{ textAlign: "center" }}
                variant={"h4"}
              >
                Eventos
              </CustomizedTypography>
            </Grid>
            <Grid container item xs={7} sm={7} md={5} spacing={1}>
              <Grid item xs={9} sm={9} sx={useStylesSx.containerSearch}>
                <SearchComponent handleChange={handleChangeSearch} />
              </Grid>
              <Grid item xs={3} sm={3} sx={useStylesSx.containerSelectedFilter}>
                <IconButton
                  sx={{
                    ...useStylesSx.iconButtonEvent,
                    ...useStylesSx.containerSelectedFilter,
                  }}
                  onClick={(event) => handleOpenUserMenu(event)}
                >
                  <ImgImgFilter src={showFilter ? icFilter : icFilterDark} />
                  <CustomizedTypography
                    mt={0.6}
                    colors={colors.grayText}
                    fontWeight={400}
                    size={"1rem"}
                  >
                    Filtros
                  </CustomizedTypography>
                </IconButton>
                <MenuFilters
                  eventsDispatch={eventsDispatch}
                  anchorElUser={anchorElUser}
                  handleMenuFilter={handleCloseUserMenu}
                />
              </Grid>
            </Grid>
          </Grid>
          {/**LISTADO DE EVENTOS */}
          <Grid container item spacing={2} mt={3}>
            {
            events.map(
              (event, index) => (
                <ItemEvent
                  key={`eventsList_${event.id}`}
                  index={index}
                  data={event}
                  action={() => goToDetails(event)}
                  is_buy={is_buy}
                  buttonBgColor={'#321D6C'}
                  buttonTextColor={'#FFFFFF'}
                  customBgColor={'linear-gradient(45deg, rgba(184, 135, 10, 1) 0%, rgba(255, 206, 82, 1) 50%, rgba(245, 183, 24, 1) 100%)'}
                  customTextColor={'#302F2F'}
                />
              )
            )
            }
          </Grid>
          <Grid container item spacing={1} mt={3}>
            <div
              ref={triggerRef}
              className={clsx("trigger", { visible: loading })}
            >
              {!loading && <LoaderSkeleton showHeader={false} />}
            </div>
          </Grid>
          {_.isEmpty(events) && (
            <GridContainerNoData
              container
              mt={5}
              item
              xs={12}
              direction={"column"}
            >
              <EventRepeat fontSize={"large"} />
              <Typography variant={"h4"}>No hay eventos por mostrar</Typography>
            </GridContainerNoData>
          )}
        </>
      </CustomizedCardContainer>
    </GridContainerEvent>
  );
};

export default MainRoot;

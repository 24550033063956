import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { amountFormat, getDayDate, getMonthsFormat } from '../../../../utils/fuctionsGeneral';
import { useStylesSx } from '../styles';
import '../styles.css';
import { colors } from '../../../../values/colors';
import { CustomizedTypography, CustomizedButton } from '../../../../componets';

const ItemEvent = ({ children, data, action, customTextColor, customBgColor, buttonTextColor, buttonBgColor, customActionText, forceEnableButton=false, is_buy=true }) => {
  if (data) {

    const [img, setImg] = useState(null);
    const [dayEvent, setDayEvent] = useState(null);
    const [month, setMont] = useState(null);
    const { title, subtitle, place, price, date, code, is_active, category_data } = data;

    const textLabel = (is_buy) ? 'Comprar boletos' : 'Elegir mesas';
    const buttonLabel = customActionText || textLabel;

    const textColor = customTextColor || category_data?.tx_color || colors.white;
    const bgColor = customBgColor || category_data?.bg_color || colors.yellowBlack;

    const buttonText = buttonTextColor || category_data?.tx_color || colors.white;
    const buttonBg = buttonBgColor || category_data?.bg_color || colors.yellowBlack;

    const disableButton = (forceEnableButton) ? false : !is_active;

    useEffect(() => {
      if (date) {
        setDayEvent(getDayDate(date));
        setMont(getMonthsFormat(date));
      }
      if (category_data) {
        setImg(category_data.icon);
      }
    }, []);

    return (
      <Grid item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ background: 'transparent' }}>
        <Paper className={'styleBox'} elevation={6} sx={useStylesSx.containerCardRoot}>
          <Grid container sx={useStylesSx.containerCard}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                ...useStylesSx.containerHeaderEvent,
                background: bgColor
              }}>
              <Grid item xs={8} sx={useStylesSx.headerTitle}>
                {category_data && category_data.name && (
                  <CustomizedTypography
                    colors={textColor}
                    size={'1.9rem'}
                    fontWeight={800}>
                    {category_data.name}
                  </CustomizedTypography>
                )}
              </Grid>
              {img && (
                <Grid item xs={4} sx={useStylesSx.containerImg}>
                  <img
                    src={img}
                    alt={'img'}
                    className={
                      category_data?.bg_color === '#FFFFFF'
                        ? 'iconCategoryInvert'
                        : 'iconCategory'
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <img
                src={data.image}
                alt={data.title}
                loading="lazy"
                className='fluid-img'
              />
            </Grid>
            <Grid item container p={0.6}>
              <Grid item xs={3} sm={3} md={3} sx={useStylesSx.eventRow}>
                {dayEvent && (
                  <Typography sx={useStylesSx.textEventDay} variant={'h4'}>
                    {dayEvent}
                  </Typography>
                )}
                {month && (
                  <Typography sx={useStylesSx.textEvent} variant={'h4'}>
                    {month}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={9} sm={9} md={9} sx={useStylesSx.eventRowDetailsEvents}>
                {title && (
                  <CustomizedTypography colors={colors.black} size={'1rem'} fontWeight={700}>
                    {title}
                  </CustomizedTypography>
                )}
                {subtitle && (
                  <CustomizedTypography
                    variant={'h5'}
                    colors={colors.blackText}
                    fontWeight={400}
                    size={'1rem'}>
                    {subtitle}
                  </CustomizedTypography>
                )}
                {place && (
                  <CustomizedTypography
                    variant={'h5'}
                    colors={colors.blackText}
                    fontWeight={400}
                    size={'1rem'}>
                    {'Lugar del evento: ' + place}
                  </CustomizedTypography>
                )}
                {price && (
                  <Typography variant={'subtitle2'} sx={{ bold: '100', color: colors.black }}>
                    Precio por boleto: {amountFormat(price)}
                  </Typography>
                )}
                {code && (
                  <CustomizedTypography
                    variant={'h5'}
                    colors={colors.blackText}
                    fontWeight={400}
                    size={'1rem'}>
                    Código: {code}
                  </CustomizedTypography>
                )}
              </Grid>
              {children}
              <Grid mt={1.5} item xs={12} className='containerButtonEvents'>
                <CustomizedButton
                  onClick={action}
                  /* eslint-disable-next-line camelcase */
                  disabled={disableButton}
                  colorinactive={colors.grayInactive}
                  variant={'outlined'}
                  color={'primary'}
                  bgcolor={buttonBg}
                  colortext={buttonText}
                  sx={{ cursor: 'pointer' }}>
                  {buttonLabel}
                </CustomizedButton>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
  return null;
};

export default ItemEvent;

const boleteraTheme = {
	palette: {
		mode: 'light',
		background: {
			default: '#fafafa',
			paper: '#fff',
		},
		text: {
			primary: 'rgba(0, 0, 0, 0.87)',
			secondary: 'rgba(0, 0, 0, 0.54)',
			disabled: 'rgba(0, 0, 0, 0.38)',
			hint: 'rgba(0, 0, 0, 0.38)',
		},
		primary: {
			main: '#FFCE52',
			light: '#F5B718',
			dark: '#B8870A',
			contrastText: '#fff',
		},
		secondary: {
			main: '#321D6C',
			light: '#321D6C',
			dark: '#321D6C',
			contrastText: '#fff',
		},
		error: {
			main: '#D92118',
			light: '#D92118',
			dark: '#D92118',
			contrastText: '#fff',
		},
		warning: {
			main: '#ff9800',
			light: '#ffb74d',
			dark: '#f57c00',
			contrastText: '#fff',
		},
		info: {
			main: '#0464A4',
			light: '#0464A4',
			dark: '#0464A4',
			contrastText: '#fff',
		},
		success: {
			main: '#56AC00',
			light: '#56AC00',
			dark: '#56AC00',
			contrastText: '#fff',
		},
		disabled: {
			main: '#32312D',
			light: '#32312D',
			dark: '#32312D',
			contrastText: '#fff',
		},
		divider: 'rgba(0, 0, 0, 0.12)',
		colorTextGray: {
			main: '#9F9F9F',
			light: '#9F9F9F',
			dark: '#9F9F9F',
			contrastText: '#9F9F9F',
		},
		textGrayBlack: {
			main: '#808191',
			light: '#808191',
			dark: '#808191',
			contrastText: '#808191',
		},
		colorText: {
			main: '#11142D',
			light: '#223263',
			dark: '#11142D',
			contrastText: '#11142D',
		},
		backgroundGray :{
			main: '#D9D9D9',
			light: '#D9D9D9',
			dark: '#D9D9D9',
			contrastText: '#D9D9D9',
		},
		backgroundTable:{
			main: '#464646',
			light: '#464646',
			dark: '#464646',
			contrastText: '#464646',
		},
	},
	myColors: {
		black: '#32312D',
		blue: '#321D6C',
	},
	myGradients: {
		yellow: 'linear-gradient(45deg, rgba(184, 135, 10, 1) 0%, rgba(255, 206, 82, 1) 50%, rgba(245, 183, 24, 1) 100%)',
	}
};

export default boleteraTheme;
import { styled } from '@mui/system';
import { Paper, TableHead, TableBody, TableCell } from '@mui/material';

export const StyledPaper = styled(Paper)(({ theme, withPadding=0 }) => ({
	margin: 'auto',
	maxWidth: '100%',
	flexGrow: 1,
	backgroundColor: 'transparent',
	borderRadius: 16,
	padding: withPadding,
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
	'& th': {
		color: '#FFF',
		backgroundColor: 'rgb(34, 38, 42)',
	},
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
	'& tr:nth-of-type(odd)': {
		backgroundColor: 'rgb(217, 217, 217)',
	},
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	color: '#525252',
}));
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Calendar } from 'primereact/calendar';

//theme
// eslint-disable-next-line import/no-extraneous-dependencies
import 'primereact/resources/themes/lara-light-indigo/theme.css';

//core
// eslint-disable-next-line import/no-extraneous-dependencies
import 'primereact/resources/primereact.min.css';

//icons
// eslint-disable-next-line import/no-extraneous-dependencies
import 'primeicons/primeicons.css';
import { colors } from '../../values/colors';

const CalendarComponent = ({ handleChangeCalendar, date, setDate }) => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();
  const prevMonth = month === 0 ? 11 : month - 1;
  const prevYear = prevMonth === 11 ? year - 1 : year;

  const minDate = new Date();
  minDate.setMonth(prevMonth);
  minDate.setFullYear(prevYear);

  return (
    <div
      style={{
        display: 'flex'
      }}>
      <Calendar
        minDate={minDate}
        dateFormat={'mm-dd-yy'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: '25rem',
          width: '25rem',
          background: colors.black,
          padding: '0.2rem',
          fontWeight: 400,
          margin: 0,
          borderBottom: '1px solid #dee2e6',
          borderTopRightRadius: '6px',
          borderTopLeftRadius: '6px',
          fontSize: '0.5rem'
        }}
        value={date}
        onChange={(e) => {
          setDate(e.value);
          handleChangeCalendar(e.value);
        }}
        inline
        showWeek={false}
        selectionMode={'range'}
      />
    </div>
  );
};

export default CalendarComponent;

import React from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomizedCardContainer, TitleGeneralTickets } from '../../../componets';
import { colors } from '../../../values/colors';
import FormLogin from './form';
import './styles.css';

const LoginUser = () => {

  const theme = useTheme();

  console.log('theme =>', theme);

  return (
    <Grid container className='containerRoot'>
      <Grid item sm={12} xs={12} md={8} lg={6} xl={6} className='containerData'>
        <CustomizedCardContainer 
          width={'70%'} 
          padding={'6vh'} 
          customBG={colors.gradientBG}
        >
          <FormLogin />
        </CustomizedCardContainer>
      </Grid>
      <Grid item sm={12} xs={12} md={4} lg={6} xl={6} className='containerRowData'>
        <TitleGeneralTickets />
      </Grid>
    </Grid>
  );
};

export default LoginUser;

import Menu from '@mui/material/Menu';
import { Avatar, Grid, MenuItem, Divider, ListItemIcon } from '@mui/material';
import { CustomizedTypography } from '../../componets';
import { colors } from '../../values/colors';
import { icUser } from '../../assets';
import s from '../../strings';
import useStyles from '../styles';
import styles from '../NavBar/styles';
import { useAuth } from '../../contexts/AuthContext';

const SettingsOptionsMenu = ({ anchorElUser, handleCloseUserMenu, listSettings }) => {
  const { user } = useAuth();
  const classes = useStyles();
  return (
    <Menu
      sx={{
        color: 'transparent',
        backgroundColor: 'transparent'
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.25)',
          width: '21rem',
          borderRadius: 4,
          '& .MuiAvatar-root': {
            ml: -0.4,
            mr: 0.4
          }
        }
      }}
      id={'account-menu'}
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorElUser)}
      onClose={() => handleCloseUserMenu()}>
      <MenuItem onClick={handleCloseUserMenu}>
        <Grid container item lg={12} sx={{ display: 'flex', background: 'transparent' }}>
          <Grid sx={styles.containerHeaderMenu} item sm={8} xl={8} lg={8} xs={8} md={8}>
            <CustomizedTypography
              size={'1rem'}
              fontWeight={700}
              mr={3}
              colors={colors.black}
              align={'right'}
              variant={'h6'}>
              Nombre
            </CustomizedTypography>
            <CustomizedTypography
              size={'0.8rem'}
              colors={colors.black}
              fontWeight={400}
              mr={3}
              align={'right'}
              variant={'subtitle1'}>
              {user.name ? user.name : 'Boleetera'}
            </CustomizedTypography>
          </Grid>
          <Grid sx={styles.containerAvatar} item sm={4} xl={4} lg={4} xs={4} md={4}>
            <Avatar
              sx={styles.avatarRoot}
              alt={user && user.name ? user.name : 'Boletera'}
              src={icUser}
            />
          </Grid>
        </Grid>
      </MenuItem>
      <Divider sx={styles.divider} variant={'middle'} />
      {listSettings.map((setting) => (
        // eslint-disable-next-line react/jsx-no-comment-textnodes
        <div key={setting.id}>
          {setting.title === s.close && <Divider sx={styles.divider} variant={'middle'} />}
          <MenuItem onClick={() => handleCloseUserMenu(setting)} key={setting.id}>
            <ListItemIcon>
              <img className={classes.img} src={setting.icon} />
            </ListItemIcon>
            <CustomizedTypography
              colors={colors.black}
              size={'0.8rem'}
              fontWeight={400}
              textAlign={'center'}>
              {setting.title}
            </CustomizedTypography>
          </MenuItem>
        </div>
      ))}
    </Menu>
  );
};

export default SettingsOptionsMenu;

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { HeaderCard } from '../CardCustom';
import { CustomizedTypography } from '../index';
import { useStylesSx } from '../../screens/usersLogged/DetailsEventScreen/styles';
import { getDayDate, getMonthsFormat } from '../../utils/fuctionsGeneral';
import { colors } from '../../values/colors';

const CardHeaderDetailEvent = ({ data, colorTextCardF, showTitleEvent, customBgColor, isActive=true, rounded=false, forceCustom=false }) => {
  const [dayEvent, setDayEvent] = useState(null);
  const [month, setMont] = useState(null);
  const { subtitle, title, code, date, category_data } = data;
  const [bgColorCard, setBgColorCard] = useState(null);
  const [textColorCard, setTextColorCar] = useState(null);

  let bgColor = colors.grayBlackText;
  let textColor = colors.white;

  if (data) {
    useEffect(() => {
      if (date) {
        setDayEvent(getDayDate(data.date));
        setMont(getMonthsFormat(data.date));
      }
      if (category_data) {
        if(isActive){
          bgColor = category_data?.bg_color || colors.redLight;
          textColor = category_data?.tx_color || colors.white;
        }
      }

      if(!isActive){
        setBgColorCard( (forceCustom) ? customBgColor : bgColor);
        setTextColorCar( (forceCustom) ? colorTextCardF : textColor);
      } else {
        setBgColorCard(customBgColor || bgColor);
        setTextColorCar(colorTextCardF || textColor);
      }
      

    }, [isActive]);

    return (
      <Grid item xs={12} sx={{
        borderRadius: (rounded) ? 16 : 0
      }}>
        <HeaderCard bgColor={bgColorCard} borderTop={rounded}>
          <Grid item xs={3} sx={useStylesSx.containerColumnHeaderCard}>
            {dayEvent && (
              <CustomizedTypography
                colors={textColorCard}
                size={'2.8rem'}
                fontWeight={600}
                variant={'h1'}>
                {dayEvent}
              </CustomizedTypography>
            )}
            {month && (
              <CustomizedTypography
                colors={textColorCard}
                size={'1rem'}
                fontWeight={400}
                variant={'h1'}>
                {month}
              </CustomizedTypography>
            )}
          </Grid>
          <Grid item xs={9} sx={useStylesSx.containerColumnHeaderCardDetails}>
            {showTitleEvent && (
              <CustomizedTypography colors={textColorCard} size={'0.7rem'} fontWeight={400}>
                Evento
              </CustomizedTypography>
            )}
            {title && (
              <CustomizedTypography colors={textColorCard} size={'1.1rem'} fontWeight={800}>
                {title}
              </CustomizedTypography>
            )}
            {subtitle && (
              <CustomizedTypography
                mt={showTitleEvent ? 0 : 1}
                colors={textColorCard}
                variant={'h5'}
                fontWeight={400}
                size={'0.9rem'}>
                {subtitle}
              </CustomizedTypography>
            )}
            {code && (
              <CustomizedTypography
                colors={textColorCard}
                mt={showTitleEvent ? 2 : 1}
                variant={'h5'}
                fontWeight={400}
                size={'0.9rem'}>
                Código: {code}
              </CustomizedTypography>
            )}
          </Grid>
        </HeaderCard>
      </Grid>
    );
  }

  return null;
};

export default CardHeaderDetailEvent;

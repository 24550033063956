import React, { useState } from 'react';
import { Avatar, TextField } from '@mui/material';
import { Person, Clear } from '@mui/icons-material';
import { SearchIcon } from './';


export const Account = ({user, onSearchChange=(text)=>console.log('text =>', text), onSearchClear=()=>console.log('Text clear')}) => {

	const [searchText, setSearchText] = useState('');

	const tryToFind = () => {
		if(searchText && searchText?.length > 0){
			onSearchChange(searchText);
		} else {
			onSearchClear();
		}
	}

	const handleClick = (event) => {
		tryToFind();
	}

	const handleClickClear = (event) => {
		setSearchText('');
		onSearchClear();
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.stopPropagation();
			event.nativeEvent.stopImmediatePropagation();
			event.preventDefault();
			tryToFind();
		}
	};

	return (
		<>
			<Avatar sx={{ width: '80%', aspectRatio: '1/1', height: 'auto' }}>
				<Person sx={{width: '100%', height: '100%'}}/>
			</Avatar>
			{user.name}
			<TextField
				sx={{
					width: '80%',
					marginTop: 2,
				}}
				variant="outlined"
				value={searchText}

				onChange={(event) => setSearchText(event.target.value)}
				onKeyDown={handleKeyDown}

				placeholder="Buscar evento"
				InputProps={{
					endAdornment: <>
						{searchText && searchText?.length > 0 && (
							<Clear sx={{cursor: 'pointer'}} onClick={handleClickClear}/>
						)}
						<SearchIcon onClick={handleClick}/>
					</>,
					sx: {
						borderRadius: '20px',
						'& input': {
							paddingRight: '30px',
						},
					},
				}}
			/>
		</>
	)
}
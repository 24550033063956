import React from 'react'
import { Grid } from '@mui/material';
import { Loader } from '../../../../componets';
import { useTheme } from '@mui/material/styles';


export const GridLoader = () => {
	const theme = useTheme();
	
	return (
		<Grid container item xs={12} className={theme.myStyles.containerLoader}>
			<Loader />
		</Grid>
	)
}
import React from 'react';
import { Grid, } from '@mui/material';
import { styled } from '@mui/system';
import { Search } from '@mui/icons-material';

export const GridColumnAllCenter = styled(Grid)`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`;

export const GridRowAllCenter = styled(Grid)`
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
`;


export const SearchIcon = styled(Search)`
  color: gray;
  margin-right: 8px;
  cursor: pointer !important;
  transition: all 0.3s;

  &:hover {
    color: #e53935;
	font-size: 2rem;
  }
`;
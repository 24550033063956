export const TYPE_ALERT_ERROR = 'error';
export const TYPE_ALERT_WARNING = 'warning';
export const TYPE_ALERT_SUCCESS = 'success';

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];

//del 3 al 8 sería como estatus de compra
//del 9 al 11 son como para el estado de pago
export const STATUS_PAYMENT = {
  active: 1,
  inactive: 2,
  pending: 3,
  cancel: 4,
  paid: 5,
  chargeback: 6,
  reverse: 7,
  reverse_failed: 8,
  approved: 9,
  declined: 10,
  error: 11,
  view: 12,
  no_view: 13,
  available: 14,
  unavailable: 15,
  expired: 16
};

export const FormatDateYYYYMMDDMMHHSS = 'YYYY-MM-DD hh:mm:ss';

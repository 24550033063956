import React from 'react';
import { Grid } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { colors } from '../../values/colors';
import CustomizedTypography from '../CustomizedComponents/CustomizedTypography';

const CountTicket = ({
  actionMore,
  actionLess,
  count,
  styles,
  ticketAvailable,
  isActiveTipeTiket,
  bgColors,
  colorText,
  disabledButton
}) => {
  let colorButtonAdd = colors.grayInactive;
  let colorButtonRemmove = colors.grayInactive;
  if (!disabledButton) {
    colorButtonAdd = count < ticketAvailable ? colors.blueLight : colors.grayInactive;
    colorButtonRemmove = count > 0 ? colors.blueLight : colors.grayInactive;
  }

  const canRun = callable => {
    if (!disabledButton) {
      callable();
    } else {
      return false;
    }
  }

  return (
    <>
      {isActiveTipeTiket && (
        <>
          <Grid
            item
            container
            sx={{ background: bgColors ? bgColors : '#e5e5e5' }}
            justifyContent={'center'}
            alignItems={'center'}
            direction={'row'}>
            <IconButton onClick={() => canRun(actionLess)} disabled={(count < 1 || disabledButton)} color={'primary'}>
              <Remove
                color={
                  disabledButton ? 'disabled' : count > 0 ? 'primary' : 'disabled'
                }
                fontSize={'lob'}
                sx={{
                  fontSize: '1.5rem',
                  color: colorButtonRemmove
                }}
              />
            </IconButton>
            <div className={styles}>
              <CustomizedTypography
                colors={colorText ? colorText : colors.blackLight}
                size={'0.8rem'}
                fontWeight={'100'}>
                {count}
              </CustomizedTypography>
            </div>
            <IconButton
              disabled={(count >= ticketAvailable || disabledButton)}
              onClick={() => canRun(actionMore)}
              color={'primary'}>
              <Add
                color={
                  disabledButton ? 'disabled' : count < ticketAvailable ? 'primary' : 'disabled'
                }
                sx={{
                  fontSize: '1.5rem',
                  color: colorButtonAdd
                }}
              />
            </IconButton>
          </Grid>
        </>
      )}
    </>
  );
};

export default CountTicket;

import React, { useEffect, useState } from 'react';
import { Toolbar, Box, AppBar, Avatar, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import k from '../../values/key';
import s from '../../strings';
import RoutesNavLinkMenu from '../ListMenu';
import { useNavigate } from 'react-router-dom';
import { removeSession } from '../../utils/fuctionUserSession';
import { useAuth } from '../../contexts/AuthContext';
import { icUser, icShopping, logoBoletera } from '../../assets';
import SettingsOptionsMenu from '../SettingsMenu';
import styles from './styles';
import useStyles from '../styles';
import { GET_MENU_OPTIONS } from '../../api/menu';

const pages = [
  { id: 1, name: s.ticket, route: k.navigation.events },
  { id: 2, name: s.table, route: k.navigation.tables },
  { id: 3, name: s.orders, route: k.navigation.myEvents }
  // { id: 3, name: s.orders, route: k.navigation.carShopping }
];

const ResponsiveAppBar = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [settings, setSettings] = useState([]);
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 62) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener('scroll', changeColor);

  useEffect(() => {
    getMenu();
  }, []);

  const getMenu = async () => {
    await GET_MENU_OPTIONS(onSuccesGetMenu, onErrorGetMenu, onDoneGetMenu);
  };

  const onSuccesGetMenu = (response) => {
    const {
      data: { success, data }
    } = response;
    if (success) {
      setSettings(data);
    }
  };
  const onErrorGetMenu = (error) => {
    console.log('data' + JSON.stringify(error));
  };
  const onDoneGetMenu = () => {};

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event?.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event?.currentTarget);
  };

  const handleCloseUserMenu = async (setting) => {
    setAnchorElUser(null);
    if (setting) {
      if (setting.route === k.navigation.close) {
        await exitApp();
      } else {
        navigate(setting.route);
      }
    }
  };

  const exitApp = async () => {
    setAnchorElUser(null);
    await closeSession().then(() => {
      navigate(k.navigation.login, { replace: true });
      window.location.reload();
    });
  };

  const closeSession = async () => {
    await removeSession();
  };

  return (
    <AppBar
      sx={color ? styles.containedAppBarRootWhite : styles.containedAppBarRoot}
      component={'nav'}>
      <Toolbar sx={styles.containedToolBarRoot} disableGutters>
        {/**TITULO BOLEETERA**/}
        <Box key={'1'} sx={styles.containedBox}>
          <img src={logoBoletera} style={styles.imgBoletera} />
        </Box>
        {/**MENU EN PEQUEÑO**/}
        <Box sx={styles.containerMenuSmall}>
          <IconButton
            size={'large'}
            aria-label={'account of current user'}
            aria-controls={'menu-appbar'}
            aria-haspopup={'true'}
            sx={{ color: 'white' }}
            onClick={(event) => handleOpenNavMenu(event)}>
            <MenuIcon sx={{ width: 50, height: 50 }} />
          </IconButton>
          <Menu
            id={'menu-appbar'}
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.25)',
                width: '18rem',
                borderRadius: 4,
                //mt: 1.5,
                '& .MuiAvatar-root': {
                  ml: -0.5,
                  mr: 1
                }
              }
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={styles.containerButtomSmal}>
            <RoutesNavLinkMenu pages={pages} handleCloseNavMenu={handleCloseNavMenu} />
          </Menu>
        </Box>
        <>
          <Box sx={styles.containedMenuSmall}>
            <img src={logoBoletera} style={styles.imgSmall} />
          </Box>
        </>
        {/** OPCIONES DE MENU APP BAR GRANDE**/}
        <Box sx={styles.containerListMenu}>
          <RoutesNavLinkMenu
            pages={pages}
            handleCloseNavMenu={handleCloseNavMenu}
            showButtons={true}
          />
        </Box>
        <Box sx={styles.containerListSettings}>
          <>
            {!anchorElUser && (
              <img
                onClick={() => {
                  navigate(k.navigation.carShopping);
                }}
                src={icShopping}
                className={classes.imgShopping}
              />
            )}
            <IconButton sx={styles.iconButton} onClick={(event) => handleOpenUserMenu(event)}>
              {!anchorElUser && (
                <Avatar
                  sx={styles.avatarStyles}
                  alt={user && user.name ? user.name : 'Boletera'}
                  src={icUser}
                />
              )}
            </IconButton>
          </>
          {settings && settings.length > 0 && (
            <SettingsOptionsMenu
              anchorElUser={anchorElUser}
              handleCloseUserMenu={handleCloseUserMenu}
              listSettings={settings}
            />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;

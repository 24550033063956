import { useEffect, useReducer, useCallback, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash/debounce';
import { GET_EVENTS_BY_FILTERS_API } from '../../../../api';
import { newsReducer, SET_EVENT_LIST, SET_EVENT_LOADING, SET_EVENT_TEMP } from './actions';
import { getMessageError } from '../../../../api/conf';

const INTERSECTION_THRESHOLD = 5;
const LOAD_DELAY_MS = 500;

const useLazyLoad = ({ triggerRef, options }) => {
  const [state, eventsDispatch] = useReducer(newsReducer, {
    page: 1,
    events: [],
    isLazy: false,
    loading: false,
    totalPages: 1
  });
  const [listEventTemp, setListEventTemp] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleChangeSearch = (text) => {
    text.preventDefault();
    if (text && text.target && text.target.value) {
      const value = text.target.value;
      console.log('value' + value);
      const listTemp = state?.events?.filter((event) => {
        if (event) {
          const { title, code, subtitle } = event;
          if (
            // eslint-disable-next-line operator-linebreak
            code.toLowerCase().includes(value.trim().toLowerCase()) ||
            // eslint-disable-next-line operator-linebreak
            title?.toLowerCase().includes(value.trim().toLowerCase()) ||
            subtitle?.toLowerCase().includes(value.trim().toLowerCase())
          ) {
            return event;
          }
        }
        return null;
      });
      eventsDispatch({ type: SET_EVENT_TEMP, events: listTemp });
    } else {
      eventsDispatch({ type: SET_EVENT_TEMP, events: listEventTemp });
    }
  };

  const _handleEntry = async (entry) => {
    const boundingRect = entry.boundingClientRect;
    const intersectionRect = entry.intersectionRect;
    // eslint-disable-next-line
    if (!state.loading && entry.isIntersecting && intersectionRect.bottom - boundingRect.bottom <= INTERSECTION_THRESHOLD) {
      if (state.page <= state.totalPages) {
        eventsDispatch({ type: SET_EVENT_LOADING, loading: true });
        await filter();
      } else {
        eventsDispatch({ type: SET_EVENT_LIST, events: [] });
        eventsDispatch({ type: SET_EVENT_LOADING, loading: true });
      }
    }
  };

  const filter = async () => {
    await GET_EVENTS_BY_FILTERS_API(state.page, 8, onSuccessFilters, errorEventListFilter, onDone);
  };

  const onSuccessFilters = (response) => {
    const { data, success, pages } = response.data;
    if (success) {
      setListEventTemp(data);
      eventsDispatch({ type: SET_EVENT_LIST, events: data, totalPages: pages });
    }
  };

  const onDone = () => {
    console.log('onDone');
  };

  const errorEventListFilter = (error) => {
    eventsDispatch({ type: SET_EVENT_LIST, events: [] });
    eventsDispatch({ type: SET_EVENT_LOADING, loading: true });
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      showError(message || getMessageError(error));
    } else {
      showError(getMessageError(error));
    }
  };

  const showError = (message) => {
    setErrorMessage(message);
    setShowAlert(true);
  };

  const handleEntry = debounce(_handleEntry, LOAD_DELAY_MS);

  const onIntersect = useCallback(
    (entries) => {
      handleEntry(entries[0]);
    },
    [handleEntry]
  );

  useEffect(() => {
    if (triggerRef.current) {
      const container = triggerRef.current;
      const observer = new IntersectionObserver(onIntersect, options);
      observer.observe(container);
      return () => {
        observer.disconnect();
      };
    }
  }, [triggerRef, onIntersect, options]);

  return [state, errorMessage, showAlert, handleChangeSearch, eventsDispatch];
};

export default useLazyLoad;

import axios from 'axios';
import { createBrowserHistory } from 'history';
import { removeSession, getToken } from '../utils/fuctionUserSession';
import k from '../values/key';
import s from '../strings';

const baseURL = process.env.REACT_APP_BASE_URL
// const baseURL = 'http://boletera.driveapp.mx/api/';
// const baseURL = 'http://127.0.0.1:8000/';
const api = axios.create({ baseURL });
api.defaults.timeout = 10000;

const defaultError = {
  error: {
    success: false,
    message: 'Ocurrió un error',
    title: ' Error interno',
    status: 400
  }
};

export const getMessageError = (error) => {
  const stringTrim = ' ';
  if (error && error.response && error.response.status) {
    switch (error.response.status) {
      case 404:
        return s.errorGeneral + error.code + stringTrim + error.response.statusText || s.notSupport;
      case 0:
        return s.networkState + stringTrim + error?.code;
      default:
        return s.errorGeneral + error + ' ' + error?.code || s.errorGeneral + s.internalError;
    }
  } else {
    if (error?.error) {
      return error.error.title + stringTrim + error.error.message;
    }
    return s.errorGeneral;
  }
};

const closeSession = () => {
  removeSession().then(() => {
    createBrowserHistory().push(k.navigation.login);
    window.location.reload();
  });
};

const handleError = (error, onError) => {
  console.log("<<<<<<<<<< message error: ", error);
  console.log("<<<<<<<<<< message error.response: ", error.response);
  console.log("<<<<<<<<<< message error.response.status: ", error?.response?.status);
  console.log("<<<<<<<<<< message error.response.data: ", error?.response?.data);
  if (error && error.response && error.response.status !== 500) {
    const {
      response: { status }
    } = error;
    status === 401 ? closeSession() : onError(error);
  } else {
    onError(defaultError);
  }
};

const getCsrfTokenHeader = () => {
  return {'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]').value}
}

/**
 * {
      headers: {
        ...getCsrfTokenHeader(),
        Authorization: `Token ${token}`
      }
    }
 */

export const POST_NO_AUTH = (path, data, onSuccess, onError, onDone = null) => {
  api
    .post(path, data)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(error, onError);
    })
    .then(onDone);
};

export const POST_AUTH = async (path, data, onSuccess, onError, onDone = null) => {
  const token = await getToken();
  api
    .post(path, data, {
      headers: {
        Authorization: `Token ${token}`
      }
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(error, onError);
    })
    .then(onDone);
};

export const GET_NO_AUTH = async (path, onSuccess, onError, onDone = null) => {
  api
    .get(path)
    .then((response) => onSuccess(response))
    .catch((error) => handleError(error, onError))
    .then(onDone);
};

export const GET_AUTH = async (path, onSuccess, onError, onDone = null, successExtraData = null) => {
  const token = await getToken();
  api
    .get(path, {
      headers: {
        Authorization: `Token ${token}`
      }
    })
    .then((response) => onSuccess(response))
    .catch((error) => handleError(error, onError))
    .then(onDone);
};

export const PATCH_AUTH = async (url, data, onSuccess, onError, onDone) => {
  const token = await getToken();
  api
    .patch(url, data, {
      headers: {
        Authorization: `Token ${token}`
      }
    })
    .then((response) => onSuccess(response))
    .catch((error) => {
      handleError(error, onError);
    })
    .then(onDone);
};

export const DELETE_AUTH = async (url, onSuccess, onError, onDone) => {
  const token = await getToken();
  api
    .delete(url, {
      headers: {
        Authorization: `Token ${token}`
      }
    })
    .then((response) => onSuccess(response))
    .catch((error) => {
      handleError(error, onError);
    })
    .then(onDone);
};

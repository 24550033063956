export const headersPaymentTable = ['id', 'Referencia', 'Fecha', 'Cantidad', 'Estatus'];
export const headersGuestTable = [
  'id',
  'Nombre',
  'Evento',
  'Boletos',
  'Correo',
  'Teléfono',
  'Cantidad',
  'Estatus',
  '-'
];

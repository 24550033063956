import React from 'react';
import styled from 'styled-components';
import { backgroundGuest, darkBG } from '../assets';
import { ReactComponent as Footer } from '../assets/background/svg/header.svg';

/**Estilo de imagen**/
// eslint-disable-next-line no-unused-vars
const FooterBackgraoud = styled(Footer)`
  position: fixed;
  z-index: 1;
  bottom: 0rem; /* 40px */
  right: 0; /* 40px */
`;

const BackgroundTickets = ({ children }) => {
  return (
    <div className={'overlay'} style={{ background: `url(${darkBG})` }}>
      {children}
      <FooterBackgraoud />
    </div>
  );
};

export default BackgroundTickets;

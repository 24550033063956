import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import { useStylesSx } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AlertCustom,
  Loader,
  CustomizedCardContainer,
  CustomizedTypography,
  CustomizedButton,
  SelectComponent,
  InteractiveMap,
  CountdownTimer
} from '../../../componets';
import {
  GET_EVENTS_BY_CODE_API,
  GET_EVENT_PRIVATE_USER_API,
  POST_BOOK_EVENT_API
} from '../../../api';
import { getMessageError } from '../../../api/conf';
import { colors } from '../../../values/colors';
import { getDayDate, getIcon, getMonthsFormat } from '../../../utils/fuctionsGeneral';
import s from '../../../strings';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonsGroupComponent from '../../../componets/RadioButton';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import k from '../../../values/key';

import './styles.css';

const data = [
  { id: 1, name: 'Mesa 1' },
  { id: 3, name: 'Mesa 2' },
  { id: 2, name: 'Mesa 3' },
  { id: 4, name: 'Mesa 4' }
];

const tables_requested = {
  1: 27,
  2: 27,
  3: 27,
  4: 27,
  5: 27,
  6: 27,
  7: 27,
  8: 27,
  9: 27,
  10: 27,
  11: 27,
  12: 27,
  13: 27
};

const tables_initial = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
  13: []
};

const sizes_initial = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

export const TitleEvent = ({ title, size, isCardActive }) => {
  return (
    <CustomizedTypography
      colors={isCardActive ? colors.textDisabled : colors.black}
      size={size ? size : '1.2rem'}
      fontWeight={700}
      level={'h2'}
      fontSize={'md'}>
      {title}
    </CustomizedTypography>
  );
};

export const SubtitleTitleEvent = ({ subtitle, color, fontSize, fontWeight, isCardActive, sx }) => {
  return (
    <CustomizedTypography
      sx={sx}
      colors={isCardActive ? colors.textDisabled : color ? color : colors.blackText}
      size={fontSize ? fontSize : '0.9rem'}
      fontWeight={fontWeight ? fontWeight : 400}
      level={'h2'}
      fontSize={'md'}>
      {subtitle}
    </CustomizedTypography>
  );
};

const SelectTables = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dataEvent, setDataEvent] = useState(null);
  const [dayEvent, setDayEvent] = useState(null);
  const [month, setMont] = useState(null);
  const [valueSelectedTable, setValueSelectedTable] = useState('tbl1');
  const [bgColorCard, setBgColorCard] = useState(colors.redLight);
  const [textColorCard, setTextColorCar] = useState(colors.white);
  const [bgColorCardDisabled, setBgColorCardDisabled] = useState(null);
  const [textColorCardDisabled, setTextColorCarDisabled] = useState(null);
  const [imgMapEvent, setMapEvent] = useState(null);
  const [showImgMapEvent, setShowImgMapEvent] = useState(false);
  const [isCardActive, setIsCardActive] = useState(false);
  const [countTables, setCountTables] = useState(1);
  const [tableSize, setTableSize] = useState(10);
  const [valuePaymentType, setValuePaymentType] = useState(1);
  const [valueSizeTable, setValueSizeTable] = useState(1);

  const [ticketDinner, setTicketDinner] = useState(0);
  const [ticketAfter, setTicketAfter] = useState(0);
  const [costTicketDinner, setCostTicketDinner] = useState(0);
  const [costTicketAfter, setCostTicketAfter] = useState(0);
  const [comisionPocesses, setComisionPocesses] = useState(0);
  const [processAmount, setProcessAmount] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const [amountTotalWhiOutProcess, setAmountTotalWhiOutProcess] = useState(0);
  const [amountTotalDinnerCalculate, setAmountTotalDinnerCalculate] = useState(0);
  const [amountTotalAfterCalculate, setAmountTotalAfterCalculate] = useState(0);
  const [messageCount, setMessageCount] = useState('');
  const [nameTicketAfter, setNameTicketAfter] = useState(s.qualityTicketAfter);
  const [nameTicketDinner, setNameTicketDinner] = useState(s.qualityTicketDinner);

  const [tableSelected, setTableSelected] = useState(tables_initial);
  const [arrangedTableSelected, setArrangedTableSelected] = useState([]);
  const [tablePending, setTablePending] = useState(tables_requested);
  const [pendingDinner, setPendingDinner] = useState(0);

  // const [dbTables, setDbTables] = useState(shopping_data.stores);
  const [dbTables, setDbTables] = useState([]);

  const [dataPrivateUser, setDataPrivateUser] = useState();
  const [cantBuy, setCantBuy] = useState(false);

  const [ticketsAfterData, setTicketsAfterData] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);

  const { timer } = CountdownTimer({
    countType: 'dates',
    dateStart: startingTime,
    dateEnd: endingTime
  });

  useEffect(() => {
    if (params && params.code) {
      setIsLoader(true);
      const { code } = params;
      getEventBYCode(code).then((r) => console.log('succes', r));
      getPrivateInfo(code).then((r) => console.log('<<<<< getPrivateInfo', r));
    }
  }, []);

  /* useEffect(() => {
    if (dataEvent) {
      if(dataEvent.is_public === false){
        getPrivateInfo(dataEvent.code).then((r) => console.log('<<<<< getPrivateInfo',  r));
      }
    }
  }, [dataEvent]); */


  const getEventBYCode = async (code) => {
    await GET_EVENTS_BY_CODE_API(code, onSuccessGetEventByCode, onErrorGetEventByCode, onDoneEvent);
  };

  const getPrivateInfo = async (code) => {
    await GET_EVENT_PRIVATE_USER_API(code, onSuccessPrivateInfo, onErrorGetEventByCode, () =>
      onDoneEvent(true)
    );
  };

  const onSuccessPrivateInfo = (response) => {
    const {
      data: { data, success }
    } = response;
    console.log('response', response);
    if (success) {
      setShowAlert(false);
      if (data) {

        setDataPrivateUser(data);

        setStartingTime(new Date(data?.now));
        setEndingTime(new Date(data?.selection_end));

        setTableSelected(tables_initial);
        setTablePending(tables_requested);

        if (data.can_select) {
          const number_cena =
            data?.total_tickets?.cena !== undefined ? data?.total_tickets?.cena : 0;
          const number_after =
            data?.total_tickets?.after !== undefined ? data?.total_tickets?.after : 0;

          setCountTables(calculateTables(number_cena, tableSize));
          setTicketDinner(number_cena);
          calculateAmountDinner(number_cena);
          setPendingDinner(number_cena);

          setTicketAfter(number_after);
          // setNameTicketAfter(`${number_after} ${ticketsAfterData?.name}`);
          setNameTicketAfter(`${number_after} ${ticketsAfterData?.ticket_type_data?.name}`);
          calculateAmountAfter(number_after);

          /* const newObject = {};
          Object.keys(data.allowed_tables).forEach(key => {
            newObject[key] = [];
          });
          setTableSelected(newObject);
          setTablePending(data.allowed_tables); */

        } else {
          /* setTableSelected(tables_initial);
          setTablePending(tables_requested); */
          setCantBuy(true);
        }
      }
    }
  };

  const onSuccessGetEventByCode = (response) => {
    const {
      data: { data, success }
    } = response;
    if (success) {
      setShowAlert(false);
      console.log('data=> ', data);
      if (data) {
        /**SE ENVIA OBJETO  DATA **/
        if (data?.table_sizes === undefined || data?.table_sizes === null) {
          data.table_sizes = sizes_initial;
        }
        setDataEvent(data);
        const { tickets, fee_is_percent, fee, diagram, category_data, table_sizes, date } = data;

        const after = tickets.length > 0 ? tickets[1] : null;
        setTicketsAfterData(after);

        /**SE FORMATEA LA FECHA**/
        if (date) {
          setDayEvent(getDayDate(date));
          setMont(getMonthsFormat(date));
        }
        /**SE ENVIA EL COLOR**/
        if (category_data) {
          const { bg_color, tx_color } = category_data;
          setBgColorCard(bg_color || colors.redLight);
          setTextColorCar(tx_color || colors.white);
        }
        setBgColorCardDisabled(colors.grayBlackText);
        setTextColorCarDisabled(colors.white);
        /**SE ENVIA EL TAMAÑO DE LAS MESAS**/
        if (table_sizes) {
          setTableSize(table_sizes[0]);
        } else {
          setTableSize(tables_initial[0]);
        }
        /**SE ENVIA LA IMAGEN**/
        if (diagram) {
          setMapEvent(diagram);
        }
        /**VALORES DEL TICKET**/
        if (tickets && tickets.length > 0) {
          setCostTicketDinner(tickets[0].value);
          setCostTicketAfter(tickets[1].value);
        }
        if (fee) {
          setComisionPocesses(fee_is_percent ? parseFloat(fee) / 100 : fee);
        }

        setDbTables(data.reservation);

        if (data.is_public) {
          setTableSelected(tables_initial);
          setTablePending(tables_requested);
        }
      }
    }
  };

  const onErrorGetEventByCode = (error) => {
    setIsLoader(false);
    setOpenDialog(false);
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      const msg = message || getMessageError(error);
      showError(msg);
    } else {
      const msg2 = getMessageError(error);
      showError(msg2);
    }
  };

  const onDoneEvent = (close = false) => {
    if (close) {
      setIsLoader(false);
      setOpenDialog(false);
    }
  };

  const showError = (message) => {
    console.log('message: ' + message);
    setErrorMessage(message);
    setShowAlert(true);
  };

  const addTicketOrder = (ticket) => {
    console.log('addTicketDinner' + ticket);
    if (ticketDinner > ticket?.item_left) {
      setMessageCount(`No puedes agregar mas de ${ticket.item_left} elementos`);
    } else {
      const dinnerTicket = ticketDinner + 1;
      if (dinnerTicket > tableSize) {
        setCountTables(calculateTables(dinnerTicket, tableSize));
      }
      setTicketDinner(dinnerTicket);
      // setNameTicketDinner(`${dinnerTicket} ${ticket.name}`);
      setNameTicketDinner(`${dinnerTicket} ${ticket?.ticket_type_data?.name}`);
      calculateAmountDinner(dinnerTicket);
      setPendingDinner(pendingDinner + 1);
    }
  };

  const calculateTables = (tickets, size) => {
    const quotient = Math.floor(tickets / size);
    const remainder = tickets % size;
    return remainder > 0 ? quotient + 1 : quotient;
  };

  const updateExistingItems = (new_tables) => {
    if (dbTables.length != new_tables.length) {
      setDbTables((prevTables) => prevTables.filter((table) => new_tables.includes(table.id)));
    }
  };

  const updateTablePlaces = (id, newPlaces = 0) => {
    // Find the index of the table with the given id in dbTables array
    const tableIndex = dbTables.findIndex((table) => table.id === id);

    // Create a shallow copy of the dbTables array
    const updatedTables = [...dbTables];

    // Update the 'places' property of the table with the new value
    updatedTables[tableIndex] = {
      ...updatedTables[tableIndex],
      places: newPlaces
    };

    // Call the setDbTables function to update the state with the updatedTables array
    setDbTables(() => updatedTables);
  };

  const handleAddTable = (event) => {
    console.log('Tamaño de mesa ', valueSizeTable);
    console.log('Mesa elegida', valueSelectedTable);
    console.log('Seleccionadas ', tableSelected);
    console.log('Pendientes ', tablePending);

    if (tablePending[valueSizeTable] > 0) {
      console.log('<<<<<<<<<< message pendingDinner: ', pendingDinner);
      console.log('<<<<<<<<<< message valueSizeTable: ', valueSizeTable);
      if (valueSizeTable <= pendingDinner) {
        const newTable = dbTables.find((table) => table.id === valueSelectedTable);
        newTable.bak_size = newTable.places;

        setTableSelected((prevSelected) => {
          const updatedTableSelected = { ...prevSelected };
          const arrayToUpdate = updatedTableSelected[valueSizeTable];
          arrayToUpdate.push(newTable);
          return updatedTableSelected;
        });

        includeInPending(valueSizeTable);

        updateTablePlaces(valueSelectedTable);

        arrangeSelectedTables(newTable, valueSizeTable);

        handleClickOnTableMap(dbTables.find((shop) => shop.places > 0 && newTable.id != shop.id));

        const new_pending = pendingDinner - valueSizeTable < 0 ? 0 : pendingDinner - valueSizeTable;
        setPendingDinner(new_pending);
      }
    }
    console.log(event);
  };

  const includeInPending = (size, value = -1) => {
    const updatedTablePending = { ...tablePending };
    const valueToUpdate = updatedTablePending[size];
    updatedTablePending[size] = valueToUpdate + value;
    setTablePending(updatedTablePending);
  };

  const handleCancel = (event) => {
    console.log('<<<<< handle Cancel');
    setIsLoader(true);

    const actual_tables = [...dbTables];
    const selected_tables = { ...tableSelected };
    const pending_tables = { ...tablePending };
    let new_pending = pendingDinner;

    arrangedTableSelected.forEach((table) => {
      const index_in_actual = dbTables.findIndex((item) => item.id === table.id);
      actual_tables[index_in_actual] = {
        ...actual_tables[index_in_actual],
        places: table.bak_size
      };

      selected_tables[table.size] = selected_tables[table.size].filter(
        (item) => item.id !== table.id
      );

      pending_tables[table.size] += 1;

      new_pending += Number(table.r_places);
    });

    if (new_pending > ticketDinner) {
      new_pending = ticketDinner;
    }
    setArrangedTableSelected([]);
    setDbTables(actual_tables);
    setTableSelected(selected_tables);
    setTablePending(pending_tables);
    setPendingDinner(new_pending);

    setIsLoader(false);
  };

  const handleRemoveTable = (table_id) => {
    const removeTable = arrangedTableSelected.find((item) => item.id == table_id);

    includeInPending(removeTable.size, 1);
    // updateTablePlaces(table_id, 10);
    updateTablePlaces(table_id, removeTable.bak_size);

    setArrangedTableSelected((prevArr) => prevArr.filter((item) => item.id != table_id));

    setTableSelected((prevSelected) => {
      const updatedSelected = { ...prevSelected };
      updatedSelected[removeTable.size] = updatedSelected[removeTable.size].filter(
        (item) => item.id !== table_id
      );
      return updatedSelected;
    });

    console.log('<<<<<<<<<< message pendingDinner: ', pendingDinner);
    console.log('<<<<<<<<<< message places: ', Number(removeTable.r_places));
    const new_pending =
      pendingDinner + Number(removeTable.r_places) > ticketDinner
        ? ticketDinner
        : pendingDinner + Number(removeTable.r_places);
    console.log('<<<<<<<<<< message new_pending: ', new_pending);
    setPendingDinner(new_pending);
  };

  const arrangeSelectedTables = (table, size, is_full = true) => {
    let insertIndex = arrangedTableSelected.findIndex((item) => item.order > table.order);
    if (insertIndex === -1) {
      insertIndex = arrangedTableSelected.length;
    }
    setArrangedTableSelected((prevArr) => [
      ...prevArr.slice(0, insertIndex),
      { ...table, size: size, r_table: table.id, r_full: is_full, r_places: size },
      ...prevArr.slice(insertIndex)
    ]);
  };

  const handleClickOnTableMap = (table) => {
    console.log('Table clicked', table);
    setValueSelectedTable(table.id);
  };

  const handleReservation = (event) => {
    // goToCarDetails();
    // reservar
    setIsLoader(true);
    POST_BOOK_EVENT_API(
      dataEvent.code,
      { seats: arrangedTableSelected },
      onSuccessReserevation,
      onErrorGetEventByCode,
      onDoneEvent
    );
  };

  const onSuccessReserevation = (response) => {
    const {
      data: { message, success }
    } = response;
    if (success) {
      setShowAlert(false);
      // goToCarDetails(true);
      goToOrderCompleted();
    }
  };

  const removeTicketOrder = (ticket) => {
    console.log('removeTicketOrder' + ticket);
    const dinnerTicket = ticketDinner - 1;
    if (ticketDinner > 0) {
      /**Se cambia el valor al tamaño de mesas */
      /* if (dinnerTicket < tableSize) {
        setCountTables(countTables - 1);
      } */
      setCountTables(calculateTables(dinnerTicket, tableSize));
      /**SE ENVIAN LOS VALRES PARA CALCULAR EL PRECIO*/
      setTicketDinner(dinnerTicket);
      calculateAmountDinner(dinnerTicket);
      setPendingDinner(pendingDinner - 1);
    } else {
      /**SE REEINICIAN LOS VALORES*/
      setTicketDinner(0);
      calculateAmountDinner(0);
      setPendingDinner(0);
    }
  };

  const calculateAmountDinner = (countTicketDinner) => {
    console.log('<<<<<<<<<< costTicketDinner', costTicketDinner);
    console.log('<<<<<<<<<< countTicketDinner', countTicketDinner);
    setAmountTotalDinnerCalculate(countTicketDinner * costTicketDinner);
    calculateAmount(amountTotalAfterCalculate, countTicketDinner * costTicketDinner);
  };

  const calculateAmount = (amountTotalAfterCalculate, amountTotalDinnerCalculate) => {
    const amountTotalAfterAndDinner = amountTotalAfterCalculate + amountTotalDinnerCalculate;
    setAmountTotalWhiOutProcess(amountTotalAfterAndDinner);
    if (amountTotalAfterAndDinner > 0) {
      const { fee_is_percent } = dataEvent;
      /**SE CALCULA EL COSTO POR PROCESAMIENTO **/
      const costProcessAmount = fee_is_percent
        ? amountTotalAfterAndDinner * parseFloat(comisionPocesses)
        : parseFloat(comisionPocesses);
      setProcessAmount(costProcessAmount);
      setAmountTotal(amountTotalAfterAndDinner + costProcessAmount);
    } else {
      setAmountTotal(amountTotalAfterAndDinner);
      setProcessAmount(0);
    }
  };

  const addTicketAfter = (ticket) => {
    console.log('addTicketDinner' + ticket);
    if (ticketAfter > ticket.item_left) {
      setMessageCount(`No puedes agregar mas de ${ticket.item_left} elementos`);
    } else {
      const ticketRD = ticketAfter + 1;
      setTicketAfter(ticketRD);
      setNameTicketAfter(`${ticketRD} ${ticket.name}`);
      calculateAmountAfter(ticketRD);
    }
  };

  const calculateAmountAfter = (countTicketAfter) => {
    setAmountTotalAfterCalculate(countTicketAfter * costTicketAfter);
    calculateAmount(countTicketAfter * costTicketAfter, amountTotalDinnerCalculate);
  };

  const removeTicketAfter = (ticket) => {
    console.log('addTicketDinner' + ticket);
    const ticketRD = ticketAfter - 1;
    if (ticketAfter > 0) {
      setTicketAfter(ticketRD);
      calculateAmountDinner(ticketRD);
    } else {
      setTicketAfter(0);
      calculateAmountDinner(0);
    }
  };

  const handleChangeSelect = (tableSelected) => {
    //setValueSelectedTable(tableSelected.name);
    setValueSelectedTable(tableSelected?.target?.value);
  };

  const selectCardTickets = () => {
    setIsCardActive(!isCardActive);
    setShowImgMapEvent(!showImgMapEvent);
  };

  const setValueRadioButton = (value) => {
    console.log(value);
    setValuePaymentType(value);
  };

  const setValueRadioButtonTable = (value) => {
    console.log(value);
    setValueSizeTable(value);
  };

  const goToOrderCompleted = () => {
    navigate(k.navigation.selectionCompleted, {
      state: {
        event: dataEvent,
        choosenTables: arrangedTableSelected
      }
    });
  };

  const goToCarDetails = (forced = false) => {
    const detailsPriceData = {
      dinnerValues: {
        ticketDinner,
        amountTotalDinnerCalculate,
        nameTicketDinner
      },
      afterValues: {
        ticketAfter,
        amountTotalAfterCalculate,
        nameTicketAfter
      },
      processAmount,
      amountTotalWhiOutProcess,
      amountTotal
    };
    console.log('detailsPriceData: ' + JSON.stringify(detailsPriceData));
    /** valuePaymentType 1 CUENTA UNICA 2 DIVIDIR CUENTA **/
    navigate(k.navigation.carShopping, {
      state: {
        event: dataEvent,
        detailsPriceData,
        typePayment: valuePaymentType,
        choosenTables: arrangedTableSelected,
        forced
      }
    });
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (isLoader) {
    return (
      <Box sx={{ flexGrow: 1, justifyContent: 'center' }}>
        <CustomizedCardContainer padding={'10rem'} width={'100%'} isbgbackgroundblack={'true'}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center'
            }}>
            <Loader />
          </Grid>
        </CustomizedCardContainer>
      </Box>
    );
  }

  if (dataEvent) {
    const { category_data, title, subtitle, code, table_sizes, tickets } = dataEvent;
    /*
    const bgColorCardF = !isCardActive ? bgColorCard : bgColorCardDisabled;
    const colorTextCardF = !isCardActive ? textColorCard : textColorCardDisabled;
    const bgColorCardS = isCardActive ? bgColorCard : bgColorCardDisabled;
    const colorTextCardS = isCardActive ? textColorCard : textColorCardDisabled;
    */
    
    const bgColorCardF = !isCardActive ? colors.gradientBG : bgColorCardDisabled;
    const colorTextCardF = !isCardActive ? '#302F2F' : textColorCardDisabled;
    const bgColorCardS = isCardActive ? colors.gradientBG : bgColorCardDisabled;
    const colorTextCardS = isCardActive ? '#302F2F' : textColorCardDisabled;

    const ticketsEventPrincipal = tickets.length > 0 ? tickets[0] : null;
    console.log('ticketsEventPrincipal' + JSON.stringify(ticketsEventPrincipal));
    console.log('ticketsAfter' + JSON.stringify(ticketsAfterData));

    return (
      <Box className='containerRootDetailEvent'>
        <Grid container className='containerRoot'>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {'¿Confirmar las mesas seleccionadas?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Una vez que confirmes que las mesas seleccionadas son correctas, no hay posibilidad
                de cambios.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomizedButton
                bgcolor={colors.whiteGray}
                colortext={colors.black}
                disabled={!isCardActive}
                bgcolorinactive={colors.transparent}
                colorinactive={colors.grayInactive}
                variant={'contained'}
                onClick={handleCloseDialog}>
                Cancelar
              </CustomizedButton>
              <CustomizedButton
                bgcolor={'#321D6C'}
                colortext={'#FFFFFF'}
                disabled={pendingDinner > 0}
                bgcolorinactive={colors.grayInactive}
                onClick={handleReservation}
                variant={'contained'}>
                Confirmar
              </CustomizedButton>
            </DialogActions>
          </Dialog>
          <CustomizedCardContainer padding={'0'} width={'100%'} isbgbackgroundblack={'true'}>
            {showAlert && <AlertCustom errorMessage={errorMessage} />}
            {cantBuy && <AlertCustom errorMessage='Todavía no puedes elegir mesas' />}
            {/**HEADER DETALLE DEL EVENTO**/}
            <Grid
              container
              item
              xs={12}
              sx={{
                ...useStylesSx.containerHeader,
                background: colors.gradientBG
              }}>
              <Grid item xs={6} sm={6} md={8} className='containerTitle'>
                {dataEvent?.category_data?.name && (
                  <CustomizedTypography
                    colors={'#302F2F'}
                    size={'2rem'}
                    fontWeight={700}
                    variant={'h4'}>
                    {category_data?.name}
                  </CustomizedTypography>
                )}
              </Grid>
              <Grid item xs={6} sm={6} md={4} className='containerHeaderImg'>
                <img src={getIcon(8)} width={'15%'} height={'25%'} />
              </Grid>
            </Grid>
            {/**BODY CARD DETALLE DEL EVENTO**/}
            <Grid item xs={12} className='containerNotData'>
              {!cantBuy &&
                dataPrivateUser &&
                dataPrivateUser?.selection_start &&
                dataPrivateUser?.selection_end && (
                  <CustomizedTypography
                    size={'1.8rem'}
                    colors={colors.black}
                    sx={{ textAlign: 'center' }}
                    variant={'subtitle2'}>
                    Tiempo restante: {timer}
                  </CustomizedTypography>
                )}
            </Grid>
            {tickets && tickets.length === 0 ? (
              <Grid container item xs={12} className='containerNotDataRoot'>
                <Grid item xs={12} className='containerNotData'>
                  <Grid item xs={12}>
                    <CustomizedTypography
                      size={'1.8rem'}
                      colors={colors.black}
                      sx={{ textAlign: 'center' }}
                      variant={'subtitle2'}>
                      No hay boletos disponibles para este evento
                    </CustomizedTypography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container item xs={12} sx={useStylesSx.containerBodyCardRoot}>
                {/**CONTENIDO**/}
                <Grid container item xs={12} spacing={1} sx={useStylesSx.containerBodyCard}>
                  {/**CARD 1**/}
                  <Grid item xs={12} sm={6} md={3.5}>
                    <Card variant={'outlined'} sx={useStylesSx.cardDetailsEvents}>
                      {/**HEADER**/}
                      <Grid
                        container
                        onClick={() => selectCardTickets()}
                        item
                        xs={12}
                        sx={{
                          ...useStylesSx.headerCardEvent,
                          background: bgColorCardF
                        }}>
                        <Grid item xs={3} sx={useStylesSx.containerColumnHeaderCard}>
                          {dayEvent && (
                            <CustomizedTypography
                              colors={colorTextCardF}
                              size={'2rem'}
                              fontWeight={600}
                              variant={'h1'}>
                              {dayEvent}
                            </CustomizedTypography>
                          )}
                          {month && (
                            <CustomizedTypography
                              colors={colorTextCardF}
                              size={'1rem'}
                              fontWeight={400}
                              variant={'h1'}>
                              {month}
                            </CustomizedTypography>
                          )}
                        </Grid>
                        <Grid item xs={9} sx={useStylesSx.containerColumnHeaderCardDetails}>
                          <CustomizedTypography
                            colors={colorTextCardF}
                            size={'0.7rem'}
                            fontWeight={400}>
                            Evento
                          </CustomizedTypography>
                          {title && (
                            <CustomizedTypography
                              colors={colorTextCardF}
                              size={'1.1rem'}
                              fontWeight={800}>
                              {title}
                            </CustomizedTypography>
                          )}
                          {subtitle && (
                            <CustomizedTypography
                              colors={colorTextCardF}
                              variant={'h5'}
                              fontWeight={400}
                              size={'0.9rem'}>
                              {subtitle}
                            </CustomizedTypography>
                          )}
                          {code && (
                            <CustomizedTypography
                              colors={colorTextCardF}
                              mt={2}
                              variant={'h5'}
                              fontWeight={400}
                              size={'0.9rem'}>
                              Código: {code}
                            </CustomizedTypography>
                          )}
                        </Grid>
                      </Grid>
                      {/**BODY**/}
                      <Grid container item xs={12} sx={useStylesSx.bodyCardEvent}>
                        <TitleEvent title={'Resumen'} isCardActive={isCardActive} />
                        {/**SECCION1**/}
                        <List sx={{ width: '100%' }}>
                          {ticketsEventPrincipal && (
                            <ListItem
                              key={ticketsEventPrincipal?.id}
                              secondaryAction={<ListItemText primary={ticketDinner} />}>
                              <ListItemText primary={`Boletos de ${ticketsEventPrincipal?.ticket_type_data?.name}`} />
                            </ListItem>
                          )}
                          {/* ticketsAfterData && (
                            <ListItem
                              key={ticketsAfterData?.id}
                              secondaryAction={<ListItemText primary={ticketAfter} />}>
                              <ListItemText primary={`Boletos de ${ticketsAfterData?.ticket_type_data?.name}`} />
                            </ListItem>
                          ) */}
                        </List>

                        {/**SECCION3**/}
                        <Grid container item xs={12} sx={useStylesSx.containerSelector}>
                          <Grid item xs={7} sx={useStylesSx.containerRow}>
                            <SubtitleTitleEvent
                              isCardActive={isCardActive}
                              fontSize={'1.2rem'}
                              color={colors.black}
                              subtitle={'Total de boletos'}
                            />
                          </Grid>
                          <Grid item xs={5} sx={useStylesSx.detailsEvent}>
                            <TitleEvent
                              isCardActive={isCardActive}
                              title={ticketDinner + ticketAfter}
                            />
                          </Grid>
                        </Grid>

                        {/**BOTON**/}
                        <Grid item mt={10} xs={12} sx={useStylesSx.containerButton}>
                            <SubtitleTitleEvent
                              isCardActive={isCardActive}
                              fontSize={'1.2rem'}
                              color={colors.black}
                              subtitle={'Para visualizar el layout del evento y reservar las mesas, dar click en el siguiente botón'}
                            />
                          <CustomizedButton
                            bgcolor={'#321D6C'}
                            onClick={() => selectCardTickets()}
                            maxwidth={'20rem'}
                            disabled={isCardActive || ticketDinner === 0}
                            colortext={'#FFFFFF'}
                            bgcolorinactive={colors.grayInactive}
                            variant={'contained'}>
                            Elegir mesas
                          </CustomizedButton>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  {/**CARD 2**/}
                  <Grid item xs={12} sm={6} md={3.5}>
                    <Card variant={'outlined'} sx={useStylesSx.cardSelectedTableRoot}>
                      {/**HEADER**/}
                      <Grid
                        container
                        onClick={() => selectCardTickets()}
                        item
                        xs={12}
                        sx={{
                          ...useStylesSx.headerCardSelected,
                          background: bgColorCardS
                        }}>
                          <Grid item xs={12}>
                            <CustomizedTypography
                              colors={colorTextCardS}
                              size={'0.7rem'}
                              fontWeight={400}>
                              Selección de mesas
                            </CustomizedTypography>
                          </Grid>
                          <Grid item xs={12}>
                            <CustomizedTypography
                              colors={colorTextCardS}
                              size={'1.7rem'}
                              fontWeight={700}>
                              21 al 22 de Agosto
                            </CustomizedTypography>
                          </Grid>
                          <Grid item xs={12}>
                            <CustomizedTypography
                              colors={colorTextCardS}
                              size={'0.8rem'}
                              fontWeight={400}>
                              Podrás hacer el apartado de tu mesa, cuando tu pago sea cubierto al 75%.
                            </CustomizedTypography>
                          </Grid>
                      </Grid>
                      {/**BODY**/}
                      <Grid container item xs={12} sx={useStylesSx.bodyCardSelected}>
                        <Box>
                          <>
                            <TitleEvent
                              isCardActive={!isCardActive}
                              size={'1.6rem'}
                              title={'Mesas'}
                            />
                            {/**SECCION1**/}

                            <Grid container item xs={12} sx={useStylesSx.containerSelector}>
                              <Grid item xs={12} sx={useStylesSx.containerRow}>
                                <SubtitleTitleEvent
                                  fontSize={'1.4rem'}
                                  isCardActive={!isCardActive}
                                  subtitle={'Selección de mesa (s)'}
                                />
                              </Grid>
                              <Grid container item xs={12}>
                                <SelectComponent
                                  colorValue={!isCardActive ? colors.grayInactive : colors.black}
                                  data={dbTables.filter((shop) => shop.places > 0)}
                                  widthSelect={1}
                                  valor={valueSelectedTable}
                                  handleChangeSelect={handleChangeSelect}
                                />
                              </Grid>
                            </Grid>
                            {/**SECCION2**/}
                            <Grid container item xs={12} sx={useStylesSx.containerSelector}>
                              <Grid item xs={7} sx={useStylesSx.containerRow}>
                                <SubtitleTitleEvent
                                  fontSize={'1.4rem'}
                                  isCardActive={!isCardActive}
                                  subtitle={'Tamaño de mesas'}
                                />
                              </Grid>
                              {table_sizes && table_sizes.length && (
                                <Grid item xs={12} sx={useStylesSx.containerListCheck}>
                                  <RadioButtonsGroupComponent
                                    disabled={!isCardActive}
                                    isRow={true}
                                    listRadioButton={
                                      table_sizes /*table_sizes.filter( size => {
                                      if( dataPrivateUser ){
                                        return Object.keys(dataPrivateUser.allowed_tables).includes((typeof size == 'number') ? size.toString():size);
                                      }
                                      return true;
                                    })*/
                                    }
                                    color={!isCardActive ? colors.grayInactive : colors.black}
                                    sizeTitleCheck={'1.2rem'}
                                    checkedIcon={<CheckBoxIcon />}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    valueInitial={valueSizeTable}
                                    actionRadioButton={setValueRadioButtonTable}
                                  />
                                </Grid>
                              )}
                              <Grid item xs={12} sx={useStylesSx.detailsEvent}>
                                <CustomizedButton
                                  bgcolor={'#321D6C'}
                                  bgcolorinactive={colors.grayInactive}
                                  colortext={'#FFFFFF'}
                                  disabled={!isCardActive}
                                  maxheight={'1.72rem'}
                                  maxwidth={'8rem'}
                                  onClick={handleAddTable}
                                  sizefont={'0.8rem'}
                                  variant={'contained'}
                                >
                                  Agregar
                                </CustomizedButton>
                              </Grid>
                            </Grid>

                          </>
                          <Divider sx={useStylesSx.divider} />
                          {/**SECCION4**/}
                          <Grid container item xs={12} sx={useStylesSx.containerSelector}>
                            <Grid item xs={7} sx={useStylesSx.containerRow}>
                              <SubtitleTitleEvent
                                isCardActive={!isCardActive}
                                fontSize={'1.4rem'}
                                color={colors.black}
                                subtitle={'Boletos restantes'}
                              />
                            </Grid>
                            <Grid item xs={5} sx={useStylesSx.detailsEvent}>
                              <TitleEvent isCardActive={!isCardActive} title={pendingDinner} />
                            </Grid>
                          </Grid>

                          {/**SECCION5**/}
                          <SubtitleTitleEvent
                            isCardActive={!isCardActive}
                            fontSize={'1.2rem'}
                            subtitle="Selecciona tus mesas acorde al numero de boletos y tamaño. Recueda que deben seleccionarse mesas completas."
                          />
                          <SubtitleTitleEvent
                            isCardActive={!isCardActive}
                            fontSize={'1.2rem'}
                            subtitle='* Las mesas están sujetas a disponibilidad, procura seleccionar tus mesas lo antes posible, en las fechas estipuladas.'
                          />
                          <Grid mt={1.3} />
                          <List>
                            {
                              arrangedTableSelected.map(table => {
                                return (
                                  <>
                                    <ListItem
                                    key={table.id}
                                      secondaryAction={
                                        <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => handleRemoveTable(table.id)}>
                                          <DeleteIcon />
                                        </IconButton>
                                      }>
                                      <ListItemText primary={`${table.name} para ${table.size} personas`}/>
                                    </ListItem>
                                  </>
                                )
                              })
                            }
                          </List>
                          {/*Object.entries(tableSelected).map(([people, tables]) => {
                            return tables.map((table, index) => (
                              <TitleEvent
                                isCardActive={!isCardActive}
                                size={'0.7rem'}
                                fontWeight={800}
                                title={`${table.name} para ${people} personas`}
                              />
                            ))
                          })*/}
                        </Box>
                        {/**BOTON**/}
                        <Grid item mt={4} xs={12} sx={useStylesSx.containerButtonRow}>
                          <CustomizedButton
                            bgcolor={colors.whiteGray}
                            colortext={colors.black}
                            maxwidth={'45rem'}
                            disabled={!isCardActive}
                            bgcolorinactive={colors.transparent}
                            colorinactive={colors.grayInactive}
                            variant={'contained'}
                            onClick={handleCancel}>
                            Cancelar
                          </CustomizedButton>
                          <CustomizedButton
                            bgcolor={'#321D6C'}
                            bgcolorinactive={colors.grayInactive}
                            colortext={'#FFFFFF'}
                            disabled={pendingDinner > 0 || !isCardActive}
                            maxwidth={'60rem'}
                            onClick={handleClickOpenDialog}
                            variant={'contained'}
                          >
                            Aceptar
                          </CustomizedButton>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  {/**CARD 3**/}
                  <Grid item xs={12} md={5}>
                    <Card
                      variant={'outlined'}
                      sx={isCardActive ? useStylesSx.cardEventSelected : useStylesSx.opacityClass}>
                      {showImgMapEvent && (
                        <InteractiveMap
                          source={imgMapEvent}
                          tables={dbTables}
                          onFilterExisting={updateExistingItems}
                          onTableClicked={handleClickOnTableMap}
                        />
                      )}
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </CustomizedCardContainer>
        </Grid>
      </Box>
    );
  }
  return null;
};
export default SelectTables;

import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, ListItemText } from '@mui/material';


export const BoxRoot = styled(Box)({
	flexGrow: 1,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	maxWidth: '100%',
	background: 'transparent',
});

export const GridHeader = styled(Grid)(({theme}) => ({
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: 5
    }
}));

export const TextListBig = styled(Grid)(({theme}) => ({
  fontSize: 24
}));

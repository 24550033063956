import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerTitle: {
    mr: 2,
    display: { xs: 'flex', md: 'none' },
    flexGrow: 1,
    fontFamily: 'monospace',
    fontWeight: 700,
    letterSpacing: '.3rem',
    color: 'inherit',
    textDecoration: 'none'
  },
  content: {
    flexGrow: 1
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  img: {
    marginLeft: 1,
    marginRight: 1,
    padding: 6,
    width: '2.3rem',
    height: '2.3rem',
    objectFit: 'contain'
  },
  containerMenu: {
    background: 'rgba(255, 255, 255, 0.8)',
    boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.25)',
    backdropFilter: 'blur(13.5px)',
    borderRadius: 20
  },
  container: {
    background: 'red'
  },
  imgShopping: {
    width: '2rem',
    height: '2rem'
  }
}));

export default useStyles;

import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Form, Formik, Field } from 'formik';

import './styles.css';

import {
  CustomizedFormHelperText,
  CustomizedTextField,
  CountTicketComponent,
  CustomizedButton,
  CustomizedLoadingButton
} from '../../../../componets';

/**UTILS**/
import validations from './validations';
import initialValues from './initialValues';
import s from '../../../../strings/index';
import { SubtitleTitleEvent } from '../../DetailsEventScreen';
import { CardSectionColumn } from '../../../../componets/CardCustom';
import { colors } from '../../../../values/colors';
import { SAVE_GUEST_API, EDIT_GUEST_API } from '../../../../api';
import { getMessageError } from '../../../../api/conf';
import { TYPE_ALERT_SUCCESS } from '../../../../utils/utilsGeneral';

const FormAddGuest = ({
  showFormAddGuest,
  setShowFormAddGuest,
  guest,
  setGuest,
  setErrorMessage,
  setShowAlert,
  bgColorCard,
  textColorCard,
  setTypeAlert,
  getListGuest
}) => {
  
  const [numberCompanions, setNumberCompanions] = useState(0);
  const [numberTables, setNumberTables] = useState(0);
  const [loaderButton, setLoaderButton] = useState(false);
  const formGuest = useRef(null);

  useEffect(() => {
    console.log(guest);
    if (guest) {
      const { full_name, email, phone } = guest;
      formGuest.current.setFieldValue('full_name', full_name);
      formGuest.current.setFieldValue('email', email);
      formGuest.current.setFieldValue('phone', phone);
    }
  }, []);

  const addGuestAction = (values, resetForm) => {
    console.log('values:=> ' + JSON.stringify(values));
    setLoaderButton(true);
    const all_values = {...values, 'event': 1}
    if (guest) {
      editGuestUser(all_values, resetForm);
    } else {
      addGuestUserForm(all_values, resetForm);
    }
  };

  const editGuestUser = async (values, resetForm) => {
    await EDIT_GUEST_API(
      guest.id,
      values,
      (response) => onSuccessEditGuest(response, resetForm),
      onErrorEditGuest,
      onDoneEditGuest
    );
  };

  const addGuestUserForm = async (values, resetForm) => {
    await SAVE_GUEST_API(
      values,
      (response) => onSuccessAddGuestUser(response, resetForm),
      errorAddGuestUser,
      onDoneAddGuestUser
    );
  };

  const onSuccessAddGuestUser = (response) => {
    const { data } = response;
    console.log('success::: ' + data);
    showAlertSuccess('Invitado agregado');
  };

  const showAlertSuccess = (message) => {
    getListGuest();
    setTypeAlert(TYPE_ALERT_SUCCESS);
    setErrorMessage(message);
    setShowAlert(true);
    setLoaderButton(false);
    setShowFormAddGuest(false);
  };

  const errorAddGuestUser = (error) => {
    if (error && error.response && error.response.data) {
      console.log('error + errorAddGuestUser' + error);
      const {
        response: {
          data: { message }
        }
      } = error;
      const errorResponse = getMessageError(error);
      showError(message || errorResponse);
      setLoaderButton(false);
    } else {
      showError(getMessageError(error));
    }
  };
  const onDoneAddGuestUser = () => {
    console.log('onDoneAddGuestUser');
    setLoaderButton(false);
  };

  const onSuccessEditGuest = (response, resetForm) => {
    console.log('deleteUser' + JSON.stringify(response));
    const {
      data: { success }
    } = response;
    console.log('success' + JSON.stringify(success));
    console.log('success - onSuccessEditGuest' + success);
    setGuest(null);
    resetForm();
    showAlertSuccess('El invitado se  ha editado correctamente.');
  };

  const onErrorEditGuest = (error) => {
    console.log('onErrorEditGuest: ');
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      console.log('onErrorEditGuest 1: ' + message);
      showError(message || getMessageError(error));
      setLoaderButton(false);
    } else {
      console.log('onErrorEditGuest 2: ' + error);
      showError(getMessageError(error));
    }
  };

  const onDoneEditGuest = () => {
    console.log('deleteUser');
    setLoaderButton(false);
  };

  const showError = (message) => {
    console.log('message::showError  ' + message);
    setErrorMessage(message);
    setShowAlert(true);
    setLoaderButton(false);
  };

  const removeNumberCompanions = () => {
    console.log('removeNumberCompanions');
    const companions = numberCompanions - 1;
    setNumberCompanions(companions);
  };

  const addNumberCompanions = () => {
    console.log('addNumberCompanions');
    const companions = numberCompanions + 1;
    setNumberCompanions(companions);
  };

  const removeNumberTables = () => {
    console.log('removeNumberCompanions');
    const tables = numberTables - 1;
    setNumberTables(tables);
  };

  const addNumberTables = () => {
    console.log('addNumberCompanions');
    const tables = numberTables + 1;
    setNumberTables(tables);
  };

  return (
    <>
      <Formik
        innerRef={formGuest}
        validationSchema={validations}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => addGuestAction(values, resetForm)}>
        {({ values, errors, touched, isValid, handleSubmit }) => (
          <Form className="contentForm">
            <Grid item xs={12} lg={12}>
              <Grid item xs className="titleForm">
                <SubtitleTitleEvent subtitle={'Invitado'} />
              </Grid>
              <>
                <Field
                  sx={{ mt: 1 }}
                  as={CustomizedTextField}
                  variant={'filled'}
                  margin={'normal'}
                  fullWidth
                  name={'full_name'}
                  label={s.nameComplete}
                  id={'full_name'}
                  autoComplete={'full_name'}
                />
                {touched.full_name && errors.full_name && (
                  <CustomizedFormHelperText color={colors.redLight}>
                    {errors.full_name}
                  </CustomizedFormHelperText>
                )}
              </>
              <>
                <Field
                  sx={{ mt: 1 }}
                  as={CustomizedTextField}
                  variant={'filled'}
                  margin={'normal'}
                  fullWidth
                  name={'email'}
                  label={s.email}
                  id={'email'}
                  autoComplete={'email'}
                />
                {touched.email && errors.email && (
                  <CustomizedFormHelperText color={colors.redLight}>
                    {errors.email}
                  </CustomizedFormHelperText>
                )}
              </>
              <>
                <Field
                  sx={{ mt: 1 }}
                  as={CustomizedTextField}
                  variant={'filled'}
                  margin={'normal'}
                  fullWidth
                  name={'phone'}
                  label={s.phone}
                  id={'phone'}
                  autoComplete={'phone'}
                />
                {touched.phone && errors.phone && (
                  <CustomizedFormHelperText color={colors.redLight}>
                    {' '}
                    {errors.phone}
                  </CustomizedFormHelperText>
                )}
              </>
              <CardSectionColumn>
                <Grid item xs={8}>
                  <SubtitleTitleEvent subtitle={'Número de acompañantes'} />
                </Grid>
                <Grid item xs={4}>
                  <CountTicketComponent
                    key={'4'}
                    messageCount={''}
                    bgColors={colors.white}
                    subtitle={'$0.00'}
                    isActiveTipeTiket={true}
                    count={numberCompanions}
                    actionLess={() => removeNumberCompanions()}
                    actionMore={() => addNumberCompanions()}
                  />
                </Grid>
              </CardSectionColumn>
              <CardSectionColumn>
                <Grid item xs={8}>
                  <SubtitleTitleEvent subtitle={'Número de mesas'} />
                </Grid>
                <Grid item xs={4}>
                  <CountTicketComponent
                    key={'4'}
                    bgColors={colors.white}
                    messageCount={''}
                    subtitle={'$0.00'}
                    isActiveTipeTiket={true}
                    count={numberTables}
                    actionLess={() => removeNumberTables()}
                    actionMore={() => addNumberTables()}
                  />
                </Grid>
              </CardSectionColumn>
              <div className="margin">
                <CardSectionColumn>
                  <Grid item xs={4} className="containerRow" />
                  <Grid item xs={8} className="containerRowRight">
                    <Grid item mt={4} xs={12} className="containerButtonShoppingForm">
                      <CustomizedButton
                        bgcolor={colors.bgColorGray}
                        colortext={colors.black}
                        maxwidth={'40rem'}
                        onClick={() => setShowFormAddGuest(!showFormAddGuest)}
                        bgcolorinactive={colors.transparent}
                        colorinactive={colors.grayInactive}
                        variant={'contained'}>
                        Cancelar
                      </CustomizedButton>
                      <CustomizedLoadingButton
                        loading={loaderButton}
                        onClick={() => handleSubmit()}
                        bgcolor={bgColorCard || colors.redLight}
                        widthbottom={'50rem'}
                        txtcolor={textColorCard || colors.white}
                        bgcolorinactive={colors.grayInactive}
                        variant={'contained'}>
                        {guest ? 'Actualizar' : 'Agregar'}
                      </CustomizedLoadingButton>
                    </Grid>
                  </Grid>
                </CardSectionColumn>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormAddGuest;

import React from 'react';
import { Box, Grid } from '@mui/material';
import { CustomizedCardContainer, CustomizedTypography } from '../../../componets';
import { colors } from '../../../values/colors';

import './styles.css';

const Notifications = () => {
  
  return (
    <Grid container>
      <Box sx={{ flexGrow: 1 }}>
        <Grid mt={1} container item xs={12} className="titleHeaderContainer">
          <Grid item xs={12} sm={12} className="containerEvent">
            <CustomizedCardContainer width={'98%'} isbgbackgroundblack={'true'}>
              <Grid item xs={12} sm={12} md={12} sx="containerEventRoot">
                <CustomizedTypography
                  colors={colors.red}
                  size={'2rem'}
                  fontWeight={700}
                  sx={{ textAlign: 'center' }}
                  variant={'h4'}>
                  Notifications
                </CustomizedTypography>
              </Grid>
            </CustomizedCardContainer>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Notifications;

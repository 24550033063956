import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { colors } from '../../values/colors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.grayBlackText,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: colors.bgColorGray
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const buildTableHeaders = (headers) => {
  return (
    <TableHead>
      <StyledTableRow>
        {headers.map((item, index) => (
          <StyledTableCell key={index}>{item}</StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
};

const buildTableBody = (body) => {
  console.log('body: ' + JSON.stringify(body));
  return (
    <TableBody>
      {body.map((row, index) => (
        <StyledTableRow key={index}>
          {row.map((item, indexR) => (
            <StyledTableCell sx={{ color: colors.grayTextTable }} key={indexR}>
              {item}
            </StyledTableCell>
          ))}
        </StyledTableRow>
      ))}
    </TableBody>
  );
};

export default function CustomizedTables({ dataTable }) {
  const { headers, body } = dataTable;
  const tableHeaders = buildTableHeaders(headers);
  const tableBody = buildTableBody(body);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label={'customized table'}>
        {tableHeaders}
        {tableBody}
      </Table>
    </TableContainer>
  );
}

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { CardOptionsBackground, CustomizedTypography, SelectComponent } from '../../../componets';
import { colors } from '../../../values/colors';
import CustomizedTables from '../../../componets/TableCustom';
import { headersGuestTable } from '../../../utils/HederTables';
import { Button, Typography } from '@material-ui/core';
import { icEdit, icDelete } from '../../../assets';

import './styles.css';

const listEvent = [
  {
    id: 1,
    name: 'Juan Carlos Gomez',
    event: 'Graduación IBERO',
    ticket: '2',
    email: 'juanca@correo.com',
    phone: '5556581111',
    amount: '$ 4,050.00',
    status: { type: 1, name: 'Aprobada' }
  },
  {
    id: 2,
    name: 'María Magdalena Perez Gomez',
    event: 'Graduación IBERO',
    ticket: '0',
    email: 'mmpe@correo.com',
    phone: '5556581112',
    amount: null,
    status: null
  },
  {
    id: 2,
    name: 'Eugenia Gomez ',
    event: 'Graduación IBERO',
    ticket: '3',
    email: 'eugegogo@correo.com',
    phone: '5556581113',
    amount: '$ 6,050.00',
    status: { type: 2, name: 'Pendiente' }
  }
];

const GuestBoletera = () => {
  const [dataTableEvent, setDataTableEvent] = useState({ headers: headersGuestTable, body: [] });
  const [valueSelectedTable, setValueSelectedTable] = useState('Selecciona una opción');

  const getButtons = (id) => {
    return (
      <>
        <Button onClick={() => alert(id)}>
          <img src={icEdit} className="img"/>
        </Button>
        <Button onClick={() => alert(id)}>
          <img src={icDelete} className="img"/>
        </Button>
      </>
    );
  };

  const getColor = (type) => {
    switch (type) {
      case 1:
        return "textGreen";
      case 2:
        return "textRed";
      default:
        return "textBlack";
    }
  };

  const getStatusIcons = (status) => {
    if (status) {
      return (
        <div>
          <Typography className={status && status.type ? getColor(status.type) : "textBlack"}>
            {status.name}
          </Typography>
        </div>
      );
    }
    return null;
  };

  //FUNCION QUE CONSTRUYE EL BODY DEL DEPARTAMENTO
  const buildBodyDataGuest = (payments) => {
    return payments.map((payment) => {
      const { id, name, event, ticket, email, phone, amount, status } = payment;
      return [
        id,
        name,
        event,
        ticket,
        email,
        phone,
        amount,
        getStatusIcons(status),
        getButtons(id)
      ];
    });
  };

  //FUNCION QIUE CONSTRUYE EL HEADER DE LA TABLA DEPARTAMENTO
  const buildDataTableGuest = (department) => {
    const body = buildBodyDataGuest(department);
    const headers = headersGuestTable;
    return { headers, body };
  };

  useEffect(() => {
    setDataTableEvent(buildDataTableGuest(listEvent));

    return () => {
      return function cleanup() {
        setDataTableEvent(buildDataTableGuest(listEvent));
      };
    };
  }, [listEvent]);

  const handleChangeSelect = (tableSelected) => {
    setValueSelectedTable(tableSelected.name);
  };

  return (
    <CardOptionsBackground idPage={4}>
      <Grid container item xs={12} sm={12} md={12} className="containerGuest">
        <CustomizedTypography
          mr={2}
          colors={colors.black}
          size={'1.3rem'}
          fontWeight={700}
          variant={'h4'}>
          Evento
        </CustomizedTypography>
        <SelectComponent
          handleChangeSelect={handleChangeSelect}
          valor={valueSelectedTable}
          data={[{ id: 1, name: 'Graduacion Graduación IBERO' }]}
        />
      </Grid>
      <Grid mt={2} item xs={12}>
        <CustomizedTables dataTable={dataTableEvent} />
      </Grid>
    </CardOptionsBackground>
  );
};

export default GuestBoletera;

import React, { useEffect, useState } from 'react';
import { MenuItem, Menu, Grid } from '@mui/material';
import { CheckBoxCircule, CalendarComponent } from '../../componets';
import { colors } from '../../values/colors';
import CustomizedTypography from '../CustomizedComponents/CustomizedTypography';
import { GET_CATEGORY_EVENT_API, GET_EVENTS_BY_FILTERS } from '../../api';
import moment from 'moment/moment';
import { FormatDateYYYYMMDDMMHHSS } from '../../utils/utilsGeneral';
import { SET_EVENT_TEMP } from '../../screens/usersLogged/EventsScreen/hooks/actions';

const SettingsOptionsMenu = ({ anchorElUser, handleMenuFilter, eventsDispatch }) => {
  const [listFilterCategory, setListFilterCategory] = useState([]);
  const [categorySelected, setCategorySelected] = useState(null);
  const [dateStartSelected, setDateStartSelected] = useState(null);
  const [dateEndSelected, setDateEndSelected] = useState(null);
  const [date, setDate] = useState(null);

  useEffect(() => {
    getCategory().then((r) => {
      console.log(r);
    });
  }, []);

  const optionSelected = async (categorySelected) => {
    /**SE CAMBIA EL VLOR DEL CHECK PARA PODER ARMAR LA LISTA DEE QUERY PARAMSS**/
    const listSelectTemp = listFilterCategory.map((category) => {
      if (category.id === categorySelected.id) {
        return { ...category, selected: !category.selected };
      }
      return category;
    });
    /**SEE ACTUALIZA LA LISTA DE CHECK **/
    setListFilterCategory(listSelectTemp);
    /**SE ARMA LA LISTA PARA PODER BUSCAR LA CATEGORIA Y QUITAR SI ES NECESARIO LA QIE YA NO ESTA SELECCIONADA**/
    let queryParams = [];
    listSelectTemp.forEach((category) => {
      if (category.selected) {
        const encodedKey = encodeURIComponent('catego');
        const encodedValue = encodeURIComponent(category.id);
        queryParams.push(encodedKey + '=' + encodedValue);
      }
    });
    queryParams = queryParams.join('&');
    setCategorySelected(queryParams);
    await getEventByFilter(queryParams, dateStartSelected, dateEndSelected);
  };

  const onSuccessGetEventFilter = (response) => {
    const {
      data: { data, success }
    } = response;
    if (success) {
      eventsDispatch({ type: SET_EVENT_TEMP, events: data });
    }
  };

  const onErrorGetEventFilter = (error) => {
    console.log(error);
  };
  const getCategory = async () => {
    await GET_CATEGORY_EVENT_API(onSuccessGetCategory, onErrorGetCategory, onDoneGetCategory);
  };

  const onSuccessGetCategory = (response) => {
    const {
      data: { data, success }
    } = response;
    if (success) {
      const listSelectTemp = data.map((category) => {
        return { ...category, selected: false };
      });
      setListFilterCategory(listSelectTemp);
    }
  };

  const onErrorGetCategory = (error) => {
    console.log(error);
  };

  const onDoneGetCategory = (date) => {};

  const handleChangeCalendar = async (dateRange) => {
    const dateStart = dateRange[0] ? moment(dateRange[0]).format(FormatDateYYYYMMDDMMHHSS) : null;
    setDateStartSelected(dateStart);
    const dateEnd = dateRange[1] ? moment(dateRange[1]).format(FormatDateYYYYMMDDMMHHSS) : null;
    setDateEndSelected(dateEnd);
    await getEventByFilter(categorySelected, dateStart, dateEnd);
  };

  const cleanFilter = async () => {
    setCategorySelected(null);
    setDateStartSelected(null);
    setDateEndSelected(null);
    setDate(null);
    const listSelectTemp = listFilterCategory.map((category) => {
      return { ...category, selected: false };
    });
    setListFilterCategory(listSelectTemp);
    await getEventByFilter(null, null, null);
  };

  const getEventByFilter = async (catgoryId, dateStart, dateEnd) => {
    await GET_EVENTS_BY_FILTERS(
      null,
      null,
      dateStart,
      dateEnd,
      catgoryId,
      onSuccessGetEventFilter,
      onErrorGetEventFilter,
      onDoneGetCategory
    );
  };
  return (
    <Menu
      sx={{
        color: 'transparent',
        backgroundColor: 'transparent'
      }}
      PaperProps={{
        elevation: 0,
        overflow: 'visible',
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(13.5px)',
          boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.25)',
          width: '25rem',
          //maxHeight: '30%',
          borderRadius: 4,
          mt: '4rem',
          '& .MuiAvatar-root': {
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'rgba(0, 0, 0, 0.8)',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      id={'account-menu'}
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorElUser)}
      onClose={() => handleMenuFilter()}>
      <div onClick={handleMenuFilter}>
        <Grid container item xs={12} lg={12} sx={{ display: 'flex', background: 'transparent' }}>
          <Grid container item xs={6} lg={6} sx={{ display: 'flex', background: 'transparent' }}>
            <CustomizedTypography fontWeight={600} size={'1.8rem'} ml={2}>
              Filtros
            </CustomizedTypography>
          </Grid>
          <Grid
            container
            item
            onClick={async () => {
              await cleanFilter();
            }}
            xs={6}
            lg={6}
            sx={{
              display: 'flex',
              background: 'transparent',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CustomizedTypography fontWeight={400} size={'1.2rem'}>
              Limpiar filtros
            </CustomizedTypography>
          </Grid>
        </Grid>
      </div>
      <Grid container item mt={1}>
        <CustomizedTypography mb={2} fontWeight={400} size={'1.3rem'} ml={2}>
          Por fecha
        </CustomizedTypography>
        <CalendarComponent
          setDate={setDate}
          date={date}
          handleChangeCalendar={handleChangeCalendar}
        />
      </Grid>
      <MenuItem>
        <Grid container item lg={12} mt={2} sx={{ display: 'flex', background: 'transparent' }}>
          <CustomizedTypography size={'1.3rem'}>Tipo de evento</CustomizedTypography>
        </Grid>
      </MenuItem>
      {listFilterCategory && listFilterCategory.length > 0 && (
        <div>
          {listFilterCategory.map((setting) => (
            // eslint-disable-next-line react/jsx-no-comment-textnodes
            <div key={setting.id}>
              <div key={setting.id}>
                <CheckBoxCircule
                  checked={setting.selected}
                  actionCheck={() => optionSelected(setting)}
                  color={colors.white}
                  isCheckdefault={true}
                  title={setting.name}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </Menu>
  );
};

export default SettingsOptionsMenu;

import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { colors } from '../../values/colors';

const CustomizedLoadingButton = styled(LoadingButton)((props) => ({
  width: `${props.widthbottom ? props.widthbottom : '11.75rem'}`,
  maxWidth: `${props.maxwidth ? props.maxwidth : '11.75rem'}`,
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '1.3rem',
  padding: 5,
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: `${props.bgcolor ? props.bgcolor : colors.yellow}`,
  borderColor: `${props.bgcolor ? props.bgcolor : colors.yellow}`,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  borderRadius: '50px',
  color: `${props.txtcolor ? props.txtcolor : colors.blackRgb}`,
  '&:hover': {
    backgroundColor: `${props.bgcolor ? props.bgcolor : colors.yellow}`,
    borderColor: `${props.bgcolor ? props.bgcolor : colors.yellow}`,
    boxShadow: 'none'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: `${props.bgcolor ? props.bgcolor : colors.yellow}`,
    borderColor: `${props.bgcolor ? props.bgcolor : colors.yellow}`
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: `${props.bgcolorinactive ? props.bgcolorinactive : colors.grayLight}`,
    borderColor: `${props.bgcolorinactive ? props.bgcolorinactive : colors.grayLight}`,
    color: `${props.txtcolor ? props.txtcolor : colors.grayBlack}`
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
  }
}));

export default CustomizedLoadingButton;

import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import k from '../values/key';

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();
  if (user) {
    return children;
  }
  return <Navigate to={k.navigation.login} state={{ from: location }} replace />;
};

export default ProtectedRoute;

import { colors } from '../../values/colors';

const styles = {
  containedAppBarRoot: {
    background: 'transparent',
    borderColor: 'transparent',
    boxShadow: '0px 0px 0px transparent'
  },
  containedAppBarRootWhite: {
    background: 'rgba(0, 0, 0, 0.9)',
    borderColor: 'rgba(0, 0, 0, 0.9)'
  },
  containedToolBarRoot: { background: 'transparent' },
  containedBox: {
    flexGrow: 0,
    p: 2,
    justifyContent: 'flex-start',
    background: 'transparent',
    alignItems: 'flex-start',
    display: { xs: 'none', md: 'flex' },
    backgroundColor: 'transparent'
  },
  customTitleTicket: {
    mr: 2,
    display: { xs: 'none', md: 'flex' }
  },
  containerListMenu: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    display: { xs: 'none', md: 'flex' }
  },
  containerListSettings: {
    mr: 5,
    flexGrow: 0,
    background: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    display: { xs: 'flex', md: 'flex' }
  },
  iconButton: {
    width: 70,
    height: 70,
    ml: 3,
    p: 0
  },
  avatarStyles: {
    width: '4rem',
    height: '4rem'
  },
  containedMenuSmall: {
    mr: 2,
    display: { xs: 'flex', md: 'none' },
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerMenuSmall: {
    background: 'transparent',
    flexGrow: 1,
    display: { xs: 'flex', md: 'none' }
  },
  containerButtomSmal: {
    display: { xs: 'block', md: 'none' }
  },
  divider: { mt: 2, bgcolor: colors.black, border: '0.6px solid rgba(0, 0, 0, 0.35)' },
  containerAvatar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    background: 'transparent'
  },
  containerHeaderMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    background: 'transparent'
  },
  avatarRoot: {
    width: 55,
    height: 55,
    ml: 3
  },
  imgBoletera: {
    maxWidth: '56%',
    maxHeight: '6rem',
    width: '56%',
    objectFit: 'contain'
  },
  imgSmall: {
    maxWidth: '35%',
    maxHeight: '6rem',
    width: '35%',
    objectFit: 'contain'
  }
};

export default styles;

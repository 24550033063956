/**CONSTANTTES**/
export const SET_EVENT_LIST = 'SET_ARTICLES';
export const SET_EVENT_LOADING = 'SET_EVENT_LOADING';
export const SET_EVENT_TEMP = 'SET_ARTICLES_TEMP';

/**RDUCER**/
export const newsReducer = (state, action) => {
  switch (action.type) {
    case SET_EVENT_LIST:
      return {
        ...state,
        events: [...state.events, ...action.events],
        page: state.page + 1,
        isLazy: false,
        loading: false,
        totalPages: action.totalPages
      };
    case SET_EVENT_TEMP:
      return {
        ...state,
        events: action.events,
        isLazy: false,
        loading: false
      };
    case SET_EVENT_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }
    default:
      return state;
  }
};

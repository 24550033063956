export default {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  confirm_password: '',
  phone: '',
  country: '',
  terminusConditions: false
};

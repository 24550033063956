import React, { useEffect, useState } from 'react';
import IframeComm from 'react-iframe-comm';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Box } from '@material-ui/core';
//import k from '../../../values/key';
import { AlertCustom, Loader, CustomizedCardContainer, CustomizedButton } from '../../../componets';
import { getMessageError } from '../../../api/conf';
import { PAYMENT_EVENT_ORDER_API } from '../../../api';
import { useStylesSx } from '../DetailsEventScreen/styles';
import { colors } from '../../../values/colors';
import { SubtitleTitleEvent, TitleEvent } from '../DetailsEventScreen';

import './styles.css';

const PaymentWebView = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [urlWebView, setUrlWebView] = useState(params?.state?.dataSales?.pay_link);
  const [heightWeb, setHeightWeb] = useState(800);
  const [isLoader, setIsLoader] = useState(false);

  const [bgColorCard, setBgColorCard] = useState(null);
  const [textColorCard, setTextColorCar] = useState(null);

  const [showComprobante, setShowComprobante] = useState(false);

  useEffect(() => {
    const eventParasm = params?.state?.event;
    if (eventParasm?.category_data) {
      const { category_data } = eventParasm;
      const bgColor = category_data.bg_color ? category_data.bg_color : colors.redLight;
      setBgColorCard(bgColor);
      const textColor = category_data.tx_color ? category_data.tx_color : color;
      setTextColorCar(textColor);
    }
  });

  const attributes = {
    src: urlWebView,
    width: '100%',
    height: heightWeb,
    frameBorder: 0.5 // show frame border just for fun...
  };

  const postMessageData = '';

  // parent received a message from iframe
  const onReceiveMessage = (response) => {
    const data = response.data;
    if (data) {
      if(typeof data === 'string' && data.startsWith('{')){
        try {
          const dataParse = JSON.parse(data);
          console.log('dataParse =>', dataParse);
          if (dataParse?.success === 1 || dataParse?.success) {
            if (dataParse?.await_response === 1 || dataParse?.await_response !== false) {
              setIsLoader(true);
              setTimeout(() => {
                setIsLoader(false);
                goToDetailsPayment();
              }, 6000);
            } else {
              setShowComprobante(true);
            }
          } else {
            console.log('no pagada');
            setIsLoader(false);
            setShowAlert(true);
            setHeightWeb(100);
            setErrorMessage(dataParse.title + ' ' + dataParse.message);
            setShowButton(true);
          }
        } catch (error) {
          console.log('error =>', error);
        }
        
      }
    }
  };

  // iframe has loaded
  const onReady = () => {
    setIsLoader(false);
  };

  const goToDetailsPayment = () => {
    if (params && params.state) {
      //navigate(k.navigation.detailsOrder, { replace: true, state: params.state });
      navigate(-1, { replace: true, state: params.state });
    } else {
      //navigate(k.navigation.detailsOrder, { replace: true });
      navigate(-1, { replace: true });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const addTransactionById = async () => {
    setIsLoader(true);
    const orderNumber = params.state.dataSales.number;
    const amount = params.state.dataSales.paying;
    const data = {
      amount
    };
    await PAYMENT_EVENT_ORDER_API(
      orderNumber,
      data,
      onSuccessAddTransactionById,
      onErrorAddTransactions,
      onDone
    );
  };

  const onSuccessAddTransactionById = (response) => {
    setIsLoader(false);
    const { data, success } = response.data;
    if (success) {
      /**NAVGEAR AL WEB VIEW**/
      setUrlWebView(data.pay_link);
      setShowButton(false);
      setHeightWeb(800);
    }
  };

  const onErrorAddTransactions = (error) => {
    setIsLoader(false);
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      showError(message || getMessageError(error));
    } else {
      showError(getMessageError(error));
    }
  };

  const showError = (message) => {
    setIsLoader(false);
    setErrorMessage(message);
    setShowAlert(true);
  };

  const onDone = () => {
    setIsLoader(false);
  };

  if (isLoader) {
    return <Loader />;
  }

  return (
    <Box className="containerRootWebView">
      <Grid container item xs={12}>
        <CustomizedCardContainer padding={'0.5rem'} width={'100%'} isbgbackgroundblack={'true'}>
          {showAlert && <AlertCustom errorMessage={errorMessage} />}

          <Grid
            container
            item
            spacing={3}
            mt={8}
            xs={12}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}>
              {
                showComprobante && (
                  <Grid item xs={12}>
                    <TitleEvent 
                      isCardActive={true} 
                      size={'1.4rem'} 
                      title={'Sigue las indicaciones para realizar tu pago'}
                    />
                  </Grid>
                )
              }
            <Grid item xs={12}>
              <IframeComm
                // sandbox
                attributes={attributes}
                postMessageData={postMessageData}
                handleReady={onReady}
                handleReceiveMessage={onReceiveMessage}
              />
            </Grid>
          </Grid>
          {showButton && (
            <Grid
              mt={8}
              item
              xs={12}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}>
              <Grid item mt={10} xs={12} sx={useStylesSx.containerButton}>
                <CustomizedButton
                  bgcolor={bgColorCard || colors.redLight}
                  onClick={() => addTransactionById()}
                  maxwidth={'20rem'}
                  colortext={textColorCard}
                  bgcolorinactive={colors.grayInactive}
                  variant={'contained'}>
                  Intentar con otra tarjeta
                </CustomizedButton>
              </Grid>
            </Grid>
          )}
        </CustomizedCardContainer>
      </Grid>
    </Box>
  );
};

export default PaymentWebView;

import React, { useState, useEffect } from 'react';
import { Grid, Box, Card, Divider, Fade, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useStylesSx } from './styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import {
  AlertCustom,
  Loader,
  CustomizedCardContainer,
  CustomizedTypography,
  CustomizedButton,
  CountTicketComponent,
  SelectComponent,
  CardHeaderDetailEvent,
  CheckBoxCircule
} from '../../../componets';

import { CardSectionColumn } from '../../../componets/CardCustom';

import { GET_EVENTS_BY_CODE_API, PAYMENT_EVENT_API } from '../../../api';
import { getMessageError } from '../../../api/conf';
import { colors } from '../../../values/colors';
import { getDayDate, getIcon, getMonthsFormat, amountFormat } from '../../../utils/fuctionsGeneral';
import s from '../../../strings';
import { AddCircle } from '@mui/icons-material';
import RadioButtonsGroupComponent from '../../../componets/RadioButton';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import k from '../../../values/key';
const data = [
  { id: 1, name: 'Mesa 1' },
  { id: 3, name: 'Mesa 2' },
  { id: 2, name: 'Mesa 3' },
  { id: 4, name: 'Mesa 4' }
];

const dataPartial = [
  { id: 0, name: 'Selecciona parcialidades' },
  { id: 1, name: 'Pagar el mínimo' },
  { id: 2, name: '2 pagos' },
  // { id: 3, name: '3 pagos' },
  // { id: 4, name: '4 pagos' }
];

import './styles.css';
import { useCommission } from '../EventsScreen/hooks/useCommission';
import { fill, initial } from 'lodash';
import { useStaticState } from '@material-ui/pickers';

export const TitleEvent = ({ title, size, isCardActive, customTextColor }) => {
  return (
    <CustomizedTypography
      colors={isCardActive ? colors.textDisabled : customTextColor || colors.black}
      size={size ? size : '1.2rem'}
      fontWeight={700}
      level={'h2'}
      fontSize={'md'}>
      {title}
    </CustomizedTypography>
  );
};

export const SubtitleTitleEvent = ({ subtitle, color, fontSize, fontWeight, isCardActive, sx }) => {
  return (
    <CustomizedTypography
      sx={sx}
      colors={isCardActive ? colors.textDisabled : color ? color : colors.blackText}
      size={fontSize ? fontSize : '0.9rem'}
      fontWeight={fontWeight ? fontWeight : 400}
      level={'h2'}
      fontSize={'md'}>
      {subtitle}
    </CustomizedTypography>
  );
};

export const EventDivider = () => (
  <Divider sx={useStylesSx.divider} />
)

const DetailGraduationScreen = () => {

  const params = useParams();
  const navigate = useNavigate();

  const location = useLocation();

	const auth = useAuth();
	const { user } = auth;

  const defaultSizes = [8, 9, 10, 11, 12];

  const [isLoader, setIsLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dataEvent, setDataEvent] = useState(null);

  const [selectedMethod, setSelectedMethod] = useState(null);

  const [dayEvent, setDayEvent] = useState(null);
  const [month, setMont] = useState(null);
  const [valueSelectedTable, setValueSelectedTable] = useState('Mesa 1');
  const [bgColorCard, setBgColorCard] = useState(colors.redLight);
  const [textColorCard, setTextColorCar] = useState(colors.white);
  const [bgColorCardDisabled, setBgColorCardDisabled] = useState(null);
  const [textColorCardDisabled, setTextColorCarDisabled] = useState(null);
  const [imgMapEvent, setMapEvent] = useState(null);
  const [showImgMapEvent, setShowImgMapEvent] = useState(false);
  const [isCardActive, setIsCardActive] = useState(false);
  const [countTables, setCountTables] = useState(1);
  const [tableSize, setTableSize] = useState(10);
  const [valuePaymentType, setValuePaymentType] = useState(1);
  const [valueSizeTable, setValueSizeTable] = useState(1);
  
  const [availableSizes, setAvailableSizes] = useState([]);
  const [selectedTableSize, setSelectedTableSize] = useState([]);
  const [describedTables, setDescribedTables] = useState([]);
  const [tableCount, setTableCount] = useState(0);

  const [ticketDinner, setTicketDinner] = useState(0);
  const [ticketAfter, setTicketAfter] = useState(0);
  const [costTicketDinner, setCostTicketDinner] = useState(0);
  const [costTicketAfter, setCostTicketAfter] = useState(0);
  const [comisionPocesses, setComisionPocesses] = useState(0);
  const [processAmount, setProcessAmount] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const [amountTotalWhiOutProcess, setAmountTotalWhiOutProcess] = useState(0);
  const [amountTotalDinnerCalculate, setAmountTotalDinnerCalculate] = useState(0);
  const [amountTotalAfterCalculate, setAmountTotalAfterCalculate] = useState(0);
  const [messageCount, setMessageCount] = useState('');
  // const [nameTicketAfter, setNameTicketAfter] = useState(s.qualityTicketAfter);
  // const [nameTicketDinner, setNameTicketDinner] = useState(s.qualityTicketDinner);
  const [nameTicketAfter, setNameTicketAfter] = useState(s.qualityTicketAfter);
  const [nameTicketDinner, setNameTicketDinner] = useState(s.qualityTicketDinner);

  const [orderedTicket, setOrderedTicket] = useState({});
  const initialTotals = { quantities: 0, grandTotal: 0, subTotal:0, commission: 0 };
  const [totalTickets, setTotalTickets] = useState(initialTotals);

  const { calcularComision, calcularMontoRecibido, calcularMontoACobrar, roundNumber } = useCommission();

  const [checkSelectedPartial, setCheckSelectedPartial] = useState(false);
  const [valuePartial, setValuePartial] = useState(dataPartial[0].id);
  const [payingValue, setPayingValue] = useState(0);

  useEffect(() => {
    if (params && params.code) {
      setIsLoader(true);
      const eventFromLocation = location?.state?.event;
      const { code } = params;
      if(eventFromLocation){
        console.log('=> desde state')
        fillData(eventFromLocation, true);
      } else {
        getEventBYCode(code).then((r) => console.log('succes' + r));
      }
    }
  }, []);

  const getEventBYCode = async (code) => {
    await GET_EVENTS_BY_CODE_API(code, onSuccessGetEventByCode, onErrorGetEventByCode, onDoneEvent);
  };

  const onSuccessGetEventByCode = (response) => {
    const {
      data: { data, success }
    } = response;
    if (success) {
      console.log('data=> ', data);
      fillData(data);
    }
  };

  const fillData = (data, turnOff=false) => {
    if (data) {
      /**SE ENVIA OBJETO  DATA **/
      setDataEvent(data);
      const { pay_methods, tickets, fee_is_percent, fee, diagram, category_data, table_sizes, date, order, order_items } = data;
      console.log('methods =>', pay_methods?.length);
      if (pay_methods?.length > 0){
        console.log('selectedMethod initial =>', selectedMethod);
        if(!selectedMethod?.id){
          setSelectedMethod(pay_methods[0]);
        }
      }
      /**SE FORMATEA LA FECHA**/
      if (date) {
        setDayEvent(getDayDate(date));
        setMont(getMonthsFormat(date));
      }
      /**SE ENVIA EL COLOR**/
      if (category_data) {
        const { bg_color, tx_color } = category_data;
        setBgColorCard(bg_color || colors.redLight);
        setTextColorCar(tx_color || colors.white);
      }
      setBgColorCardDisabled(colors.grayBlackText);
      setTextColorCarDisabled(colors.white);
      /**SE ENVIA EL TAMAÑO DE LAS MESAS**/
      const listSizes = table_sizes || defaultSizes;
      const map1 = listSizes.map((x) => { 
        const data = {id: `Mesa${x}`, amount: x, name: `Mesa para ${x}`};
        return data;
      });
      
      setAvailableSizes(map1);
      setSelectedTableSize(map1[0]?.amount);
      
      /**SE ENVIA LA IMAGEN**/
      if (diagram) {
        setMapEvent(diagram);
      }
      /**VALORES DEL TICKET**/
      if (tickets && tickets.length > 0) {
        
        const ticketData = {}
        tickets.forEach( ticket => {
          let the_quantity = 0;
          let the_total = 0;
          let the_config = [];
          if( order && order?.is_editable && order_items?.length > 0 ){
            const found = order_items.find((item) => item.object_id === ticket.id);
            if( found ){
              the_quantity = (found?.quantity) ? found?.quantity : 0;
              the_total = the_quantity * ticket.value;

              if(!ticket.is_after){
                if(found?.config?.length > 0){
                  const my_describedTables = [];
                  found?.config?.forEach( size_table => {
                    my_describedTables.push(size_table);
                  });
                  setTableCount(found?.config?.length);
                  setDescribedTables(my_describedTables);
                  the_config = found?.config;
                }
              }
            }
          }
          ticketData[ticket.id] = {
            id: ticket.id,
            name: ticket?.ticket_type_data?.name || `Boleto ${ticket.id}`,
            quantity: the_quantity,
            value: ticket.value,
            total: the_total,
            config: the_config,
          }
          console.log('ticketData[ticket.id] ==>', ticketData[ticket.id]);
        });
        setOrderedTicket(ticketData);

        setCostTicketDinner(tickets[0].value);
        setNameTicketDinner(`Boletos para ${tickets[0]?.ticket_type_data?.name}`);

        setCostTicketAfter(tickets[1].value);
        setNameTicketAfter(`Boletos para ${tickets[1]?.ticket_type_data?.name}`);
      }
      if (fee) {
        setComisionPocesses(fee_is_percent ? parseFloat(fee) / 100 : fee);
      }
    }

    if(turnOff){
      setIsLoader(false);
    }
  }

  const onErrorGetEventByCode = (error) => {
    setIsLoader(false);
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      const msg = message || getMessageError(error);
      showError(msg);
    } else {
      const msg2 = getMessageError(error);
      showError(msg2);
    }
  };

  const onDoneEvent = () => {
    setIsLoader(false);
  };

  const handleChangeSelectTable = (newSize, index) => {
    // const newSize = event?.target?.value;

    const prev = describedTables[index];
    if(prev != newSize){
      if(prev > newSize){
        lessTicket(dataEvent?.tickets[0], prev - newSize, describedTables);
      } else {
        moreTicket(dataEvent?.tickets[0], newSize - prev, describedTables);
      }
      describedTables[index] = newSize;
      setDescribedTables(describedTables);
    }
  }

  const handleMethodChange = (event) => {
    console.log('handleMethodChange =>', event);
    const new_method = dataEvent?.pay_methods.find( item => item.id == event.target.value);
    console.log('new method =>', new_method);
    calcCommission(totalTickets, new_method);
    setSelectedMethod(new_method);
  };

  const partialChanged = (is_partial) => {
    updateValueToPay(totalTickets.subTotal, is_partial, valuePartial, dataEvent?.tickets[0]?.value, selectedMethod, true);
    setCheckSelectedPartial(!checkSelectedPartial);
  }

  const showError = (message) => {
    console.log('message: ' + message);
    setErrorMessage(message);
    setShowAlert(true);
  };

  const calculateTables = (total_tickets, size) => {
    const quotient = Math.floor(total_tickets / size);
    const remainder = total_tickets % size;
    return remainder > 0 ? quotient + 1 : quotient;
  };

  const moreTicket = (ticket_instance, quantity=1, config=[]) => {
    console.log('moreTicket =>', ticket_instance);
    if (orderedTicket[ticket_instance.id]?.quantity > ticket_instance?.item_left) {
      setMessageCount(`No puedes agregar mas de ${ticket_instance.item_left} elementos`);
    } else {
      const prev = orderedTicket;
      prev[ticket_instance.id].quantity += quantity;
      prev[ticket_instance.id].total = prev[ticket_instance.id].quantity * prev[ticket_instance.id].value;
      prev[ticket_instance.id].config = config;

      updateTotals(prev);
      setOrderedTicket({...prev});
    }
  }

  const lessTicket = (ticket_instance, quantity=1) => {
    console.log('lessTicket =>', ticket_instance);
    const prev = orderedTicket;
    if (prev[ticket_instance.id].quantity > 0){
      prev[ticket_instance.id].quantity -= quantity;
    } else {
      prev[ticket_instance.id].quantity = 0;
    }
    prev[ticket_instance.id].total = prev[ticket_instance.id].quantity * prev[ticket_instance.id].value;
    updateTotals(prev);
    setOrderedTicket({...prev});
  }

  const moreTables = (given_size=-1) => {
    const new_size = (given_size > -1) ? given_size : selectedTableSize;

    describedTables.push(new_size);
    console.log('moreTables =>', describedTables);
    
    moreTicket(dataEvent?.tickets[0], new_size, describedTables);
    setTableCount( prev => prev +1);
    setDescribedTables(describedTables);
  }

  const lessTables = () => {
    let newCounter = 0;
    if(tableCount > 0){
      const deleted = describedTables.pop();
      lessTicket(dataEvent?.tickets[0], deleted);
      newCounter = tableCount - 1;
    }
    console.log('lessTables =>', describedTables);
    setTableCount(newCounter);
    setDescribedTables(describedTables);
  }

  const updateTotals = (array_data) => {
    const totals = Object.values(array_data).reduce(
      (accumulator, ticket) => ({ 
        quantities: accumulator.quantities + ticket.quantity,
        subTotal: accumulator.subTotal + ticket.total, 
    }), initialTotals);
    
    setTotalTickets(
      calcCommission(totals)
    );
  }

  const calcCommission = (total_object, newMethod=null) => {
    let current_method = selectedMethod;
    if(newMethod){
      current_method = newMethod
    }
    console.log('selectedMethod =>', current_method);
    
    total_object.grandTotal = calcularMontoACobrar(
      total_object.subTotal,
      current_method.commission_percent,
      current_method.fixed_commission,
      current_method.taxes_on_commission,
      current_method.accept_cents
    );
    total_object.commission = roundNumber(total_object.grandTotal - total_object.subTotal);
    console.log('total_object =>', total_object);

    updateValueToPay(total_object.subTotal, checkSelectedPartial, valuePartial, dataEvent?.tickets[0]?.value, current_method, true);
    
    return total_object;
  }
  
  const updateValueToPay = (grand_total, is_partial, partial_option, unit_value, current_provider, save=true) => {
    // updateValueToPay(totalTickets.subTotal, checkSelectedPartial, valuePartial, dataEvent?.tickets[0]?.value, true)
    
    let partial_amount = grand_total;
    let subTotal = grand_total;
    
    if (is_partial){
      switch(partial_option){
        case 1:
          subTotal = 4 * ((unit_value) ? unit_value : 0);
          break;
        case 2:
          subTotal = subTotal / partial_option;
          break;
        default:
          break;
      }
    }

    if(save){
      partial_amount = calcularMontoACobrar(
        subTotal,
        current_provider.commission_percent,
        current_provider.fixed_commission,
        current_provider.taxes_on_commission,
        current_provider.accept_cents
      );
      setPayingValue(partial_amount);
    }
    return subTotal;
  }

  const addTicketOrder = (ticket) => {
    console.log('addTicketDinner' + ticket);
    if (ticketDinner > ticket?.item_left) {
      setMessageCount(`No puedes agregar mas de ${ticket.item_left} elementos`);
    } else {
      const dinnerTicket = ticketDinner + 1;
      if (dinnerTicket > tableSize) {
        setCountTables(calculateTables(dinnerTicket, tableSize));
      }
      setTicketDinner(dinnerTicket);
      // setNameTicketDinner(`${dinnerTicket} ${ticket?.ticket_type_data?.name}`);
      setNameTicketDinner(`Boletos para ${ticket?.ticket_type_data?.name}`);
      calculateAmountDinner(dinnerTicket);
    }
  };

  const removeTicketOrder = (ticket) => {
    console.log('removeTicketOrder' + ticket);
    const dinnerTicket = ticketDinner - 1;
    if (ticketDinner > 0) {
      /**Se cambia el valor al tamaño de mesas */
      /* if (dinnerTicket < tableSize) {
        setCountTables(countTables - 1);
      } */
      setCountTables(calculateTables(dinnerTicket, tableSize));
      /**SE ENVIAN LOS VALRES PARA CALCULAR EL PRECIO*/
      setTicketDinner(dinnerTicket);
      calculateAmountDinner(dinnerTicket);
    } else {
      /**SE REEINICIAN LOS VALORES*/
      setTicketDinner(0);
      calculateAmountDinner(0);
    }
  };

  const calculateAmountDinner = (countTicketDinner) => {
    setAmountTotalDinnerCalculate(countTicketDinner * costTicketDinner);
    calculateAmount(amountTotalAfterCalculate, countTicketDinner * costTicketDinner);
  };

  const calculateAmount = (amountTotalAfterCalculate, amountTotalDinnerCalculate) => {
    const amountTotalAfterAndDinner = amountTotalAfterCalculate + amountTotalDinnerCalculate;
    setAmountTotalWhiOutProcess(amountTotalAfterAndDinner);
    if (amountTotalAfterAndDinner > 0) {
      const { fee_is_percent } = dataEvent;
      /**SE CALCULA EL COSTO POR PROCESAMIENTO **/
      const costProcessAmount = fee_is_percent
        ? amountTotalAfterAndDinner * parseFloat(comisionPocesses)
        : parseFloat(comisionPocesses);
      setProcessAmount(costProcessAmount);
      setAmountTotal(amountTotalAfterAndDinner + costProcessAmount);
    } else {
      setAmountTotal(amountTotalAfterAndDinner);
      setProcessAmount(0);
    }
  };

  const addTicketAfter = (ticket) => {
    console.log('addTicketDinner' + ticket);
    if (ticketAfter > ticket.item_left) {
      setMessageCount(`No puedes agregar mas de ${ticket.item_left} elementos`);
    } else {
      const ticketRD = ticketAfter + 1;
      setTicketAfter(ticketRD);
      // setNameTicketAfter(`${ticketRD} ${ticket?.ticket_type_data?.name}`);
      setNameTicketAfter(`Boletos para ${ticket?.ticket_type_data?.name}`);
      calculateAmountAfter(ticketRD);
    }
  };

  const calculateAmountAfter = (countTicketAfter) => {
    setAmountTotalAfterCalculate(countTicketAfter * costTicketAfter);
    calculateAmount(countTicketAfter * costTicketAfter, amountTotalDinnerCalculate);
  };

  const removeTicketAfter = (ticket) => {
    console.log('addTicketDinner' + ticket);
    const ticketRD = ticketAfter - 1;
    if (ticketAfter > 0) {
      setTicketAfter(ticketRD);
      calculateAmountDinner(ticketRD);
    } else {
      setTicketAfter(0);
      calculateAmountDinner(0);
    }
  };

  const handleChangeSelect = (tableSelected) => {
    //setValueSelectedTable(tableSelected.name);
    setValueSelectedTable(tableSelected?.target?.value);
  };

  const selectCardTickets = () => {
    setIsCardActive(!isCardActive);
    setShowImgMapEvent(!showImgMapEvent);
  };

  const setValueRadioButton = (value) => {
    console.log(value);
    setValuePaymentType(value);
  };

  const setValueRadioButtonTable = (value) => {
    console.log(value);
    setValueSizeTable(value);
  };

  const goToCarDetails = () => {
    const detailsPriceData = {
      dinnerValues: {
        ticketDinner,
        amountTotalDinnerCalculate,
        nameTicketDinner
      },
      afterValues: {
        ticketAfter,
        amountTotalAfterCalculate,
        nameTicketAfter
      },
      processAmount,
      amountTotalWhiOutProcess,
      amountTotal
    };
    console.log('detailsPriceData: ' + JSON.stringify(detailsPriceData));
    /** valuePaymentType 1 CUENTA UNICA 2 DIVIDIR CUENTA **/
    navigate(k.navigation.carShopping, {
      state: {
        event: dataEvent,
        detailsPriceData,
        typePayment: valuePaymentType
      }
    });
  };

  const handleChangePartialSelect = (event) => {
    setValuePartial(event.target.value);
    updateValueToPay(totalTickets.subTotal, checkSelectedPartial, event.target.value, dataEvent?.tickets[0]?.value, selectedMethod, true);
  };

  const buyingTickets = () => {
    console.log('orderedTicket ==>', orderedTicket);
    return Object.values(orderedTicket).filter(
      (ticket) => ticket.quantity > 0
    )
  }

  const payOrder = async (event) => {
    setIsLoader(true);
    const types = [];
    const quantities = [];
    const configs = [];
    const tickets = buyingTickets();
    tickets.forEach(
      t => {
        types.push(t.id);
        quantities.push(t.quantity);
        configs.push(t.config);
      }
    )
    const total_pay = updateValueToPay(
      totalTickets.subTotal, 
      checkSelectedPartial, 
      valuePartial, 
      dataEvent?.tickets[0]?.value,
      selectedMethod,
      false
    );

    const partial_amount = (total_pay < totalTickets.subTotal) ? total_pay : 0;
    console.log('total_pay ==>', total_pay);
    console.log(' totalTickets.subTotal ==>',  totalTickets.subTotal);
    console.log('partial_amount ==>', partial_amount);

    const data = { 
      configs, types, quantities, partial_amount, 
      pay_provider: selectedMethod.id,
      order_number: dataEvent?.order?.number
    };
    console.log('payOrder data ==>', data);
    // setIsLoader(true);
    await PAYMENT_EVENT_API(params?.code, data, successPayment, onErrorGetEventByCode, onDoneEvent)

  }

  const successPayment = (response) => {
    console.log('response => ', response);
    const { data: { data, success } } = response;
    if (success) {
      navigate(k.navigation.paymentOrder, {
        state: {
          isScreenMain: true,
          dataSales: data,
          event: dataEvent
        }
      });
    }
  }

  const filteredTickets = buyingTickets();

  if (isLoader) {
    return (
      <Box sx={{ flexGrow: 1, justifyContent: 'center' }}>
        <CustomizedCardContainer padding={'10rem'} width={'100%'} isbgbackgroundblack={'true'}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center'
            }}>
            <Loader />
          </Grid>
        </CustomizedCardContainer>
      </Box>
    );
  }

  if (dataEvent) {
    const { category_data, title, subtitle, code, table_sizes, tickets } = dataEvent;
    const bgColorCardF = !isCardActive ? bgColorCard : bgColorCardDisabled;
    const colorTextCardF = !isCardActive ? textColorCard : textColorCardDisabled;
    const bgColorCardS = isCardActive ? bgColorCard : bgColorCardDisabled;
    const colorTextCardS = isCardActive ? textColorCard : textColorCardDisabled;
    const ticketsEventPrincipal = tickets.length > 0 ? tickets[0] : null;
    const ticketsAfterData = tickets.length > 0 ? tickets[1] : null;
    console.log('ticketsEventPrincipal' + JSON.stringify(ticketsEventPrincipal));
    console.log('ticketsAfter' + JSON.stringify(ticketsAfterData));
    return (
      <Box className="containerRootDetailEvent">
        <Grid container className="containerRoot">
          <CustomizedCardContainer padding={'0'} width={'100%'} isbgbackgroundblack={'true'}>
            {showAlert && <AlertCustom errorMessage={errorMessage} />}
            {/**HEADER DETALLE DEL EVENTO**/}
            <Grid
              container
              item
              xs={12}
              sx={{
                ...useStylesSx.containerHeader,
                background: colors.gradientBG
              }}>
              <Grid item xs={6} sm={6} md={8} className="containerTitle">
                {dataEvent?.category_data?.name && (
                  <CustomizedTypography
                    colors={'#302F2F'}
                    size={'2rem'}
                    fontWeight={700}
                    variant={'h4'}>
                    {category_data?.name}
                  </CustomizedTypography>
                )}
              </Grid>
              <Grid item xs={6} sm={6} md={4} className="containerHeaderImg">
                {dataEvent?.category_data?.icon && (
                  <img src={dataEvent?.category_data?.icon} className='responsive-img'/>
                )}
              </Grid>
            </Grid>
            {/**BODY CARD DETALLE DEL EVENTO**/}
            {tickets && tickets.length === 0 ? (
              <Grid container item xs={12} className="containerNotDataRoot">
                <Grid item xs={12} className="containerNotData">
                  <Grid item xs={12}>
                    <CustomizedTypography
                      size={'1.8rem'}
                      colors={colors.black}
                      sx={{ textAlign: 'center' }}
                      variant={'subtitle2'}>
                      No hay boletos disponibles para este evento
                    </CustomizedTypography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container item xs={12} sx={useStylesSx.containerBodyCardRoot}>
                {/**CONTENIDO**/}
                <Grid container item xs={12} spacing={1} sx={useStylesSx.containerBodyCard}>
                  {/**CARD 1**/}
                  {/* <Fade in={!isCardActive} className={`transitionOnOff ${!isCardActive ? 'visible' : 'noVisible'}`}>
                  </Fade> */}
                  <Grid item xs={12} sm={5} md={6}>
                    <Card variant={'outlined'} sx={useStylesSx.cardDetailsEvents}>
                      {/**HEADER**/}
                        <CardHeaderDetailEvent 
                          colorTextCardF={'#302F2F'}
                          customBgColor={'linear-gradient(45deg, rgba(184, 135, 10, 1) 0%, rgba(255, 206, 82, 1) 50%, rgba(245, 183, 24, 1) 100%)'}
                          data={dataEvent} 
                          isActive={!isCardActive}
                          showTitleEvent 
                        />
                      {/**BODY**/}
                      <Grid container item xs={12} sx={useStylesSx.bodyCardSelected}>
                        
                          <TitleEvent title={'Orden'} isCardActive={isCardActive} size={'1.4rem'}/>
                          
                          {/**SEECCION1**/}

                          {
                            tickets?.length > 0 && (
                              <Grid container item xs={12} sx={useStylesSx.containerSelector} key={tickets[0]?.id}>
                                <Grid item xs={12} sm={5} lg={7} sx={useStylesSx.containerRow}>
                                  <SubtitleTitleEvent
                                    isCardActive={isCardActive}
                                    subtitle={`Mesas para ${tickets[0]?.ticket_type_data?.name}`}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={7} lg={5} sx={useStylesSx.detailsEvent}>
                                  <>
                                    {messageCount && <AlertCustom>{messageCount}</AlertCustom>}
                                    <Grid
                                      item
                                      container
                                      sm={10}
                                      xs={10}
                                      className="containerRootPrice">
                                      <CountTicketComponent
                                        messageCount={messageCount}
                                        ticketAvailable={tickets[0]?.item_left}
                                        showLimitTicket={tickets[0]?.has_limit}
                                        isActiveTipeTiket={tickets[0]?.is_active}
                                        count={tableCount}
                                        className="titleContainer"
                                        bgColors={
                                          isCardActive ? colors.grayCountInactive : colors.grayCount
                                        }
                                        colorText={
                                          isCardActive ? colors.grayInactive : colors.black
                                        }
                                        disabledButton={isCardActive}
                                        actionMore={() => moreTables()}
                                        actionLess={() => lessTables()}
                                      />
                                    </Grid>
                                  </>
                                </Grid>
                              </Grid>
                            )
                          }

                          {
                            describedTables?.length > 0 && describedTables.map((value, index) => {
                              return (
                                <SelectComponent
                                  colorValue={colors.black}
                                  data={availableSizes}
                                  widthSelect={1}
                                  valor={value}
                                  handleChangeSelect={(event) => handleChangeSelectTable(event?.target?.value, index)}
                                />
                              )
                            })
                          }

                          {
                            tickets?.length > 1 && (
                              <Grid container item xs={12} sx={useStylesSx.containerSelector} key={tickets[1]?.id}>
                                <Grid item xs={12} sm={5} lg={7} sx={useStylesSx.containerRow}>
                                  <SubtitleTitleEvent
                                    isCardActive={isCardActive}
                                    subtitle={`Boletos para ${tickets[1]?.ticket_type_data?.name}`}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={7} lg={5} sx={useStylesSx.detailsEvent}>
                                  <>
                                    {messageCount && <AlertCustom>{messageCount}</AlertCustom>}
                                    <Grid
                                      item
                                      container
                                      sm={10}
                                      xs={10}
                                      className="containerRootPrice">
                                      <CountTicketComponent
                                        messageCount={messageCount}
                                        ticketAvailable={2 * ((describedTables?.length) ? describedTables?.length : 0)}
                                        showLimitTicket={tickets[1]?.has_limit}
                                        isActiveTipeTiket={tickets[1]?.is_active}
                                        count={orderedTicket[tickets[1]?.id].quantity}
                                        className="titleContainer"
                                        bgColors={
                                          isCardActive ? colors.grayCountInactive : colors.grayCount
                                        }
                                        colorText={
                                          isCardActive ? colors.grayInactive : colors.black
                                        }
                                        disabledButton={isCardActive}
                                        actionLess={() => lessTicket(tickets[1])}
                                        actionMore={() => moreTicket(tickets[1])}
                                      />
                                    </Grid>
                                  </>
                                </Grid>
                              </Grid>
                            )
                          }

                          <EventDivider/>

                          {/**SEECCION2**/}
                          <Grid container item xs={12} sx={useStylesSx.containerSelector}>
                            <Grid item xs={7} sx={useStylesSx.containerRow}>
                              <SubtitleTitleEvent
                                isCardActive={isCardActive}
                                fontSize={'1.2rem'}
                                color={colors.black}
                                subtitle={'Total de boletos'}
                              />
                            </Grid>
                            <Grid item xs={5} sx={useStylesSx.detailsEvent}>
                              <TitleEvent isCardActive={isCardActive} title={totalTickets.quantities} />
                            </Grid>
                          </Grid>

                          {/**BOTON**/}
                          <Grid item mt={10} xs={12} sx={useStylesSx.containerButton}>
                            <CustomizedButton
                              bgcolor={'#321D6C'}
                              onClick={() => selectCardTickets()}
                              maxwidth={'20rem'}
                              disabled={isCardActive || totalTickets.quantities === 0}
                              colortext={'#FFFFFF'}
                              bgcolorinactive={colors.grayInactive}
                              variant={'contained'}>
                              Continuar
                            </CustomizedButton>
                          </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  {/**CARD 2**/}
                  <Grid item xs={12} sm={7} md={6}>
                    <Card variant={'outlined'} sx={useStylesSx.cardSelectedTableRoot}>
                      {/**HEADER**/}
                      <CardHeaderDetailEvent 
                        data={dataEvent} showTitleEvent isActive={isCardActive}
                        colorTextCardF={'#302F2F'}
                        customBgColor={'linear-gradient(45deg, rgba(184, 135, 10, 1) 0%, rgba(255, 206, 82, 1) 50%, rgba(245, 183, 24, 1) 100%)'}
                      />

                      {/**BODY**/}
                      <Grid container item xs={12} sx={useStylesSx.bodyCardSelected}>
                        
                        {/**SEECCION1**/}
                        {
                          (filteredTickets?.length > 0) && (
                            <>
                              <TitleEvent isCardActive={!isCardActive} size={'1.4rem'} title={'Detalles de compra'}/>
                              <CardSectionColumn>
                                {
                                  filteredTickets.map(
                                    ticket => (
                                      <Grid container item xs={12} sx={useStylesSx.containerSelector} key={`ft${ticket?.id}`}>
                                        <Grid item xs={6} className="containerRowShopping">
                                          <SubtitleTitleEvent subtitle={`(${ticket.quantity}) Boletos para ${ticket.name}`} />
                                        </Grid>
                                        <Grid item xs={6} className="containerRowRight">
                                          <SubtitleTitleEvent subtitle={amountFormat(ticket.total)} />
                                        </Grid>
                                      </Grid>
                                    )
                                  )
                                }
                                <Grid container item xs={12} sx={useStylesSx.containerSelector}>
                                  <Grid item xs={6} className="containerRowShopping">
                                    <SubtitleTitleEvent subtitle={'Cargo por servicio'} />
                                  </Grid>
                                  <Grid item xs={6} className="containerRowRight">
                                    <SubtitleTitleEvent subtitle={amountFormat(totalTickets.commission)} />
                                  </Grid>
                                </Grid>
                                <Grid container item xs={12} sx={useStylesSx.containerSelector}>
                                  <Grid item xs={6} className="containerRowShopping">
                                    <SubtitleTitleEvent subtitle={'Total'} />
                                  </Grid>
                                  <Grid item xs={6} className="containerRowRight">
                                    <SubtitleTitleEvent subtitle={amountFormat(totalTickets.grandTotal)} />
                                  </Grid>
                                </Grid>
                              </CardSectionColumn>
                              
                              <EventDivider/>
                            </>
                          )
                        }

                        {/**PAGOS**/}
                        <TitleEvent isCardActive={!isCardActive} size={'1.4rem'} title={'Pago'}/>
                        <Grid container item xs={12} sx={useStylesSx.containerSelector}>
                          <Grid item xs={6} className="containerRowShopping">
                            <SubtitleTitleEvent subtitle={'Total'} />
                          </Grid>
                          <Grid item xs={6} className="containerRowRight">
                            <TitleEvent isCardActive={!isCardActive} size={'1.4rem'} title={amountFormat(payingValue)} customTextColor={'#321D6C'}/>
                          </Grid>

                          <Grid item xs={6} className="containerPartial">
                            <CheckBoxCircule
                              checkSelected={checkSelectedPartial}
                              setCheckSelected={setCheckSelectedPartial}
                              color={colors.black}
                              actionCheck={() => partialChanged(!checkSelectedPartial)}
                              isCheckdefault={false}
                            />
                            <SubtitleTitleEvent subtitle={'Pagar boletos por parcialidades'} />
                          </Grid>
                          <Grid item xs={6} className="containerRowRight">
                            <SelectComponent
                              widthSelect={'11rem'}
                              colorValue={
                                !checkSelectedPartial ? colors.grayInactive : colors.black
                              }
                              data={dataPartial}
                              isRow={true}
                              valor={valuePartial}
                              handleChangeSelect={handleChangePartialSelect}
                              isActive={checkSelectedPartial}
                            />
                          </Grid>
                        </Grid>

                        <EventDivider/>

                        <SubtitleTitleEvent
                          isCardActive={isCardActive}
                          fontSize={'1.2rem'}
                          color={colors.black}
                          subtitle={'Elige el método de pago'}
                        />

                        {
                          dataEvent?.pay_methods && (
                            <RadioGroup
                              aria-label="Payment Method"
                              name="paymentMethod"
                              row
                              value={selectedMethod.id}
                              onChange={handleMethodChange}
                            >
                              {
                                dataEvent?.pay_methods.map(method=>(
                                  <FormControlLabel
                                    id={`method${method.id}`}
                                    value={method.id}
                                    control={<Radio disabled={!isCardActive} checked={method.id === selectedMethod.id}/>}
                                    label={
                                      <div className='labelContainer'>
                                        <img src={method.icon} alt="Credit Card" className='labelImg'/>
                                      </div>
                                    }
                                  />
                                ))
                              }
                            </RadioGroup>
                          )
                        }

                        {/**BOTON**/}
                        <Grid item mt={4} xs={12} sx={useStylesSx.containerButtonRow}>
                          <CustomizedButton
                            onClick={() => selectCardTickets()}
                            bgcolor={colors.whiteGray}
                            colortext={colors.black}
                            maxwidth={'45rem'}
                            disabled={!isCardActive}
                            bgcolorinactive={colors.transparent}
                            colorinactive={colors.grayInactive}
                            variant={'contained'}>
                            Cancelar
                          </CustomizedButton>
                          <CustomizedButton
                            onClick={payOrder}
                            bgcolor={'#321D6C'}
                            maxwidth={'60rem'}
                            colortext={'#FFFFFF'}
                            disabled={!isCardActive}
                            bgcolorinactive={colors.grayInactive}
                            variant={'contained'}>
                            Pagar
                          </CustomizedButton>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  {/* <Fade in={isCardActive} className={`transitionOnOff ${isCardActive ? 'visible' : 'noVisible'}`}>
                  </Fade> */}
                  
                </Grid>
              </Grid>
            )}
          </CustomizedCardContainer>
        </Grid>
      </Box>
    );
  }
  return null;
};
export default DetailGraduationScreen;

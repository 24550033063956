export default {
  signup: '/auth/register/',
  login: 'auth/login/',
  forgotPassword: 'users/change-password/',
  verifyOTP: 'check-otp/',
  validUser: 'auth/activate/',
  codes: '/api/country/',
  terms: '/api/legal/terms-and-conditions',
  privacy: '/api/legal/privacy',
  faqs: '/api/legal/faqs'
};

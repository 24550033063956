import { colors } from '../../../values/colors';

const PADDING_CARD = 1;
const BORDER_RADIUS = 4;

const cardGeneralStyles = {
  borderRadius: BORDER_RADIUS,
  background: colors.whiteGray,
  boxShadow: '0px 6px 13px rgba(0, 0, 0, 0.27)'
};

const useStylesSx = {
  containerBodyCardRoot: {
    display: 'flex',
    flexDirection: 'row',
    padding: 3
  },
  containerBodyCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    background: 'transparent'
  },
  cardDetailsEvents: {
    ...cardGeneralStyles
  },
  headerCardEvent: {
    p: PADDING_CARD
  },
  containerColumnHeaderCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent'
  },
  containerColumnHeaderCardDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    background: 'transparent'
  },
  bodyCardEvent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    background: colors.transparent,
    p: 2
  },
  cardSelectedTableRoot: {
    ...cardGeneralStyles
  },
  headerCardSelected: {
    p: 1
  },
  bodyCardSelected: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    background: colors.transparent,
    p: 2
  },
  cardEventSelected: {
    ...cardGeneralStyles,
    position: 'relative',
    display: 'flex',
    background: colors.grayBlackText,
    borderColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '46rem',
  },
  opacityClass: {
    ...cardGeneralStyles,
    position: 'relative',
    height: '46rem',
    maxHeight: '46rem',
    display: 'flex',
    background: colors.grayBlackText,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.2,
    width: '100%',
  },
  divider: {
    mb: 2.5,
    mt: 1.5,
    bgcolor: colors.grayBlack,
    border: '1px solid rgba(0, 0, 0, 0.3)'
  },
  containerButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    mb: 1
  },
  containerButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  detailsEvent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
    justifyContent: 'flex-start',
    alignSelf: 'center'
  },
  containerSelector: {
    display: 'flex',
    background: 'transparent',
    mt: 1,
    mb: 1
  },
  containerSelectorCol: {
    display: 'flex',
    background: 'transparent',
    flexDirection: 'column',
    mt: 1,
    mb: 1
  },
  containerListCheck: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: 'transparent'
  },
  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem',
    background: colors.redLight
  },
  containerIcon: {
    display: 'flex',
    justifyContent: 'flex-star',
    alignSelf: 'center',
    background: 'transparent'
  },
  icon: {
    fontSize: '1.9rem'
  }
};

export { useStylesSx };

import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { CustomizedTypography } from '../../../componets';
import './styles.css';
import { colors } from '../../../values/colors';
import {
  icMain,
  icVisa,
  icEdit,
  icDelete,
  icEditDark,
  icPencil,
  icMastercard,
  icAmerican
} from '../../../assets';
import { AddCircle } from '@mui/icons-material';
import { useAuth } from '../../../contexts/AuthContext';
import { CardOptionsBackground } from '../../../componets';

const SectionTitleProfile = ({ title, subtitle, fontWeight, fontWeightSubtitle }) => {
  return (
    <Box sx={{ marginTop: 3 }}>
      <CustomizedTypography
        colors={colors.black}
        size={'0.8rem'}
        fontWeight={fontWeight ? fontWeight : 800}
        variant={'h2'}>
        {title}
      </CustomizedTypography>
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}>
        <CustomizedTypography
          mt={0.6}
          colors={colors.black}
          size={'1.2rem'}
          sx={{ mr: '0.8rem' }}
          fontWeight={fontWeightSubtitle ? fontWeightSubtitle : 800}
          variant={'h2'}>
          {subtitle}
        </CustomizedTypography>
        <img src={icEditDark} width={'15rem'} height={'15rem'} />
      </Grid>
    </Box>
  );
};

const Profile = () => {
  const { user } = useAuth();
  const { name, email } = user;

  const listCardPayment = [
    { id: 1, numberCard: '********* 4565', typeCard: 1 },
    { id: 2, numberCard: '********* 4565', typeCard: 2 }
  ];

  const getIconCard = (type) => {
    switch (type) {
      case 1:
        return icVisa;
      case 2:
        return icMastercard;
      case 3:
        return icAmerican;
      default:
        return icVisa;
    }
  };

  return (
    <CardOptionsBackground idPage={1}>
      <Grid container item xs={12} className="containerBodyCard">
        <Grid container item xs={4} className="containerImgProfile">
          <img src={icMain} width={'50%'} />
          <img src={icPencil} className="imgPencil" />
        </Grid>
        <Grid container item xs={8} className="containerBody">
          <Box sx={{ maxWidth: '100%', width: '100%' }}>
            <SectionTitleProfile title={'Nombre'} subtitle={name} />
            <Grid container item xs={12} mt={2} mb={2}>
              <Grid container item xs={4}>
                <SectionTitleProfile title={'Telefono'} subtitle={'5515824001'} />
              </Grid>
              <Grid container item xs={8}>
                <SectionTitleProfile title={'Correo'} subtitle={email} />
              </Grid>
            </Grid>
            <SectionTitleProfile mt={2} title={'Contraseña'} subtitle={'****************'} />
            <Divider
              sx={{
                mb: 1,
                mt: 2,
                bgcolor: colors.grayBlack,
                border: '0.5px solid rgba(0, 0, 0, 0.3)'
              }}
            />
          </Box>
          <Grid item xs={12} mt={2}>
            <CustomizedTypography colors={colors.red} size={'1rem'} fontWeight={800} variant={'h2'}>
              Metodos de pago
            </CustomizedTypography>
            {/**METODOS DE PAGO**/}
            <Grid container item xs={12} mt={2} mb={2} className="containerRootPaymentList">
              <Grid item>
                <AddCircle
                  color={'primary'}
                  sx={{
                    fontSize: '1.9rem',
                    color: colors.grayBlackText
                  }}
                />
              </Grid>
              <Grid item>
                <CustomizedTypography
                  ml={1}
                  colors={colors.black}
                  size={'0.9rem'}
                  fontWeight={800}
                  variant={'h2'}>
                  Agregar métodos de pago
                </CustomizedTypography>
              </Grid>
            </Grid>
            {listCardPayment && listCardPayment.length > 0 && (
              <>
                {listCardPayment.map((cardPayment) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Grid
                      key={cardPayment.id}
                      container
                      item
                      xs={6}
                      className="containerListPayment">
                      <CustomizedTypography
                        colors={colors.black}
                        size={'1.3rem'}
                        fontWeight={800}
                        variant={'h2'}>
                        {cardPayment.numberCard}
                      </CustomizedTypography>
                      <img src={getIconCard(cardPayment.typeCard)} width={'50rem'} />
                      <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="containerButtonAction">
                          <img src={icEdit} width={'18rem'} />
                        </div>
                        <div className="containerButtonAction">
                          <img src={icDelete} width={'18rem'} />
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CardOptionsBackground>
  );
};

export default Profile;

import { styled } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';
import { colors } from '../../values/colors';

const CustomizedFormHelperText = styled(FormHelperText)`
  color: ${(props) => (props.color ? `${props.color}` : `${colors.white}`)};
  font-family: Inter;
`;

export default CustomizedFormHelperText;

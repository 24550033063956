import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { CustomizedTypography, CardOptionsBackground } from '../../../componets';
import { colors } from '../../../values/colors';
import CustomizedTables from '../../../componets/TableCustom';
import { Typography } from '@material-ui/core';
import { headersPaymentTable } from '../../../utils/HederTables';

import './styles.css';

const listPayments = [
  {
    id: 1,
    reference: 'Referencia: GraIB_12311231231231123',
    date: '2/02/2023, 13:02:33',
    amount: '$ 10,050.00',
    status: { type: 1, name: 'Aprobada' }
  },
  {
    id: 2,
    reference: 'Referencia: Otban_156354634563456',
    date: '28/02/2023, 16:42:19',
    amount: '$ 4,050.00',
    status: { type: 1, name: 'Aprobada' }
  }
];

const PaymentMethodsScreen = () => {
  const [dataTable, setDataTable] = useState({ headers: headersPaymentTable, body: [] });
  

  const getColor = (type) => {
    switch (type) {
      case 1:
        return "textGreen";
      case 2:
        return "textRed";
      default:
        return "textBlack";
    }
  };

  const getStatusIcons = (status) => {
    if (status) {
      return (
        <div>
          <Typography className={status && status.type ? getColor(status.type) : "textBlack"}>
            {status.name}
          </Typography>
        </div>
      );
    }
    return null;
  };

  const getStyleReference = (reference) => {
    if (reference) {
      return (
        <div>
          <Typography className="textBlack">{reference}</Typography>
        </div>
      );
    }
    return null;
  };

  //FUNCION QUE CONSTRUYE EL BODY DEL DEPARTAMENTO
  const buildBodyDataPayment = (payments) => {
    return payments.map((payment) => {
      const { id, reference, date, amount, status } = payment;
      return [id, getStyleReference(reference), date, amount, getStatusIcons(status)];
    });
  };

  //FUNCION QIUE CONSTRUYE EL HEADER DE LA TABLA DEPARTAMENTO
  const buildDataTablePayment = (payment) => {
    const body = buildBodyDataPayment(payment);
    const headers = headersPaymentTable;
    return { headers, body };
  };

  useEffect(() => {
    setDataTable(buildDataTablePayment(listPayments));

    return () => {
      return function cleanup() {
        setDataTable(buildDataTablePayment(listPayments));
      };
    };
  }, [listPayments]);

  return (
    <CardOptionsBackground idPage={3}>
      <Grid item xs={12} sm={12} md={12} className="containerPaymentMethods">
        <CustomizedTypography colors={colors.black} size={'1.3rem'} fontWeight={700} variant={'h4'}>
          Resumen de pagos
        </CustomizedTypography>
      </Grid>
      <Grid mt={2} item xs={12}>
        <CustomizedTables dataTable={dataTable} />
      </Grid>
    </CardOptionsBackground>
  );
};

export default PaymentMethodsScreen;

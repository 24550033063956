import k from './../values/key';

export const setSession = async (value) => {
  try {
    await localStorage.setItem(k.storage.session, JSON.stringify(value));
  } catch (e) {
    console.error('e' + e);
  }
};

export const removeSession = async () => {
  try {
    await localStorage.removeItem(k.storage.session);
  } catch (e) {
    console.error('e' + e);
  }
};

export const getToken = async () => {
  try {
    const session = localStorage.getItem(k.storage.session);
    return JSON.parse(session).token;
  } catch (e) {
    return null;
  }
};

export const setUserRecord = async (data) => {
  console.log('data' + JSON.stringify(data));
  try {
    await localStorage.setItem(k.storage.record, JSON.stringify(data));
  } catch (e) {
    console.error('e' + e);
  }
};

export const removeUserRecord = async () => {
  try {
    await localStorage.removeItem(k.storage.record);
  } catch (e) {
    console.error('e' + e);
  }
};

export const getUserRecord = async () => {
  try {
    const session = localStorage.getItem(k.storage.record);
    console.log('session' + session);
    return JSON.parse(session);
  } catch (e) {
    return null;
  }
};

import styled from 'styled-components';

const CustomizedCardContainer = styled.div`
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: auto;
  max-width: 98%;
  overflow-y: auto;
  margin: ${(props) => (props.margin ? props.margin : '30px')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 100;
  padding: ${(props) => (props.padding ? props.padding : '6vh')};
  background: ${(props) =>
    props?.customBG
      ? props.customBG
      : !props.isbgbackgroundblack
        ? 'linear-gradient(177.94deg, #E84A43 73.58%, #000000 147.88%)'
        : 'linear-gradient(170.29deg, #FFFFFF 67.5%, #A6A6A6 137.31%)'};
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.37);
  border-radius: 30px;
  @media (max-width: 60rem) {
    height: auto;
    max-height: none;
  },
`;

export default CustomizedCardContainer;

import React, { useEffect, useState } from 'react';
import { AlertCustom, CustomizedTypography, Loader } from '../../../componets';
import { Box, Grid } from '@mui/material';
import CustomizedCardContainer from '../../../componets/CustomizedComponents/CustomizedCardContainer';
import { GET_TERMS_AND_CONDITIONS_API } from '../../../api';
import { colors } from '../../../values/colors';
import { getMessageError } from '../../../api/conf';
import './styles.css';

const TermsAndConditions = () => {

  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [isLoadingView, setIsLoadingView] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    getTerms();
  });

  const getTerms = async () => {
    await GET_TERMS_AND_CONDITIONS_API(onSuccessGetTerms, onErrorGetTerms, onDoneGetTerms);
  };
  const onSuccessGetTerms = (response) => {
    const {
      data: { body }
    } = response;
    setTermsAndConditions(body);
    setIsLoadingView(false);
  };
  const onErrorGetTerms = (error) => {
    const {
      response: {
        data: { message }
      }
    } = error;
    showAlertMessage(message || getMessageError(error));
  };
  const showAlertMessage = (message) => {
    setErrorMessage(message);
    setShowAlert(true);
  };
  const onDoneGetTerms = () => {
    setIsLoadingView(false);
  };
  if (isLoadingView) {
    return (
      <Box className='loader'>
        <Grid item xs={12}>
          <Loader />
        </Grid>
      </Box>
    );
  }
  return (
    <Box className='rootTerms'>
      {showAlert && <AlertCustom errorMessage={errorMessage} />}
      <Grid container className='containerRoot'>
        <CustomizedCardContainer padding={'0'} width={'100%'} isbgbackgroundblack={'true'}>
          <Grid container item xs={12} className='containerBodyCard'>
            <CustomizedTypography
              colors={colors.black}
              size={'1rem'}
              dangerouslySetInnerHTML={{ __html: termsAndConditions }}
            />
          </Grid>
        </CustomizedCardContainer>
      </Grid>
    </Box>
  );
};

export default TermsAndConditions;

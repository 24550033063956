import { styled, Button } from '@mui/material';
import { colors } from '../../values/colors';

const CustomizedButton = styled(Button)((props) => ({
  boxShadow: 'none',
  textTransform: 'none',
  maxWidth: `${props.maxwidth ? props.maxwidth : '11.75rem'}`,
  width: `${props.maxwidth ? props.maxwidth : '11.75rem'}`,
  maxHeight: `${props.maxheight ? props.maxheight : '3.72rem'}`,
  fontSize: `${props.sizefont ? props.sizefont : '1rem'}`,
  padding: '6px',
  border: '1px solid',
  lineHeight: 1.5,
  color: `${props.colortext ? props.colortext : colors.grayBlackText}`,
  borderRadius: 50,
  // backgroundColor: `${props.bgcolor ? props.bgcolor : colors.grayLight}`,
  background: `${props.bgcolor ? props.bgcolor : colors.grayLight}`,
  borderColor: `${props.bgcolor ? props.bgcolor : colors.grayLight}`,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  '&:hover': {
    backgroundColor: `${props.bgcolor ? props.bgcolor : colors.grayLight}`,
    borderColor: `${props.bgcolor ? props.bgcolor : colors.grayLight}`,
    boxShadow: 'none'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: `${props.bgcolor ? props.bgcolor : colors.grayLight}`,
    borderColor: `${props.bgcolor ? props.bgcolor : colors.grayLight}`
  },
  '&:focus': {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.12)'
  },
  '&:disabled': {
    color: `${props.colorinactive ? props.colorinactive : colors.white}`,
    backgroundColor: `${props.bgcolorinactive ? props.bgcolorinactive : colors.grayLight}`,
    borderColor: `${props.bgcolorinactive ? props.bgcolorinactive : colors.grayLight}`,
    borderWidth: 0,
    boxShadow: 'none'
  }
}));

export default CustomizedButton;

import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { colors } from '../../values/colors';

const BootstrapInput = styled((props) => <InputBase {...props} />)(({ theme }) => ({
  'label + &': {
    color: '#000000'
  },
  '& .MuiInputBase-input': {
    border: '1px solid #e2e2e1',
    color: '#000000',
    borderRadius: 50,
    fontSize: '0.8rem',
    maxHeight: '4rem',
    height: '4rem',
    marginTop: theme.spacing(2),
    position: 'relative',
    padding: '14px 27px 14px 16px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.mode === 'light' ? `${colors.white}` : '#2b2b2b',
    justifyContent: 'center',
    alignItems: 'center',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      backgroundColor: theme.palette.mode === 'light' ? `${colors.white}` : '#2b2b2b',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderColor: colors.white,
      borderRadius: 50,
      maxHeight: '4rem',
      height: '4rem',
      marginTop: theme.spacing(2.2),
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000000'
    }
  }
}));

export default BootstrapInput;

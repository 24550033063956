import React from 'react';
import { CustomizedCardContainer } from './componets';
function App() {
  return (
    <CustomizedCardContainer isbgbackgroundblack={'true'}>
      <h2>Error 404</h2>
      <p>La página que buscas no existe o fue cambiada de lugar</p>
    </CustomizedCardContainer>
  );
}

export default App;

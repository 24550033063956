import { GET_AUTH, POST_AUTH, DELETE_AUTH, PATCH_AUTH } from '../conf';
import paths from '../guest/paths';

export const GET_GUEST_API = async (onSuccess, onError, onDone) => {
  await GET_AUTH(`${paths.guest}`, onSuccess, onError, onDone);
};

export const GET_GUEST_BY_IDGUEST = async (id, onSuccess, onError, onDone) => {
  await GET_AUTH(`${paths.guest}${id}`, onSuccess, onError, onDone);
};

export const SAVE_GUEST_API = async (data, onSuccess, onError, onDone) => {
  await POST_AUTH(`${paths.guest}`, data, onSuccess, onError, onDone);
};

export const DELETE_GUEST_API = async (id, onSuccess, onError, onDone) => {
  await DELETE_AUTH(`${paths.guest}${id}/`, onSuccess, onError, onDone);
};

export const EDIT_GUEST_API = async (id, data, onSuccess, onError, onDone) => {
  await PATCH_AUTH(`${paths.guest}${id}/`, data, onSuccess, onError, onDone);
};

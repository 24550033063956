import { GET_NO_AUTH, POST_NO_AUTH } from '../conf';
import paths from './paths';

export const SIGNUP_API = async (data, onSuccess, onError, onDone) => {
  await POST_NO_AUTH(paths.signup, data, onSuccess, onError, onDone);
};

export const LOGIN_API = async (data, onSuccess, onError, onDone) => {
  await POST_NO_AUTH(paths.login, data, onSuccess, onError, onDone);
};

export const VALIDATE_USER_API = async (id, token, onSuccess, onError, onDone) => {
  await GET_NO_AUTH(`${paths.validUser}${id}/${token}/`, onSuccess, onError, onDone);
};

export const GET_CODE_PHONES = async (onSuccess, onError, onDone) => {
  await GET_NO_AUTH(`${paths.codes}`, onSuccess, onError, onDone);
};

export const GET_TERMS_AND_CONDITIONS_API = async (onSuccess, onError, onDone) => {
  await GET_NO_AUTH(`${paths.terms}`, onSuccess, onError, onDone);
};

export const GET_PRIVACY_API = async (onSuccess, onError, onDone) => {
  await GET_NO_AUTH(`${paths.privacy}`, onSuccess, onError, onDone);
};

export const GET_FAQS_API = async (onSuccess, onError, onDone) => {
  await GET_NO_AUTH(`${paths.faqs}`, onSuccess, onError, onDone);
};

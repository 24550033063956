import { colors } from '../../../values/colors';

const useStylesSx = {
  containerCardRoot: {
    margin: 'auto',
    maxWidth: '100%',
    flexGrow: 1,
    backgroundColor: 'transparent',
    borderRadius: 4
  },
  containerCard: {
    background: 'transparent'
  },
  containerHeaderEvent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 1,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15
  },
  headerTitle: {
    display: 'flex',
    background: 'transparent',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  containerImg: {
    display: 'flex',
    background: 'transparent',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  eventRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  eventRowDetailsEvents: {
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent'
  },
  textEvent: {
    fontWeight: '400',
    color: colors.black,
    fontSize: '1rem'
  },
  textEventDay: {
    color: colors.black,
    fontWeight: 600,
    fontSize: '2rem'
  },
  containerEventRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'flex-start',
    background: 'transparent'
  },
  containerSearch: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    //alignItems: 'center',
    background: 'transparent'
  },
  containerSelectedFilter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent'
  },
  iconButtonEvent: {
    width: 70,
    height: 70,
    ml: 3,
    p: 0
  }
};

export { useStylesSx };

export default {
  navigation: {
    login: '/',
    terms: '/terms',
    privacy: '/privacy',
    faqs: 'faqs',
    createAccount: '/createAccount',
    
    tables: '/tables',
    events: '/events',
    detailsEvent: '/detailEvent/',
    detailsGraduation: '/detailGraduation/',
    selectTables: '/eventTables/',

    ordersSales: '/orderSales',
    all: '*',
    paymentOrder: '/paymentOrder',
    detailsOrder: '/detailOrderScreen',
    validate: '/validate/',
    close: '/exit',
    profile: '/profile',
    boletera: '/boletera',
    carShopping: '/buys',
    guest: '/guests',
    paymentMethod: '/paymentMethods',
    notifications: '/notifications',
    orderCompleted: '/orderCompleted',
    selectionCompleted: '/selectionCompleted',
    
    myEvents: '/myEvents/',
  },
  status: {
    unauthorized: 401
  },
  storage: {
    session: 'user',
    record: 'userRecord'
  }
};

import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import RouteUserGuest from './RouteUserGues';
import NavBar from '../layout/NavBar';
import {
  LoginScreen,
  SingUpScreen,

  EventsScreen,
  DetailsEventScreen,
  SelectionCompleted,
  SelectTables,

  ProfileScreen,
  BoleteraScreen,
  GuestScreen,
  PaymentMethodsScreen,
  ShoppingCarScreen,
  Notifications,
  TermsAndConditions,
  PrivacyPolicy,
  FrequentQuestions,
  PaymentWebViewScreen,

  MyEvents,
  MyOrders,
  MyTransactions,
  TestScreen,

  DetailGraduationScreen,
} from '../screens';
import k from '../values/key';
import Toolbar from '@mui/material/Toolbar';
import { useAuth } from '../contexts/AuthContext';
import BackgroundTickets from '../componets/BackgroundTickets';
import App from '../App';
import './styles.css';

const MainNavigationRoot = () => {
  const { user } = useAuth();

  return (
    <BackgroundTickets>
      <Box sx={{ display: 'flex' }}>
        <BrowserRouter>
          {user && (
            <NavBar />
          )}
          <div className='div-main'>
            <Toolbar />
            <div className='content'>
              <Routes>
                <Route
                  index
                  path={k.navigation.login}
                  element={
                    <RouteUserGuest>
                      <LoginScreen />
                    </RouteUserGuest>
                  }
                />
                <Route
                  path={k.navigation.terms}
                  element={
                    <RouteUserGuest>
                      <TermsAndConditions />
                    </RouteUserGuest>
                  }
                />
                <Route
                  path={k.navigation.privacy}
                  element={
                    <RouteUserGuest>
                      <PrivacyPolicy />
                    </RouteUserGuest>
                  }
                />
                <Route
                  path={'test'}
                  element={
                    <RouteUserGuest>
                      <TestScreen />
                    </RouteUserGuest>
                  }
                />
                <Route path={k.navigation.faqs} element={<FrequentQuestions />} />
                <Route
                  exact
                  path={`${k.navigation.validate}:id/:token/`}
                  element={
                    <RouteUserGuest>
                      <LoginScreen />
                    </RouteUserGuest>
                  }
                />
                <Route
                  exact
                  path={k.navigation.createAccount}
                  element={
                    <RouteUserGuest>
                      <SingUpScreen />
                    </RouteUserGuest>
                  }
                />
                <Route exact path={k.navigation.all} element={<App />} />
 
                <Route
                  path={k.navigation.events}
                  element={
                    <ProtectedRoute>
                      <EventsScreen is_buy/>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={k.navigation.tables}
                  element={
                    <ProtectedRoute>
                      <EventsScreen is_buy={false} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.detailsEvent}:code`}
                  element={
                    <ProtectedRoute>
                      <DetailsEventScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.detailsGraduation}:code`}
                  element={
                    <ProtectedRoute>
                      <DetailGraduationScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.selectTables}:code`}
                  element={
                    <ProtectedRoute>
                      <SelectTables />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.profile}`}
                  element={
                    <ProtectedRoute>
                      <ProfileScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.boletera}`}
                  element={
                    <ProtectedRoute>
                      <BoleteraScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.guest}`}
                  element={
                    <ProtectedRoute>
                      <GuestScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.paymentMethod}`}
                  element={
                    <ProtectedRoute>
                      <PaymentMethodsScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.carShopping}`}
                  element={
                    <ProtectedRoute>
                      <ShoppingCarScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.paymentOrder}`}
                  element={
                    <ProtectedRoute>
                      <PaymentWebViewScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.notifications}`}
                  element={
                    <ProtectedRoute>
                      <Notifications />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.selectionCompleted}`}
                  element={
                    <ProtectedRoute>
                      <SelectionCompleted />
                    </ProtectedRoute>
                  }
                />
                
                <Route
                  path={`${k.navigation.myEvents}`}
                  element={
                    <ProtectedRoute>
                      <MyEvents />
                    </ProtectedRoute>
                  }
                />
                
                <Route
                  path={`${k.navigation.myEvents}:code`}
                  element={
                    <ProtectedRoute>
                      <MyOrders />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${k.navigation.myEvents}:code/orders/:order_number`}
                  element={
                    <ProtectedRoute>
                      <MyTransactions />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      </Box>
    </BackgroundTickets>
  );
};
export default MainNavigationRoot;

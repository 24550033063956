import React, { useEffect, useState } from 'react';
import {
  AlertCustom,
  CustomizedTypography,
  Loader,
  CustomizedCardContainer
} from '../../../componets';
import { Box, Grid } from '@mui/material';

import { GET_FAQS_API } from '../../../api';
import { colors } from '../../../values/colors';
import { getMessageError } from '../../../api/conf';
import './styles.css';

const FrequentQuestions = () => {

  const [frequentQuestions, setFrequentQuestions] = useState(null);
  const [isLoadingView, setIsLoadingView] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const getFrequentQuestions = async () => {
    await GET_FAQS_API(
      onSuccessGetFrequentQuestions,
      onErrorGetFrequentQuestions,
      onDoneGetFrequentQuestions
    );
  };

  useEffect(() => {
    getFrequentQuestions();
  }, []);

  const onSuccessGetFrequentQuestions = (response) => {
    const {
      data: { data, success }
    } = response;
    if (success) {
      setFrequentQuestions(data);
      setIsLoadingView(false);
    }
  };

  const onErrorGetFrequentQuestions = (error) => {
    const {
      response: {
        data: { message }
      }
    } = error;
    showAlertMessage(message || getMessageError(error));
  };
  const showAlertMessage = (message) => {
    setErrorMessage(message);
    setShowAlert(true);
  };
  const onDoneGetFrequentQuestions = () => {
    setIsLoadingView(false);
  };

  if (isLoadingView) {
    return (
      <Box className='containerLoader'>
        <Grid container item xs={12}>
          <Loader />
        </Grid>
      </Box>
    );
  }
  return (
    <Box className='root'>
      {showAlert && <AlertCustom errorMessage={errorMessage} />}
      <Grid container className='containerRoot'>
        <CustomizedCardContainer padding={'1rem'} width={'100%'} isbgbackgroundblack={'true'}>
          <Grid container item xs={12} className='containerBodyCard'>
            <CustomizedTypography mb={2} fontWeight={'800'} colors={colors.redLight} size={'2rem'}>
              Preguntas frecuentes
            </CustomizedTypography>
            {frequentQuestions && frequentQuestions.length && (
              <Grid>
                {frequentQuestions.map((faq) => (
                  <Grid item mb={3} key={faq.id}>
                    <CustomizedTypography
                      mb={1}
                      fontWeight={'800'}
                      colors={colors.black}
                      size={'1.4rem'}>
                      {faq?.question}
                    </CustomizedTypography>
                    <CustomizedTypography
                      fontWeight={'400'}
                      dangerouslySetInnerHTML={{ __html: faq?.answer }}
                      colors={colors.grayBlackText}
                      size={'1.1rem'}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </CustomizedCardContainer>
      </Grid>
    </Box>
  );
};

export default FrequentQuestions;

import { Grid } from '@material-ui/core';
import { icPeopleSuccess } from '../../../assets';
import { CustomizedLoadingButton, CustomizedTypography } from '../../../componets';
import './styles.css';

const ValidUser = ({ isLoadingButton, actionValidUser, dataUser }) => {

  return (
    <Grid container>
      <Grid item className='containerUserRemember'>
        <img src={icPeopleSuccess} width={'30%'} height={'30%'} />
        <CustomizedTypography
          fontWeight={700}
          mt={5}
          size={'2rem'}
          variant={'h6'}
          component={'div'}>
          Registro exitoso
        </CustomizedTypography>
        <CustomizedTypography
          fontWeight={400}
          mt={5}
          size={'1.5rem'}
          variant={'h2'}
          component={'div'}>
          Bienvenido
        </CustomizedTypography>
        {dataUser && dataUser.name && (
          <CustomizedTypography
            fontWeight={600}
            mt={5}
            size={'1.4rem'}
            variant={'h2'}
            component={'div'}>
            {dataUser.name}
          </CustomizedTypography>
        )}
        <CustomizedTypography
          fontWeight={400}
          mt={5}
          size={'1.5rem'}
          variant={'h2'}
          component={'div'}>
          Inicia sesión para concluir el registro.
        </CustomizedTypography>
        <div onClick={() => actionValidUser()} className='margin'>
          <CustomizedLoadingButton
            mt={10}
            type={'submit'}
            widthbottom={'343px'}
            fullWidth
            loading={isLoadingButton}
            variant={'contained'}>
            Iniciar sesión
          </CustomizedLoadingButton>
        </div>
      </Grid>
    </Grid>
  );
};

export default ValidUser;

import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import RoutesNavLinkMenuOptions from '../HeaderListOptionsMenu';
import CustomizedCardContainer from '../CustomizedComponents/CustomizedCardContainer';
import k from '../../values/key';
import './styles.css';


const pages = [
  { id: 1, name: 'Perfil', route: k.navigation.profile },
  {
    id: 2,
    name: 'Mi boletera',
    route: k.navigation.boletera
  },
  {
    id: 3,
    name: 'Métodos de pago',
    route: k.navigation.paymentMethod
  },
  { id: 4, name: 'Invitados', route: k.navigation.guest }
];

const CardOptionsBackground = ({ children, idPage }) => {

  const [selectedPage, setSelectedPage] = useState(idPage);

  const handleCloseNavMenu = (page) => {
    setSelectedPage(page.id);
  };
  return (
    <Box className='rootProfile'>
      <Grid container className='containerRoot'>
        <CustomizedCardContainer padding={'0'} width={'100%'} isbgbackgroundblack={'true'}>
          {/**HEADER DETALLE DEL EVENTO**/}
          <Grid container item xs={12} className='containerHeader'>
            <RoutesNavLinkMenuOptions
              selectedPage={selectedPage}
              pages={pages}
              handleCloseNavMenu={handleCloseNavMenu}
            />
          </Grid>
          <Grid container item xs={12} className='containerBodyCard'>
            {children}
          </Grid>
        </CustomizedCardContainer>
      </Grid>
    </Box>
  );
};

export default CardOptionsBackground;

import * as React from 'react';
import { FormControlLabel, FormControl, RadioGroup, Radio } from '@mui/material';
import { colors } from '../values/colors';
import { CustomizedTypography } from './index';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

// Inspired by blueprintjs
function BpRadioCustom(props) {
  return (
    <Radio
      disableRipple
      color={'default'}
      checkedIcon={props.checkedIcon ? props.checkedIcon : <RadioButtonCheckedIcon />}
      icon={props.icon ? props.icon : <RadioButtonUncheckedIcon />}
      {...props}
    />
  );
}

const RadioButtonsGroupComponent = ({
  listRadioButton,
  color,
  subtitleColors,
  actionRadioButton,
  isRow,
  valueInitial,
  disabled,
  icon,
  checkedIcon,
  sizeTitleCheck
}) => {
  const [value, setValue] = React.useState(valueInitial);

  const handleChange = (event) => {
    setValue(event.target.value);
    actionRadioButton(event.target.value);
  };

  const nameStyle = isRow ? 'row-radio-buttons-group' : 'controlled-radio-buttons-group';
  const labelledby = isRow
    ? 'demo-row-radio-buttons-group-label'
    : 'demo-controlled-radio-buttons-group';

  return (
    <FormControl>
      <RadioGroup
        row={isRow}
        aria-labelledby={labelledby}
        name={nameStyle}
        value={value}
        onChange={handleChange}>
        {listRadioButton.map((radio, index) => (
          <div key={index}>
            <FormControlLabel
              disabled={disabled}
              key={radio.id ? radio.id : radio}
              value={radio.value ? radio.value : radio}
              sx={{ background: 'transparent' }}
              control={
                <BpRadioCustom
                  icon={icon}
                  checkedIcon={checkedIcon}
                  sx={{
                    color: color ? color : colors.black,
                    '&.Mui-checked': {
                      color: color ? color : colors.blue
                    },
                    '& .MuiSvgIcon-root': { fontSize: '1.3rem' }
                  }}
                />
              }
              label={
                <CustomizedTypography
                  colors={color ? color : colors.blackText}
                  size={sizeTitleCheck ? sizeTitleCheck : '1rem'}
                  fontWeight={400}
                  level={'h2'}>
                  {radio.name ? radio.name : radio}
                </CustomizedTypography>
              }
            />
            {radio.subtitle && (
              <CustomizedTypography
                colors={subtitleColors ? subtitleColors : colors.textDisabled}
                level={'subtitle2'}
                size={'0.8rem'}>
                {radio.subtitle}
              </CustomizedTypography>
            )}
          </div>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroupComponent;

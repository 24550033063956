import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';

import './style.css';

import { ReactComponent as ShoppingMap } from '../../assets/maps/tomas_moro_final.svg';
// import { ReactComponent as ShoppingMap } from '../../assets/maps/up_junio10.svg';

const InteractiveMap = ({ source, tables, onFilterExisting, onTableClicked }) => {
  const [loaded, setLoaded] = useState(false);

  const existing = [];

  const initialState = {
    top: 0,
    left: 0,
    places: 0,
    name: ''
  };

  const checkDuplicate = (id) => {
    return existing.includes(id);
  };

  const [tooltip, setTooltip] = useState(initialState);

  const getStoreData = (event) => {

    const item = event.target.classList.contains('map-clickable')
      ? event.target
      : event.target.parentElement;
    const storeId = item.getAttribute('id');
    return storeId ? tables.find((s) => s.id === storeId) : storeId;
  };

  const getTooltipDom = () => document.querySelector('.map-tooltip');

  const clearTooltip = () => {
    setTooltip({ ...tooltip, ...initialState });
  };

  const handlePolygonClick = (event) => {
    const store = getStoreData(event);
    if (store) {
      console.log('Hallado', store);
      onTableClicked(store);
    }
  };

  const handlePolygonMouseMove = (event) => {
    const dom_tooltip = getTooltipDom();
    const map_right = document.getElementById('map_container').getBoundingClientRect().right;
    const map_bottom = document.getElementById('map_container').getBoundingClientRect().bottom;

    // dom_tooltip.style.top = event.clientY;
    // dom_tooltip.style.left = event.clientX;

    const { top, right, left, bottom } = event.target.getBoundingClientRect();
    dom_tooltip.style.top = top + 'px';
    dom_tooltip.style.left = left + 'px';
    // dom_tooltip.style.top = top;
    // dom_tooltip.style.left = left;
  };

  const handlePolygonMouseLeave = (event) => {
    setTooltip({ ...tooltip, name: null });
    const dom_tooltip = getTooltipDom();
    dom_tooltip.style.display = 'none';
  };

  const handlePolygonMouseEnter = (event) => {
    const store = getStoreData(event);
    if (store) {
      const { top, left } = event.target.getBoundingClientRect();
      setTooltip({ ...tooltip, top: top, left: left, name: store.name, places: store.places });
      const dom_tooltip = getTooltipDom();
      // dom_tooltip.innerHTML = `<b>${tooltip?.name}</b>`
      dom_tooltip.style.display = 'block';
    } else {
      clearTooltip();
    }
  };

  const setUpMap = () => {
    const polygons = document.querySelectorAll('.map-clickable');
    polygons.forEach((polygon) => {
      polygon.addEventListener('click', handlePolygonClick);
      polygon.addEventListener('mouseover', handlePolygonMouseEnter);
      polygon.addEventListener('mouseout', handlePolygonMouseLeave);
      polygon.addEventListener('mousemove', handlePolygonMouseMove);

      const pol_id = polygon.getAttribute('id');

      const item_found = tables.find((s) => s.id === pol_id);

      if (item_found) {
        polygon.classList.remove('empty', 'full', 'normal');
        if (item_found.places > 0) {
          polygon.classList.add(item_found.places == 10 ? 'empty' : 'normal');
        } else {
          polygon.classList.add('full');
        }
        if (!checkDuplicate(item_found.id)) {
          existing.push(item_found.id);
        }
      }
    });

    onFilterExisting(existing);
  };

  const clearMap = () => {
    const polygons = document.querySelectorAll('.map-clickable');
    polygons.forEach((polygon) => {
      polygon.removeEventListener('click', handlePolygonClick);
      polygon.removeEventListener('mouseover', handlePolygonMouseEnter);
      polygon.removeEventListener('mouseout', handlePolygonMouseLeave);
      polygon.removeEventListener('mousemove', handlePolygonMouseMove);

      polygon.classList.remove('empty', 'full', 'normal');
    });
  };

  useEffect(() => {
    console.log('----------Rendering');
    /* if (loaded) {
      setUpMap();
    } */
    setUpMap();
    return () => {
      clearMap();
    };
  }, [tables]);

  return (
    <>
      <ShoppingMap id='map_container' />
      {/* <SVG
        id='map_container'
        src={source}
        onLoad={(src, hasCache) => {
          setUpMap();
          setLoaded(true);
        }}
      /> */}
      <div className='map-tooltip'>
        {tooltip && tooltip?.name && <strong>{tooltip?.name}</strong>}
        {tooltip && tooltip?.places > 0 && <p>Lugares: {tooltip?.places}</p>}
      </div>
    </>
  );
};

export default InteractiveMap;

import React from 'react';
import { Box, Grid } from '@mui/material';
import { CardOptionsBackground, CustomizedButton, CustomizedTypography } from '../../../componets';

import { colors } from '../../../values/colors';
import { icMain } from '../../../assets';
import { useAuth } from '../../../contexts/AuthContext';
import Search from '../../../componets/SearchComponent';
import { AddCircle } from '@mui/icons-material';
import {
  BodyCard,
  CardRootCustom,
  CardSectionColumn,
  DividerCustom,
  HeaderCard
} from '../../../componets/CardCustom';
import { getIcon } from '../../../utils/fuctionsGeneral';
import { SubtitleTitleEvent, TitleEvent } from '../DetailsEventScreen';
import './styles.css';

const SectionDetailsSales = ({
  titleLeft,
  titleRight,
  styleLeft,
  styleRight,
  colorRight,
  colorLeft
}) => {
  const stylesGeneralLeft = { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' };
  const stylesGeneralRight = { display: 'flex', justifyContent: 'center', alignItems: 'center' };

  return (
    <CardSectionColumn>
      <Grid item xs={6} sx={styleLeft ? styleLeft : stylesGeneralLeft}>
        <SubtitleTitleEvent color={colorLeft} subtitle={titleLeft} />
      </Grid>
      <Grid item xs={6} sx={styleRight ? styleRight : stylesGeneralRight}>
        <SubtitleTitleEvent color={colorRight} subtitle={titleRight} />
      </Grid>
    </CardSectionColumn>
  );
};

export const SectionTitleProfile = ({ title, subtitle, fontWeight, fontWeightSubtitle }) => {
  return (
    <Box sx={{ marginTop: 3 }}>
      <CustomizedTypography
        colors={colors.black}
        size={'0.8rem'}
        fontWeight={fontWeight ? fontWeight : 800}
        variant={'h2'}>
        {title}
      </CustomizedTypography>
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}>
        <CustomizedTypography
          mt={0.6}
          colors={colors.black}
          size={'1.3rem'}
          fontWeight={fontWeightSubtitle ? fontWeightSubtitle : 800}
          variant={'h2'}>
          {subtitle}
        </CustomizedTypography>
      </Grid>
    </Box>
  );
};

const Boletera = () => {

  const { user } = useAuth();

  const actionForm = () => {
    console.log('actionForm');
  };

  return (
    <CardOptionsBackground idPage={2}>
      <Grid container item xs={12} sm={12} md={12} className='containerEventRoot'>
        <Grid item xs={3}>
          <img src={icMain} width={'50%'} />
          <SectionTitleProfile title={'Nombre'} subtitle={user.name} />
          <Grid item xs={9} sm={9} mt={3}>
            <Search
              hideLabel={true}
              actionFormLogin={(values, resetForm) => actionForm(values, resetForm)}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <CardRootCustom>
            <HeaderCard>
              <Grid container p={2} item xs={12}>
                <Grid item xs={6}>
                  <CustomizedTypography size={'1.1rem'} fontWeight={800}>
                    Graduación IBERO
                  </CustomizedTypography>
                  <CustomizedTypography size={'0.8rem'} fontWeight={400}>
                    Código: adfaaeq
                  </CustomizedTypography>
                  <CustomizedTypography size={'1.1rem'} fontWeight={400}>
                    30 noviembre
                  </CustomizedTypography>
                </Grid>
                <Grid item xs={6} className='containerHeader'>
                  <img src={getIcon(1)} width={'35%'} height={'45%'} />
                  <CustomizedTypography
                    mt={2}
                    colors={colors.black}
                    size={'0.8rem'}
                    fontWeight={800}>
                    Liberación de mesa xx/xxx/xxxx
                  </CustomizedTypography>
                </Grid>
              </Grid>
            </HeaderCard>
            <BodyCard bgColor={colors.bgColorGray}>
              <Box>
                <Grid item mt={1} mb={2}>
                  <CardRootCustom bgColor={colors.white}>
                    <BodyCard bgColor={colors.white}>
                      <CardSectionColumn>
                        <Grid item xs={6} sx={{ background: 'transparent' }}>
                          <CardSectionColumn>
                            <Grid item xs={6}>
                              <SubtitleTitleEvent
                                fontSize={'1rem'}
                                color={colors.black}
                                subtitle={'Boletos'}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <SubtitleTitleEvent
                                fontSize={'1rem'}
                                color={colors.black}
                                subtitle={'10'}
                              />
                            </Grid>
                          </CardSectionColumn>
                          <CardSectionColumn>
                            <Grid item xs={6}>
                              <SubtitleTitleEvent
                                fontSize={'1rem'}
                                color={colors.black}
                                subtitle={'Mesas'}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <SubtitleTitleEvent
                                fontSize={'1rem'}
                                color={colors.black}
                                subtitle={'1'}
                              />
                            </Grid>
                          </CardSectionColumn>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: 'flex',
                            background: 'transparent',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          <CustomizedButton
                            bgcolor={colors.yellowBlack}
                            width={'13rem'}
                            maxheight={'3rem'}
                            maxwidth={'13rem'}
                            colortext={colors.white}
                            bgcolorinactive={colors.grayInactive}
                            variant={'contained'}>
                            Gestionar invitados
                          </CustomizedButton>
                        </Grid>
                      </CardSectionColumn>
                    </BodyCard>
                  </CardRootCustom>
                </Grid>
                <TitleEvent title={'Detalle de la compara'} />
                <SectionDetailsSales
                  titleLeft={'10 boletos para cena'}
                  titleRight={'$ 20,000.00'}
                />
                <SectionDetailsSales titleLeft={'2 boletos para after'} titleRight={'$ 2,550.00'} />
                <SectionDetailsSales titleLeft={'Subtotal:'} titleRight={'$ 20,050.00'} />
                <DividerCustom />
                <SectionDetailsSales
                  titleLeft={'Monto total de la orden'}
                  titleRight={'$ 20,050.00'}
                />
                <SectionDetailsSales
                  titleLeft={'Pagado'}
                  styleLeft={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  styleRight={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  titleRight={'$ 14,150.00'}
                  colorRight={colors.green}
                />
                <SectionDetailsSales
                  titleLeft={'Resta a pagar'}
                  styleLeft={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  styleRight={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  titleRight={'$  6,050.00'}
                  colorRight={colors.redLight}
                />
                <DividerCustom />
                <CardSectionColumn>
                  <Grid item xs={6}>
                    <TitleEvent title={'Pagado'} />
                  </Grid>
                  <Grid item xs={6}>
                    <SubtitleTitleEvent
                      fontSize={'2rem'}
                      color={colors.redLight}
                      subtitle={'$ 14,150.00'}
                    />
                  </Grid>
                </CardSectionColumn>
                <CardSectionColumn>
                  <Grid
                    item
                    mt={2}
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <CustomizedButton
                      bgcolor={colors.redLight}
                      width={'15rem'}
                      maxheight={'3rem'}
                      maxwidth={'15rem'}
                      colortext={colors.white}
                      bgcolorinactive={colors.grayInactive}
                      variant={'contained'}>
                      Gestionar pagos
                    </CustomizedButton>
                  </Grid>
                </CardSectionColumn>
              </Box>
            </BodyCard>
          </CardRootCustom>
        </Grid>
        <Grid container item xs={4} className='containerAddEvent'>
          <Grid item xs={12} className='containerAddEvent'>
            <AddCircle
              color={'primary'}
              sx={{
                fontSize: '5rem',
                color: colors.grayBlackText
              }}
            />
          </Grid>
          <Grid item xs={12} className='containerAddEvent'>
            <CustomizedTypography
              colors={colors.black}
              size={'0.8rem'}
              fontWeight={800}
              variant={'h2'}>
              Agregar evento
            </CustomizedTypography>
          </Grid>
        </Grid>
      </Grid>
    </CardOptionsBackground>
  );
};

export default Boletera;

import { GET_AUTH } from '../conf';
import paths from './path';

export const GET_ORDER_BY_FILTERS_API = async (id, page, rowPage, onSuccess, onError, onDone) => {
  let url = `${paths.userOrder}${id}${paths.orders}`;
  if (page && rowPage) {
    url = `${url}?p=${page}&page_size=${rowPage}`;
  }
  await GET_AUTH(url, onSuccess, onError, onDone);
};

export const GET_ORDERS_BY_ID_API = async (id, onSuccess, onError, onDone) => {
  await GET_AUTH(`${paths.order}${id}`, onSuccess, onError, onDone);
};

export const GET_ORDER_TRANSACTIONS = async (id, onSuccess, onError, onDone) => {
  await GET_AUTH(`${paths.order}${id}/${paths.transactions}`, onSuccess, onError, onDone);
};

import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik, Field } from 'formik';
import validations from './validations';
import initialValues from './initialValues';
import s from '../../../../strings/index';
import { IconButton, Box, InputAdornment, Select, MenuItem } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import {
  CustomizedTextField,
  CustomizedTypography,
  CustomizedFormHelperText,
  CustomizedLoadingButton,
  SimpleLoadingButton,
  CustomizedSelect,
  AlertCustom
} from '../../../../componets';
import { colors } from '../../../../values/colors';
import { Grid, FormControl, Checkbox } from '@mui/material';
import { pink } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import CardSendEmail from '../CardSendEmail';
import k from '../../../../values/key';
import { CheckBoxComponent, FieldPasswordCustom } from './componetsForm';
import './styles.css';

const FormSingupUser = ({
  isEmailSend,
  email,
  actionForm,
  errorMessage,
  showAlert,
  isLoading,
  listCodeCountry
}) => {
  /**variable iniciales**/
  const navigate = useNavigate();
  const signupForm = useRef();
  /**hoks**/
  const [checkSelectedTerm, setCheckSelectedTerms] = useState(false);
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [showHideConfirmPassword, setShowConfirmHidePassword] = useState(false);
  const [country, setCountry] = useState('');

  useEffect(() => {
    if (listCodeCountry && listCodeCountry.length > 0) {
      listCodeCountry.forEach((code) => {
        if (code.iso2 === 'MX') {
          setCountry(code.id);
          signupForm?.current?.setFieldValue('country', code.id);
        }
      });
    }
  }, []);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const goToTermsAndConditions = () => {
    navigate(k.navigation.terms);
  };

  const goToPrivacy = () => {
    navigate(k.navigation.privacy);
  };

  const handleChangeSelect = (event, setFieldValue) => {
    event.preventDefault();
    setFieldValue('country', event.target.value);
    setCountry(event.target.value);
  };

  return (
    <>
      {isEmailSend ? (
        <CardSendEmail email={email} />
      ) : (
        <>
          {showAlert && <AlertCustom errorMessage={errorMessage} />}
          <Formik
            innerRef={signupForm}
            validationSchema={validations}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              actionForm(values, resetForm);
            }}>
            {({ errors, touched, isValid, setFieldValue, handleSubmit }) => (
              <Form className='content'>
                <CustomizedTypography size={'1.7rem'} variant={'h2'} component={'div'}>
                  Regístrate
                </CustomizedTypography>
                <Grid container spacing={2} className='containerRootFormSignup'>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name={'first_name'}
                      as={CustomizedTextField}
                      variant={'filled'}
                      margin={'normal'}
                      fullWidth
                      id={'first_name'}
                      label={s.name}
                      autoComplete={'first_name'}
                    />
                    {touched.first_name && errors.first_name && (
                      <CustomizedFormHelperText>{errors.first_name}</CustomizedFormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name={'last_name'}
                      as={CustomizedTextField}
                      variant={'filled'}
                      margin={'normal'}
                      fullWidth
                      id={'last_name'}
                      label={s.lastName}
                      autoComplete={'last_name'}
                    />
                    {touched.last_name && errors.last_name && (
                      <CustomizedFormHelperText>{errors.last_name}</CustomizedFormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name={'email'}
                      as={CustomizedTextField}
                      variant={'filled'}
                      margin={'normal'}
                      fullWidth
                      id={'email'}
                      label={s.email}
                      autoComplete={'email'}
                    />
                    {touched.email && errors.email && (
                      <CustomizedFormHelperText>{errors.email}</CustomizedFormHelperText>
                    )}
                  </Grid>
                  <Grid container item xs={12} sm={6}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant={'standard'}>
                        <Select
                          fullWidth
                          id={'country'}
                          input={<CustomizedSelect placeholder={'Código de pais'} />}
                          label={'Código de pais'}
                          labelId={'demo-customized-select-label'}
                          name={'country'}
                          onChange={(e) => handleChangeSelect(e, setFieldValue)}
                          value={country}
                        >
                          {listCodeCountry.map((country) => {
                            // eslint-disable-next-line react/jsx-key
                            return (
                              <MenuItem key={country.id} value={country.id}>
                                {country.nombre}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {touched.country && errors.country && (
                        <CustomizedFormHelperText>{errors.country}</CustomizedFormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Field
                        name={'phone'}
                        as={CustomizedTextField}
                        variant={'filled'}
                        margin={'normal'}
                        fullWidth
                        id={'phone'}
                        label={s.phone}
                        autoComplete={'phone'}
                      />
                      {touched.phone && errors.phone && (
                        <CustomizedFormHelperText>{errors.phone}</CustomizedFormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldPasswordCustom
                      showHidePassword={showHidePassword}
                      setShowHidePassword={setShowHidePassword}
                      handleMouseDownPassword={setShowHidePassword}
                      touched={touched}
                      errors={errors}
                      id={'password'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={CustomizedTextField}
                      autoComplete={'confirm_password'}
                      fullWidth
                      id={'confirm_password'}
                      label={s.confirmPassword}
                      margin={'normal'}
                      name={'confirm_password'}
                      type={showHideConfirmPassword ? 'text' : 'password'}
                      variant={'filled'}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position={'end'}>
                            <IconButton
                              color={'primary'}
                              aria-label={'toggle password visibility'}
                              onClick={() => setShowConfirmHidePassword(!showHideConfirmPassword)}
                              onMouseDown={handleMouseDownPassword}
                              edge={'end'}>
                              {showHideConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {touched.confirm_password && errors.confirm_password && (
                      <CustomizedFormHelperText>{errors.confirm_password}</CustomizedFormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CheckBoxComponent
                      actionSubtitle={() => goToPrivacy()}
                      actionTitle={() => goToTermsAndConditions()}
                      checkSelectedTerm={checkSelectedTerm}
                      onlyTitle
                      setCheckSelectedTerms={setCheckSelectedTerms}
                      setFieldValue={setFieldValue}
                      textColor={colors.blackTicket}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTypography mt={3} size={'0.8rem'}>
                      {s.requiredField}
                    </CustomizedTypography>
                  </Grid>
                </Grid>
                <Grid>
                  <Checkbox
                    inputProps={ {'aria-label': 'Checkbox demo'} }
                    defaultChecked
                    sx={{
                      color: pink[800],
                      '&.Mui-checked': {
                        color: pink[600],
                      },
                    }}
                  />
                </Grid>
                <Grid container item spacing={2} className='containerButtonSingup'>
                  <Grid item xs={6} className='containerCancel'>
                    <CustomizedTypography
                      colors={colors.blackTicket}
                      fontWeight={700}
                      id={'cancel'}
                      size={'1.4rem'}
                      type={'button'}
                      variant={'h6'}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {s.cancel}
                    </CustomizedTypography>
                  </Grid>
                  <Grid item xs={6} className='buttonContainer'>
                    <SimpleLoadingButton
                      color="disabled" 
                      disabled={!isValid}
                      id={'singup'}
                      loading={isLoading}
                      type={'button'}
                      variant={'contained'}
                      widthbottom={'20rem'}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {s.singup}
                    </SimpleLoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default FormSingupUser;

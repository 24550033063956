import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import {
  AlertCustom,
  CheckBoxCircule,
  CustomizedButton,
  CustomizedCardContainer,
  Loader,
  SelectComponent,
  CardHeaderDetailEvent
} from '../../../componets';

import { colors } from '../../../values/colors';
import {
  BodyCard,
  CardRootCustom,
  CardSectionColumn,
  DividerCustom
} from '../../../componets/CardCustom';
import CardHeaderTitle from '../../../componets/CardHeaderTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubtitleTitleEvent, TitleEvent } from '../DetailsEventScreen';
//import { Link } from '@material-ui/core';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormAddGuest from './form';
import ListGuests from './ListGuests';
import { amountFormat } from '../../../utils/fuctionsGeneral';
import { getMessageError } from '../../../api/conf';
import {
  DELETE_GUEST_API,
  GET_GUEST_API,
  GET_PAYMENTS_ORDER,
  PAYMENT_EVENT_API,
  GET_ORDER_BY_FILTERS_API
} from '../../../api';
import k from '../../../values/key';
import { TYPE_ALERT_WARNING } from '../../../utils/utilsGeneral';
import { useAuth } from '../../../contexts/AuthContext';

import './styles.css';

const dataPartial = [
  { id: 0, name: 'Selecciona parcialidades' },
  { id: 1, amount: '100' },
  { id: 3, amount: '200' },
  { id: 2, amount: '300' },
  { id: 4, amount: '400' }
];

const SectionListPayment = ({ title, subtitle, fontSize }) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <SubtitleTitleEvent fontSize={'1rem'} subtitle={title} />
      </Grid>
      <Grid item xs={6}>
        <SubtitleTitleEvent fontSize={fontSize ? fontSize : '1rem'} subtitle={subtitle} />
      </Grid>
    </Grid>
  );
};

const ShoppingCarScreen = () => {
  
  const params = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [checkSelectedPartial, setCheckSelectedPartial] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [checkSelected, setCheckSelected] = useState(false);
  const [event, setEvent] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [detailsPriceData, setDetailsPriceData] = useState(null);
  const [valuePartial, setValuePartial] = useState(dataPartial[0].name);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [typeAlert, setTypeAlert] = useState(TYPE_ALERT_WARNING);
  const [showFormAddGuest, setShowFormAddGuest] = useState(false);
  const [bgColorCard, setBgColorCard] = useState(null);
  const [textColorCard, setTextColorCar] = useState(null);
  const [listGuest, setListGuest] = useState([]);
  const [guest, setGuest] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [listDataUserPayments, setListDataUserPayments] = useState([
    {
      id: 1,
      nameGuest: 'Juan Carlos Gomez',
      tickets: 2,
      tables: 1,
      comisions: '50.00',
      total: '4,050.00'
    }
  ]);
  const [listTransactions, setListTransactions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [rowPage, setRowPage] = useState(8);
  // eslint-disable-next-line no-unused-vars
  const [listDataTicket, setListDataTicket] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dataService, setDataService] = useState([]);

  useEffect(() => {
    setupOrder().then((r) => console.log('setupOrder =>', r));
  }, []);

  const setupOrder = async () => {
    setIsLoader(true);
    const eventParasm = params?.state?.event;
    const detailsPriceParams = params?.state?.detailsPriceData;
    console.log('eventParasm =>', eventParasm);
    if (eventParasm) {
      setEvent(eventParasm);
      setDetailsPriceData(detailsPriceParams);
      if (eventParasm?.category_data) {
        const { category_data } = eventParasm;
        const bgColor = category_data.bg_color ? category_data.bg_color : colors.redLight;
        setBgColorCard(bgColor);
        const textColor = category_data.tx_color ? category_data.tx_color : color;
        setTextColorCar(textColor);
      }
      await getTransactionById();
    } else {
      await getListOrders(page, rowPage);
    }
    await getListGuest();
  };

  const getListOrders = async (page, rowPage) => {
    await GET_ORDER_BY_FILTERS_API(
      user?.user_id,
      page,
      rowPage,
      onSuccessGetListOrders,
      onErrorGetListOrders,
      onDoneGetOrderById
    );
  };

  const onSuccessGetListOrders = (response) => {
    const { data, success } = response.data;
    console.log('data' + JSON.stringify(data));
    console.log('response.data' + JSON.stringify(response.data));
    console.log('response.data' + JSON.stringify(data[0].number));
    if (success) {
      setListDataTicket(data);
      setDataService(data[0]);
      setDetailsPriceData(data[0]);
      setEvent(data[0]);
      getTransactionById(data[0].number);
      //setDetailsPriceData(detailsPriceParams);
      /**if (eventParasm?.category_data) {
        const { category_data } = eventParasm;
        const bgColor = category_data.bg_color ? category_data.bg_color : colors.redLight;
        setBgColorCard(bgColor);
        const textColor = category_data.tx_color ? category_data.tx_color : color;
        setTextColorCar(textColor);
      }**/
    }
  };

  const onErrorGetListOrders = (error) => {
    setIsLoader(false);
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      showError(message || getMessageError(error));
    } else {
      showError(getMessageError(error));
    }
  };

  const getTransactionById = async (orderNumber) => {
    const orderNumberValue = orderNumber ? orderNumber : params?.state?.dataSales?.number;
    console.log('orderNumber' + orderNumber);
    setIsLoader(true);
    if (orderNumberValue) {
      await GET_PAYMENTS_ORDER(
        orderNumberValue,
        onSuccessGetTransactions,
        onErrorAddTransactions,
        onDoneGetOrderById
      );
    } else {
      setIsLoader(false);
    }
  };

  const onSuccessGetTransactions = (response) => {
    const { data, success } = response.data;
    console.log('data onSuccessGetTransactions onSuccessGetTransactions ' + success);
    if (success) {
      console.log('data onSuccessGetTransactions onSuccessGetTransactions ' + JSON.stringify(data));
      setListTransactions(data);
    }
    setIsLoader(false);
  };

  const onErrorAddTransactions = (error) => {
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      showError(message || getMessageError(error));
    } else {
      showError(getMessageError(error));
    }
    setIsLoader(false);
  };

  const onDoneGetOrderById = () => {
    setIsLoader(false);
  };

  const handleChangeSelect = (value) => {
    setValuePartial(value.target.value);
  };

  const getListGuest = async () => {
    setIsLoader(true);
    await GET_GUEST_API(onSuccessGetListUser, onErrorGetListGuest, onDoneGuest);
  };

  const onSuccessGetListUser = (response) => {
    const {
      data: { success, data }
    } = response;
    if (success) {
      console.log('success' + success);
      setListGuest(data);
      setIsLoader(false);
    }
  };

  const onErrorGetListGuest = (error) => {
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      showError(message || getMessageError(error));
    } else {
      showError(getMessageError(error));
    }
  };

  const onDoneGuest = () => {
    console.log('onDoneAddGuestUser');
    setIsLoader(false);
  };

  const showError = (message) => {
    setErrorMessage(message);
    setShowAlert(true);
    setIsLoader(false);
  };

  const paymentOrder = async () => {
    //setLoadingButton(true);
    if (checkSelectedPartial) {
      //Pago por parcialidades
      console.log('pago por parcialidades' + valuePartial);
      await getLinkPaymentByAmount(valuePartial);
    } else {
      await getLinkPaymentByAmount(0);
    }
  };

  const getLinkPaymentByAmount = async (amount) => {
    const eventParams = params?.state?.event;
    console.log('eventParam: ' + eventParams);
    console.log('eventParam: ' + eventParams.code);
    console.log('eventParam: ' + JSON.stringify(eventParams.tickets));
    const detailsPriceParams = params?.state?.detailsPriceData;
    const { dinnerValues, afterValues } = detailsPriceParams;
    const { ticketDinner } = dinnerValues;
    const { ticketAfter } = afterValues;

    const types = [];
    const quantities = [];

    if (ticketDinner) {
      types.push(eventParams.tickets[0]?.id);
      quantities.push(ticketDinner);
    }
    if (ticketAfter) {
      types.push(eventParams.tickets[1]?.id);
      quantities.push(ticketAfter);
    }
    const data = { types, quantities, partial_amount: amount };
    console.log('data PAYMENT' + JSON.stringify(data));
    await PAYMENT_EVENT_API(
      eventParams?.code,
      data,
      onSuccessGetLinkPaymentByAmount,
      onErrorGetLinkPaymentByAmount,
      onDoneGetLinkPaymentByAmount
    );
  };

  const onSuccessGetLinkPaymentByAmount = (response) => {
    console.log('response onSuccessGetLinkPaymentByAmount' + response);
    const { data, success } = response.data;
    //setLoadingButton(false);
    setIsLoader(false);
    const eventParams = params?.state?.event;
    if (success) {
      navigate(k.navigation.paymentOrder, {
        state: {
          isScreenMain: true,
          dataSales: data,
          event: eventParams
        }
      });
    }
  };

  const onErrorGetLinkPaymentByAmount = (error) => {
    setIsLoader(false);
    ///setLoadingButton(false);
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      showError(message || getMessageError(error));
    } else {
      showError(getMessageError(error));
    }
  };

  const onDoneGetLinkPaymentByAmount = () => {
    setIsLoader(false);
    //setLoadingButton(false);
  };

  const editGuest = (guest) => {
    console.log('editGuest' + JSON.stringify(guest));
    setGuest(guest);
    setShowFormAddGuest(true);
  };

  const deleteGuest = async (guest) => {
    console.log('editGuest' + JSON.stringify(guest));
    console.log('deleteGuest');
    await DELETE_GUEST_API(guest.id, onSuccessDeleteGuest, onErrorDeleteGuest, onDoneDeleteGuest);
  };

  const onSuccessDeleteGuest = (response) => {
    console.log('response' + JSON.stringify(response));
    setErrorMessage('Invitado elimiando');
    getListGuest();
  };

  const onErrorDeleteGuest = (error) => {
    if (error && error.response && error.response.data) {
      const {
        response: {
          data: { message }
        }
      } = error;
      showError(message || getMessageError(error));
    } else {
      showError(getMessageError(error));
    }
  };

  const onDoneDeleteGuest = () => {
    setIsLoader(false);
  };

  if (event && detailsPriceData) {
    const { dinnerValues, processAmount, amountTotalWhiOutProcess, amountTotal } = detailsPriceData;
    const total = amountTotal ? amountTotal : detailsPriceData?.total;
    const name = dinnerValues ? dinnerValues?.nameTicketDinner : detailsPriceData?.resume;
    const comission = processAmount ? processAmount : detailsPriceData?.taxes;
    const subTotal = amountTotalWhiOutProcess
      ? amountTotalWhiOutProcess
      : detailsPriceData?.sub_total;

    return (
      <Box className="containerRootShopping">
        <Grid container item xs={12} className="titleHeaderContainer">
          <CustomizedCardContainer
            padding={isLoader ? '10rem' : '2rem'}
            width={'95%'}
            isbgbackgroundblack={'true'}>
            {showAlert && <AlertCustom typeAlert={typeAlert} errorMessage={errorMessage} />}
            <>
              {isLoader ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center'
                  }}>
                  <Loader />
                </Grid>
              ) : (
                <>
                  {/**HEADER**/}
                  <CardHeaderTitle title={'Carrito'} />
                  {/**BODY**/}
                  <Grid mt={2} container item xs={12} sm={12} md={12}>
                    {/**CARD  EEVNTO**/}
                    <Grid item xs={4} sm={4} md={4}>
                      <CardRootCustom>
                        {event && <CardHeaderDetailEvent data={event} />}
                        <BodyCard bgColor={colors.white}>
                          <TitleEvent title={'Detalles de compra'} />
                          <>
                            <CardSectionColumn>
                              {name && (
                                <Grid item xs={6} className="containerRowShopping">
                                  <SubtitleTitleEvent
                                    subtitle={name || dinnerValues?.nameTicketDinner}
                                  />
                                </Grid>
                              )}
                              {dinnerValues && dinnerValues?.amountTotalDinnerCalculate && (
                                <Grid item xs={6} className="containerRowRight">
                                  <SubtitleTitleEvent
                                    subtitle={amountFormat(
                                      dinnerValues?.amountTotalDinnerCalculate
                                    )}
                                  />
                                </Grid>
                              )}
                            </CardSectionColumn>
                          </>
                          <CardSectionColumn>
                            <Grid item xs={6} mt={3} className="containerRowShopping">
                              <SubtitleTitleEvent subtitle={'Comisión:'} />
                            </Grid>
                            {comission && (
                              <Grid item xs={6} mt={3} className="containerRowRight">
                                <SubtitleTitleEvent subtitle={amountFormat(comission)} />
                              </Grid>
                            )}
                          </CardSectionColumn>
                          <CardSectionColumn>
                            <Grid item xs={6} className="containerRowShopping">
                              <SubtitleTitleEvent subtitle={'Subtotal:'} />
                            </Grid>
                            {subTotal && (
                              <Grid item xs={6} className="containerRowRight">
                                <SubtitleTitleEvent subtitle={amountFormat(subTotal)} />
                              </Grid>
                            )}
                          </CardSectionColumn>
                          <DividerCustom />
                          <CardSectionColumn>
                            <Grid item xs={6} className="containerRowShopping">
                              <SubtitleTitleEvent
                                fontWeight={900}
                                subtitle={'Monto total de la orden'}
                              />
                            </Grid>
                            {total && (
                              <Grid item xs={6} className="containerRowRight">
                                <SubtitleTitleEvent
                                  fontSize={'2rem'}
                                  subtitle={amountFormat(total)}
                                />
                              </Grid>
                            )}
                          </CardSectionColumn>
                          {/**METODO DE PAGO
                          <CardSectionColumn>
                            <Grid item xs={6} className="containerRowShopping">
                              <SubtitleTitleEvent
                                fontSize={'0.8rem'}
                                subtitle={'Selecciona método de pago'}
                              />
                            </Grid>
                            <Grid item xs={6} className="containerRowRight">
                              <SubtitleTitleEvent subtitle={'Débito *****3452'} />
                            </Grid>
                          </CardSectionColumn>
                           **/}
                          {/**
                             <CardSectionColumn>
                             <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                             <Link className="link" underline={'always'} href={'#'}>
                             Seleccionar otro método de pago
                             </Link>
                             </Grid>
                             </CardSectionColumn>
                             **/}
                          <DividerCustom />
                          <TitleEvent title={'Pago'} />
                          <CardSectionColumn>
                            <Grid item xs={6} className="containerRowShopping">
                              <SubtitleTitleEvent subtitle={'Total'} />
                            </Grid>
                            {total && (
                              <Grid item xs={6} className="containerRowRight">
                                <SubtitleTitleEvent
                                  fontSize={'2rem'}
                                  color={colors.redLight}
                                  subtitle={amountFormat(total)}
                                />
                              </Grid>
                            )}
                          </CardSectionColumn>
                          <CardSectionColumn>
                            <Grid item xs={6} className="containerPartial">
                              <SubtitleTitleEvent subtitle={'Pagar boletos por parcialidades'} />
                            </Grid>
                            <Grid item xs={6} className="containerRowRight">
                              <CheckBoxCircule
                                checkSelected={checkSelectedPartial}
                                setCheckSelected={setCheckSelectedPartial}
                                color={colors.black}
                                actionCheck={() => setCheckSelectedPartial(!checkSelectedPartial)}
                                isCheckdefault={true}
                              />
                              <SelectComponent
                                widthSelect={'11rem'}
                                colorValue={
                                  !checkSelectedPartial ? colors.grayInactive : colors.black
                                }
                                data={dataPartial}
                                isRow={true}
                                valor={valuePartial}
                                handleChangeSelect={handleChangeSelect}
                              />
                            </Grid>
                          </CardSectionColumn>
                          <CardSectionColumn>
                            <Grid item xs={4} className="containerRowShopping" />
                            <Grid item xs={8} className="containerRowRight">
                              <Grid item mt={4} xs={12} className="containerButtonShopping">
                                <CustomizedButton
                                  bgcolor={colors.white}
                                  colortext={colors.black}
                                  maxwidth={'40rem'}
                                  onClick={() => {
                                    navigate(-1);
                                  }}
                                  bgcolorinactive={colors.transparent}
                                  colorinactive={colors.grayInactive}
                                  variant={'contained'}>
                                  Cancelar
                                </CustomizedButton>
                                <CustomizedButton
                                  onClick={() => paymentOrder()}
                                  bgcolor={bgColorCard}
                                  maxwidth={'50rem'}
                                  colortext={textColorCard}
                                  bgcolorinactive={colors.grayInactive}
                                  variant={'contained'}>
                                  Pagar
                                </CustomizedButton>
                              </Grid>
                            </Grid>
                          </CardSectionColumn>
                          <CardSectionColumn>
                            <Grid item xs={12} mt={1} className="labelCard">
                              <SubtitleTitleEvent
                                fontSize={'0.63rem'}
                                subtitle={
                                  'Las mesas están sujetas a disponibilidad, procura realizar tu pago lo antes posible.'
                                }
                              />
                            </Grid>
                          </CardSectionColumn>
                        </BodyCard>
                      </CardRootCustom>
                    </Grid>
                    {/**CARD INVITADOS**/}
                    <Grid container item p={3} xs={8} sm={8} md={8}>
                      <Box sx={{ maxWidth: '100%', width: '100%' }}>
                        {/**PRIMERA SESSCION**/}
                        <Grid container item xs={12}>
                          <Grid item xs={4}>
                            <TitleEvent title={'Invitados'} />
                          </Grid>
                          <Grid item xs={3.5} className="addGuest">
                            <div
                              className="addGuest"
                              onClick={() => {
                                setGuest(null);
                                setShowFormAddGuest(!showFormAddGuest);
                              }}>
                              <AddCircleOutlineIcon className="iconAddGuest" />
                              <SubtitleTitleEvent subtitle={'Agregar invitado.'} />
                            </div>
                          </Grid>
                          <Grid item xs={4.5}>
                            <SubtitleTitleEvent
                              subtitle={
                                'Al enviar liga de pago a tus invitados divides el total de tu cuenta.'
                              }
                            />
                          </Grid>
                          {
                            /**FORMULARIO DE INVITADOS*/
                            showFormAddGuest ? (
                              <Box className="containerAdd">
                                <CardRootCustom>
                                  <BodyCard bgColor={colors.bgColorGray}>
                                    <FormAddGuest
                                      guest={guest}
                                      setGuest={setGuest}
                                      showFormAddGuest={showFormAddGuest}
                                      setShowFormAddGuest={setShowFormAddGuest}
                                      setErrorMessage={setErrorMessage}
                                      setTypeAlert={setTypeAlert}
                                      setShowAlert={setShowAlert}
                                      bgColorCard={bgColorCard}
                                      textColorCard={textColorCard}
                                      getListGuest={getListGuest}
                                    />
                                  </BodyCard>
                                </CardRootCustom>
                              </Box>
                            ) : (
                              <>
                                {
                                  /**LISTADO DE INVITADOS**/
                                  listGuest && listGuest.length > 0 ? (
                                    <Box className="containerBox">
                                      <ListGuests
                                        editGuest={editGuest}
                                        deleteGuest={deleteGuest}
                                        listGuest={listGuest}
                                        setListGuest={setListGuest}
                                        setCheckSelected={setCheckSelected}
                                      />
                                    </Box>
                                  ) : (
                                    <Box className="containerGuest">
                                      <SubtitleTitleEvent subtitle={'Sin invitados.'} />
                                    </Box>
                                  )
                                }
                              </>
                            )
                          }
                        </Grid>
                        <DividerCustom />
                        <Grid item xs={12}>
                          {listDataUserPayments && listDataUserPayments.length > 0 ? (
                            <Box>
                              <Grid container>
                                <Grid item xs={5}>
                                  {listDataUserPayments.map((data) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <Box key={data.id} mt={2}>
                                      <TitleEvent title={data.nameGuest} />
                                      <SectionListPayment
                                        title={'Boletos'}
                                        subtitle={data.tickets}
                                      />
                                      <SectionListPayment title={'Mesas'} subtitle={data.tables} />
                                      <SectionListPayment
                                        title={'Comision'}
                                        subtitle={data.comisions}
                                      />
                                      <SectionListPayment
                                        title={'Total'}
                                        fontSize={'1.4rem'}
                                        subtitle={'$ ' + data.total}
                                      />
                                    </Box>
                                  ))}
                                </Grid>
                                <Grid item xs={7}>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    className="containerTransaction">
                                    <SubtitleTitleEvent
                                      subtitle={'Tu pago se encuentra completo'}
                                    />
                                  </Grid>
                                  {listTransactions && listTransactions.length > 0 && (
                                    <>
                                      {listTransactions.map((data) => (
                                        // eslint-disable-next-line react/jsx-key
                                        <Grid
                                          key={data.id}
                                          mt={2}
                                          container
                                          item
                                          xs={12}
                                          className="containerListTransaction">
                                          <Grid item xs={6}>
                                            <TitleEvent size={'0.8rem'} title={data.reference} />
                                            <SubtitleTitleEvent subtitle={data.updated_at} />
                                          </Grid>
                                          <Grid item xs={3}>
                                            <SubtitleTitleEvent subtitle={data.amount} />
                                          </Grid>
                                          <Grid item xs={3}>
                                            <SubtitleTitleEvent
                                              color={colors.green}
                                              subtitle={data.status}
                                            />
                                          </Grid>
                                        </Grid>
                                      ))}
                                    </>
                                  )}
                                  <Grid
                                    mt={3}
                                    container
                                    item
                                    xs={12}
                                    className="containerTextPrice">
                                    <SubtitleTitleEvent subtitle={'Pagado'} />
                                    <SubtitleTitleEvent
                                      sx={{ ml: 0.5, mr: 0.5 }}
                                      color={colors.green}
                                      fontSize={'1.2rem'}
                                      subtitle={'$14,150.00'}
                                    />
                                    <SubtitleTitleEvent subtitle={'de'} sx={{ mr: 0.5 }} />
                                    <SubtitleTitleEvent
                                      color={colors.black}
                                      fontSize={'1.2rem'}
                                      subtitle={'$  20,150.00'}
                                    />
                                    <SubtitleTitleEvent
                                      subtitle={'Resta a pagar'}
                                      sx={{ ml: 0.5, mr: 0.5 }}
                                    />
                                    <SubtitleTitleEvent
                                      sx={{ mr: 1 }}
                                      color={colors.redLight}
                                      fontSize={'1.2rem'}
                                      subtitle={'$  6,050.00'}
                                    />
                                  </Grid>
                                  <Grid
                                    mt={2}
                                    item
                                    xs={12}
                                    className="containerButtonDetails">
                                    <CustomizedButton
                                      bgcolor={colors.redLight}
                                      maxwidth={'16rem'}
                                      colortext={colors.white}
                                      bgcolorinactive={colors.grayInactive}
                                      variant={'contained'}>
                                      Ver detalles de pagos
                                    </CustomizedButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          ) : (
                            <Box>
                              <Grid item xs={12}>
                                <TitleEvent title={'Pago'} />
                              </Grid>
                              <Box className="containerPayment">
                                <Grid container item xs={12} className="containerColumn">
                                  <Box>
                                    {listDataUserPayments.length > 0 && (
                                      <>
                                        <Grid item xs={12} className="containerColumn">
                                          <SubtitleTitleEvent
                                            fontSize={'1.5rem'}
                                            color={colors.green}
                                            subtitle={'Pagado'}
                                          />
                                          <SubtitleTitleEvent
                                            sx={{ ml: 2 }}
                                            fontSize={'1.5rem'}
                                            subtitle={'$0.00'}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          mt={3}
                                          className="containerColumn">
                                          <SubtitleTitleEvent
                                            fontSize={'1.5rem'}
                                            subtitle={'Faltante'}
                                          />
                                          <SubtitleTitleEvent
                                            sx={{ ml: 2 }}
                                            color={colors.redLight}
                                            fontSize={'1.5rem'}
                                            subtitle={'$0.00'}
                                          />
                                        </Grid>
                                        <DividerCustom />
                                      </>
                                    )}
                                    <Grid item xs={12} className="containerColumn">
                                      <SubtitleTitleEvent
                                        fontSize={'1rem'}
                                        subtitle={'Total a pagar.'}
                                      />
                                    </Grid>
                                    {total && (
                                      <Grid item xs={12} className="containerColumn">
                                        <SubtitleTitleEvent
                                          fontSize={'2rem'}
                                          color={colors.redLight}
                                          subtitle={amountFormat(total)}
                                        />
                                      </Grid>
                                    )}
                                  </Box>
                                </Grid>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          </CustomizedCardContainer>
        </Grid>
      </Box>
    );
  }
};

export default ShoppingCarScreen;

import { object, string, ref } from 'yup';
import s from '../../../../strings';
export const numberRegex = /^[0-9]+$/;
export const maxlengthPhone = 10;

export default object({
  email: string()
    .required(s.fieldRequired)
    .email(s.typeEmailRequired)
    .min(3, ({ min }) => `${s.maxLengthInputMin}${min} ${s.caracteres}`),
  password: string().required(s.fieldRequired),
  confirm_password: string()
    .required(s.fieldRequired)
    .typeError(s.fieldRequired)
    .oneOf([ref('password'), null], s.errorConfirmPassword),
  first_name: string().required(s.fieldRequired),
  last_name: string().required(s.fieldRequired),
  country: string().required(s.fieldRequired),
  phone: string()
    .required(s.fieldRequired)
    .matches(numberRegex, s.onlyNumbers)
    .min(maxlengthPhone, ({ min }) => `${s.maxLengthInputMin}${min} ${s.caracteres}`)
    .max(maxlengthPhone, ({ max }) => `${s.maxLengthInput}${max} ${s.caracteres}`),
  terminusConditions: string().required(s.fieldRequired).matches(true, s.fieldRequired)
});

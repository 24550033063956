export const colors = {
  red: '#E84A43',
  black: '#000000',
  yellow: '#FFC700',
  white: '#FFFFFF',
  greenLight: '#38E8DF',
  grayLight: '#F4F3F3',
  grayBlack: '#B7B7B7',
  blackRgb: 'rgba(0, 0, 0, 0.63)',
  redLight: '#D92118',
  blue: '#0464A4',
  yellowBlack: '#FF9900',
  purple: '#4A04A4',
  grayText: '#767676',
  grayBlackText: '#464646',
  blackText: '#595959',
  textDisabled: '#656565',
  grayInactive: '#A6A6A6',
  whiteGray: '#F5F5F5',
  transparent: '#ffffff00',
  blueLight: '#33A0FF',
  blackLight: '#262626',
  grayBlackLight: '#F6F7F8',
  grayList: '#6C6C6C',
  bgColorGray: '#D9D9D9',
  grayCount: '#e5e5e5',
  grayCountInactive: '#f1f1f1',
  green: '#56AC00',
  greenLightBg: '#D1FF96',
  grayTextTable: '#787878',

  blackTicket: '#32312D',
  gradientBG: 'linear-gradient(45deg, rgba(184, 135, 10, 1) 0%, rgba(255, 206, 82, 1) 50%, rgba(245, 183, 24, 1) 100%)',
};

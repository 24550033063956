import React from 'react';
import s from '../../strings';
import '../../screens/usersLogged/EventsScreen/styles.css';
import { styled } from '@mui/material/styles';
import { colors } from '../../values/colors';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { CustomizedTypography } from '../index';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: 50,
  backgroundColor: colors.grayLight,
  '&:hover': {
    backgroundColor: colors.grayLight
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const SearchComponent = ({ handleChange, hideLabel }) => {

  return (
    <div>
      <>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            onChange={handleChange}
            placeholder={'Buscar evento'}
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
        {!hideLabel && (
          <div className="textSearch">
            <CustomizedTypography mt={0.6} colors={colors.grayText} fontWeight={400} size={'1rem'}>
              {s.codeEvent}
            </CustomizedTypography>
          </div>
        )}
      </>
    </div>
  );
};

export default SearchComponent;

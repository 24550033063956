import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { colors } from '../../values/colors';

const CustomizedTypography = styled(Typography)`
  color: ${(props) => (props.colors ? `${props.colors}` : `${colors.white}`)};
  font-family: Inter;
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.size ? props.size : '1.8rem')};
  cursor: pointer;
`;

export default CustomizedTypography;

import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { TYPE_ALERT_WARNING } from '../utils/utilsGeneral';

const AlertCustom = ({ errorMessage, typeAlert }) => {
  return (
    <Stack sx={{ width: '100%' }} mb={2} spacing={2}>
      <Alert severity={typeAlert || TYPE_ALERT_WARNING}>{errorMessage}</Alert>
    </Stack>
  );
};

export default AlertCustom;

import React from 'react';
import { Checkbox } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { CustomizedTypography } from '../index';
import { colors } from '../../values/colors';
import './styles.css';

const CheckBoxCircule = ({
  title,
  subtitle,
  isCheckdefault,
  color,
  sizeTitleCheck,
  subtitleColors,
  value,
  name,
  actionCheck,
  fontSizeCheck,
  checked,
  actionTitle,
  sizeSubtitleCheck,
  actionSubtitle,
  disabled
}) => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <div className='containerCheckBox'>
      {!isCheckdefault ? (
        <Checkbox
          {...label}
          name={name}
          value={value}
          sx={{
            color: color ? color : colors.black,
            '&.Mui-checked': {
              color: color ? color : colors.blue
            },
            '& .MuiSvgIcon-root': { fontSize: fontSizeCheck ? fontSizeCheck : '1.3rem' }
          }}
          onClick={(value) => {
            actionCheck(value);
          }}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
        />
      ) : (
        <Checkbox
          checked={checked}
          disabled={disabled}
          sx={{
            color: color ? color : colors.black,
            '&.Mui-checked': {
              color: color ? color : colors.blue
            },
            '&.MuiSvgIcon-root': {
              fontSize: fontSizeCheck ? fontSizeCheck : '1.3rem'
            }
          }}
          onClick={(value) => {
            actionCheck(value);
          }}
          {...label}
        />
      )}
      {title && (
        <div>
          <CustomizedTypography
            onClick={actionTitle}
            colors={color ? color : colors.blackText}
            size={sizeTitleCheck ? sizeTitleCheck : '1rem'}
            fontWeight={400}
            level={'h2'}>
            {title}
          </CustomizedTypography>
          {subtitle && (
            <CustomizedTypography
              onClick={actionSubtitle}
              colors={subtitleColors ? subtitleColors : colors.textDisabled}
              level={'subtitle2'}
              size={sizeSubtitleCheck ? sizeSubtitleCheck : '0.8rem'}>
              {subtitle}
            </CustomizedTypography>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckBoxCircule;

import React, { useState } from 'react';
import { Typography, MenuItem, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import s from '../../strings';
import k from '../../values/key';
import { styled } from '@mui/material/styles';
import { colors } from '../../values/colors';

const RoutesNavLinkMenu = ({ pages, showButtons, handleCloseNavMenu }) => {
  const [selectedPage, setSelectedPage] = useState({
    id: 1,
    name: s.ticket,
    route: k.navigation.events
  });

  const goToNavigation = (page) => {
    console.log('page ==> ' + JSON.stringify(page));
    handleCloseNavMenu(page);
    setSelectedPage(page);
  };

  const CustomizedButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1.2rem',
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  });

  if (showButtons) {
    return (
      <>
        {pages.map((page) => (
          <Link className={'link'} key={page.id} to={page.route}>
            <CustomizedButton
              key={page.id}
              onClick={() => goToNavigation(page)}
              sx={{
                my: 2,
                mr: 1,
                color: selectedPage.id === page.id ? colors.redLight : colors.white,
                display: 'block',
                fontFamily: 'Inter',
                fontWeight: 700,
                fontSize: '1.4rem'
              }}>
              {page.name}
            </CustomizedButton>
          </Link>
        ))}
      </>
    );
  }
  return (
    <>
      {pages.map((page) => (
        <Link className={'link'} key={page.id} to={page.route}>
          <MenuItem key={page.id} onClick={() => goToNavigation(page.route)}>
            <Typography textAlign={'center'}>{page.name}</Typography>
          </MenuItem>
        </Link>
      ))}
    </>
  );
};

export default RoutesNavLinkMenu;

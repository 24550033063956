import { Grid } from '@material-ui/core';
import { icEmail } from '../../../assets';
import { CustomizedLoadingButton, CustomizedTypography } from '../../../componets';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const CardSendEmail = ({ email }) => {

  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item className='containerUserRemember'>
        <img src={icEmail} className='imgSendEmail' />
        <CustomizedTypography
          fontWeight={700}
          mt={5}
          size={'2rem'}
          variant={'h6'}
          component={'div'}>
          Correo enviado
        </CustomizedTypography>
        <CustomizedTypography
          fontWeight={400}
          mt={5}
          size={'1.6rem'}
          variant={'h6'}
          component={'div'}>
          Enviamos una liga para validar tu correo a
        </CustomizedTypography>
        {email && (
          <CustomizedTypography
            fontWeight={600}
            mt={5}
            size={'1.4rem'}
            variant={'h6'}
            component={'div'}>
            {email}
          </CustomizedTypography>
        )}
        <CustomizedTypography
          fontWeight={400}
          mt={5}
          mb={10}
          size={'1.5rem'}
          variant={'h6'}
          component={'div'}>
          Revisa tu correo y da clic en el enlace que te enviamos.
        </CustomizedTypography>
        <div className='margin'>
          <CustomizedLoadingButton
            mt={10}
            onClick={() => navigate(-1)}
            type={'submit'}
            widthbottom={'343px'}
            fullWidth
            variant={'contained'}>
            Salir
          </CustomizedLoadingButton>
        </div>
      </Grid>
    </Grid>
  );
};

export default CardSendEmail;


import { Box, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const INTENT_CAPTURE = "CAPTURE"

const CURRENCY_MXN = "MXN"

const QUALITY = "1"

const TEST_AMOUNT = 100

const APPROVE_STATUS = "approve"

const paths = {
	genToken: 'v1/oauth2/token',
	createOrders: 'v2/checkout/orders',
	captureOrders: '/capture',
}

/* 
* Prod
const config = {
	url: "https://api-m.paypal.com/",
	clientIDPayPal: "AfpwQMc1kI9YMKATG0BTo6ec8W1u7MN1ERGGemfIg9scHrkDGshniGG4kzF6K72ezOCtH-xSni1DpXMr",
	secretIDPayPal: "EOZGF6AUMybMIWxDJu22thZ6-OdGfD_fb0lz_9FAiGWF2mbkJvg4VdBaRDZL_fmDwceNxIXU8S4p71Tn",
} 
*/

const config = {
	url: "https://api-m.sandbox.paypal.com/",
	clientIDPayPal: "ARD4MaMTLNoIQGkvtdCXLeBuKlmn9f0DUxKEGsax5AzZRKXDGB0IROtCXHc9mJPNb9IfmtVN7RLvPb3C",
	secretIDPayPal: "EC24zxYaxpxxgkTyXLdw6wHEgiHQsa-XhnWOWMImFn8-Ci8NYDacS2lVtcAAxs3W2Mp9XP1vT0Tm976n",
}

const api = axios.create({ baseURL: config.url });
const api2 = axios.create({ baseURL: "http://127.0.0.1:8000/" });


const TestScreen = () => {

	const [tokenPaypal, setTokenPaypal] = useState(null);

	useEffect(() => {

		getToken();
	
	}, [])


	const getToken = () => {
		const data = 'grant_type=client_credentials'

		const username = config.clientIDPayPal;
		const password = config.secretIDPayPal;

		// const token = Buffer.from(`${username}:${password}`).toString('base64');
		const token = btoa(`${username}:${password}`);
		
		const authHeader = 'Basic ' + token;

		const headers = {
			'content-type': 'application/x-www-form-urlencoded',
			Authorization: authHeader,
		}

		POST(
			paths.genToken,
			data,
			headers,
			saveToken,
			console.log
		)
	}

	const saveToken = (response) => {
		console.log(response);
		const { data: access_token } = response;
		setTokenPaypal(access_token);
	}

	const createOrder = (data) => {
		const headers = {
			'content-type': 'application/json',
			Authorization: `Bearer ${tokenPaypal?.access_token}`,
		}
		POST(paths.createOrders, data, headers, saveOrder, console.log)
	}

	const saveOrder = (response) => {
		console.log(response);
		const { data: {links, id} } = response;
		const objLink = links.find(link => link.rel === APPROVE_STATUS);
		// Navegar
		const nav_data = {
			url: objLink.href,
			idOrder: id,
			access_token: tokenPaypal
		}
	}

	const POST = (part_url, data, headers, onSuccess, onError) => {
		console.log("<<<<<part_url", part_url);
		console.log("<<<<<data", data);
		console.log("<<<<<headers", headers);
		api.post(part_url, data, {
			headers: headers,
		})
		// .then(response => onSuccess(response))
		.then(onSuccess)
		.catch(onError);
		// .catch(error => onError(error));
	}
		

	const getDataCreateOrder = (listProduct, amountTotal, referenceId) => {
		const listProductItems = listProduct.map(item => ({
			name: item.product,
			description: item.concept,
			quantity: QUALITY,
			unit_amount: {
				currency_code: CURRENCY_MXN,
				value: item.amount
			}
		}));
		return {
			intent: INTENT_CAPTURE,
			purchase_units: [
				{
					reference_id: referenceId,
					items: listProductItems,
					amount: {
						currency_code: CURRENCY_MXN,
						value: amountTotal,
						breakdown: {
							item_total: {
								currency_code: CURRENCY_MXN,
								value: amountTotal
							},
						},
					},
				},
			],
			application_context: {
			
			return_url: 'https://apiportal.driveapp.mx/api/webhook/paypal/success/',
			cancel_url: 'https://apiportal.driveapp.mx/api/webhook/paypal/cancel/'
			},
		};
	};

	const handleNewOrder = event => {
		const data = getDataCreateOrder(
			[{ product: 'Compra de boletos', concept: '2 boletos de cena y 1 after, mesa 45', amount: TEST_AMOUNT }],
			TEST_AMOUNT,
			'ref001'
		)

		console.log('<<<<<data', data);

		createOrder(data);
	}

	const [image, setImage] = useState(null);

	const handleImageChange = (e) => {
		setImage(e.target.files[0]);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("image", image);

		// Agrega cualquier otro campo que necesites enviar junto con la imagen
		formData.append("title", "Nuevo título");
		formData.append("description", "Nueva descripción");
		console.log("formData", formData);

		api2.put("myadmin/events/A517AC4GHU/", {uno: 1, dos: 2}, {
			withCredentials: true,
			headers: {
				// "Content-Type": "multipart/form-data" // Importante establecer el tipo de contenido como 'multipart/form-data'
				Authorization: "Token 9cf816ec0b50d4cdfa4be7d160fcca6b493b74eb"
			}
		})
		.then((response) => {
			// Maneja la respuesta de la solicitud exitosa aquí
			console.log("Evento actualizado con éxito:", response.data);
		})
		.catch((error) => {
			// Maneja los errores aquí
			console.error("Error al actualizar el evento:", error);
			console.log("Error al actualizar el evento:", error);
		});
	};
	
	return (
		<Box>
			<Grid container>
				<Grid container item xs={6}>
					<h1>Testing</h1>
					{tokenPaypal?.access_token && (
						<button type='button' onClick={handleNewOrder}>
							Crear orden
						</button>
					)}
				</Grid>
				<Grid container item xs={6}>
					<h2>Columns</h2>
				</Grid>

				<Grid item xs={12}>
					<h2>Edit Event</h2>
					<form onSubmit={handleFormSubmit}>
						<div>
							<label htmlFor="image">Image:</label>
							<input
								type="file"
								id="image"
								accept="image/*"
								onChange={handleImageChange}
							/>
						</div>
						{/* Agrega otros campos de edición del evento aquí si es necesario */}
						{/* <div>
						<label htmlFor="title">Title:</label>
						<input type="text" id="title" />
						</div> */}
						{/* ... */}
						<button type="submit">Save</button>
					</form>
				</Grid>
			</Grid>
		</Box>
	)
}

export default TestScreen;
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { CustomizedCardContainer, Loader, TitleGeneralTickets } from '../../../componets';
import FormSingUpUser from './form';
import { GET_CODE_PHONES, SIGNUP_API } from '../../../api';
import { getMessageError } from '../../../api/conf';
import { colors } from '../../../values/colors';
import './styles.css';

const SingupScreen = () => {

  const [isEmailSend, setIsEmailSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingView, setIsLoadingView] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState(null);
  const [listCodeCountry, setCodeCountry] = useState([]);
  const [listCodeCountryTemp, setCodeCountryTemp] = useState([]);

  const signupUser = async (values, resetForm) => {
    console.log('signupUser::: ');
    setIsLoading(true);
    setEmail(values.email);
    await SIGNUP_API(
      values,
      (response) => singupOnSuccess(response, resetForm),
      onErrorSingupOnSuccess
    );
  };

  const onErrorSingupOnSuccess = (error) => {
    setIsLoading(false);
    const {
      response: {
        data: { message }
      }
    } = error;
    showAlertMessage(message || getMessageError(error));
  };

  const showAlertMessage = (message) => {
    setErrorMessage(message);
    setShowAlert(true);
  };

  const singupOnSuccess = async (response, resetForm) => {
    console.log('singupOnSuccess=> ' + JSON.stringify(response));
    setIsLoading(false);
    const {
      data: { success }
    } = response;
    if (success) {
      resetForm();
      setIsEmailSend(true);
    }
  };

  useEffect(() => {
    getCountryCode();
  }, []);

  const getCountryCode = async () => {
    setIsLoadingView(true);
    await GET_CODE_PHONES(onSuccessGetCountryCode, onErrorGetCountryCode, onDoneGetCountryCode);
  };

  const onSuccessGetCountryCode = (response) => {
    const { data } = response;
    setCodeCountry(data);
    setCodeCountryTemp(data);
    setIsLoadingView(false);
  };

  const onErrorGetCountryCode = (error) => {
    setIsLoading(false);
    const {
      response: {
        data: { message }
      }
    } = error;
    showAlertMessage(message || getMessageError(error));
    setIsLoadingView(false);
  };

  const onDoneGetCountryCode = () => {
    setIsLoading(false);
    setIsLoadingView(false);
  };

  if (isLoadingView) {
    return (
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Loader />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <>
      <Grid container className='containerRoot'>
        <Grid item sm={12} xs={12} md={8} lg={7} xl={7} className='containerData'>
          <CustomizedCardContainer width={isEmailSend ? '65%' : '85%'} padding={'6vh'} customBG={colors.gradientBG}>
            <FormSingUpUser
              isEmailSend={isEmailSend}
              email={email}
              actionForm={signupUser}
              errorMessage={errorMessage}
              showAlert={showAlert}
              isLoading={isLoading}
              listCodeCountry={listCodeCountry}
              listCodeCountryTemp={listCodeCountryTemp}
              setCodeCountryTemp={setCodeCountryTemp}
            />
          </CustomizedCardContainer>
        </Grid>
        <Grid item sm={12} xs={12} md={4} lg={5} xl={5} className='containerRowData'>
          <TitleGeneralTickets />
        </Grid>
      </Grid>
    </>
  );
};

export default SingupScreen;

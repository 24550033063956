import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { colors } from '../../values/colors';

const CustomizedTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '1.8rem',
    overflow: 'hidden',
    borderRadius: 50,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.mode === 'light' ? `${colors.white}` : '#2b2b2b',
    justifyContent: 'center',
    alignItems: 'center',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? `${colors.white}` : '#2b2b2b'
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.mode === 'light' ? `${colors.white}` : '#2b2b2b',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderColor: colors.white
    }
  },
  '& label.Mui-focused': {
    color: 'gray',
    fontFamily: 'Inter',
    fontSize: '1.8rem',
    textAlign: 'center'
  }
}));

export default CustomizedTextField;

import React from 'react';
import { Grid } from '@mui/material';
import { CustomizedTypography } from '../index';
import { colors } from '../../values/colors';
import './styles.css';

const CardHeaderTitle = ({ title }) => {

  return (
    <Grid item xs={12} sm={12} md={12} className='containerHeader'>
      <CustomizedTypography
        colors={colors.red}
        size={'2rem'}
        fontWeight={700}
        sx={{ textAlign: 'left' }}
        variant={'h4'}>
        {title}
      </CustomizedTypography>
    </Grid>
  );
};

export default CardHeaderTitle;

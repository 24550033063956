// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { Typography, MenuItem, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../values/colors';

const RoutesNavLinkMenuOptions = ({ pages, handleCloseNavMenu, selectedPage }) => {

  const goToNavigation = page => handleCloseNavMenu(page);

  return (
    <Grid container item xs={12} className='containerHeader'>
      <Grid item xs={12} sm={12} md={12} className='containerTitle'>
        {pages.map((page) => (
          <Link className={'link'} key={page.id} to={page.route}>
            <MenuItem key={page.id} onClick={() => goToNavigation(page)}>
              <Typography
                sx={{
                  my: 2,
                  mr: 1,
                  display: 'block',
                  fontFamily: 'Inter',
                  fontWeight: 700,
                  fontSize: '1.4rem'
                }}
                color={page?.id === selectedPage ? colors.redLight : colors.grayList}
                textAlign={'center'}>
                {page.name}
              </Typography>
            </MenuItem>
          </Link>
        ))}
      </Grid>
    </Grid>
  );
};

export default RoutesNavLinkMenuOptions;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

import { GridContainerEvent } from '../EventsScreen/styled';
import { CustomizedCardContainer, AlertCustom, CardHeaderDetailEvent } from '../../../componets';
import { GridLoader } from '../MyEvents/components';

import {
	Paper,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	Chip,
	Button,
	Typography
} from '@mui/material';

import { StyledPaper, StyledTableHead, StyledTableBody, StyledTableCell } from './components';

import { GET_ORDER_OF_MY_EVENTS, DEFAULT_ERROR_PROCESS } from '../../../api';

import { amountFormat } from '../../../utils/fuctionsGeneral';
import k from '../../../values/key';

const MyOrders = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();
	const auth = useAuth();

	const {state: { event }} = location;
	const { code, order_number } = params;
	const { user } = auth;

	console.log('location =>', location);
	console.log('code =>', code);
	console.log('order_number =>', order_number);

	const [isLoading, setIsLoading] = useState(true);
	const [showAlert, setShowAlert] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	
	const [orderList, setOrderList] = useState([]);

	useEffect(() => {

		GET_ORDER_OF_MY_EVENTS(
			user.id,
			code,
			successApi,
			errorApi,
			doneApi
		).then(
			(r) => console.log('api call =>', r)
		);
		
		return () => {
			null;
		}
	
	}, []);

	const successApi = (response) => {
		const {data: {data, success}} = response;
		console.log('data =>', data);
		if( data?.length > 1 ){
			setOrderList(data);
		} else {
			showOrderTransactions(data[0]);
		}
	}

	const errorApi = (error) => {
		console.log('errorApi =>');
		const msg = DEFAULT_ERROR_PROCESS(error);
		displayAlert(msg);
	};

	const doneApi = () => {
		console.log('doneApi =>');
		setIsLoading(false);
	};

	const displayAlert = (msg) => {
		setErrorMessage(msg);
		setShowAlert(true);
	}

	const showOrderTransactions = (order) => {
		navigate(
			`${k.navigation.myEvents}${code}/orders/${order.number}`,
			{
				// replace: true,
				state: {
					...location?.state,
					order: order
				}
			}
		);
	}

	const Body = () => (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<Typography variant="h3" align="center" gutterBottom>
				MIS COMPRAS
			</Typography>

			<CardHeaderDetailEvent 
				data={event} 
				colorTextCardF={'#302F2F'}
				customBgColor={'linear-gradient(45deg, rgba(184, 135, 10, 1) 0%, rgba(255, 206, 82, 1) 50%, rgba(245, 183, 24, 1) 100%)'}
			/>
			
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader aria-label="sticky table">
					<StyledTableHead>
						<TableRow>
							<TableCell> # </TableCell>
							<TableCell> Descripción </TableCell>
							<TableCell> Pendiente </TableCell>
							<TableCell> Total </TableCell>
							<TableCell> Estado </TableCell>
						</TableRow>
					</StyledTableHead>
					<StyledTableBody>
						{
							orderList.map( order => (
								<TableRow key={`row${order.id}`}>
									<StyledTableCell>
										<Button color='info' onClick={() => showOrderTransactions(order)}>
											{order.number}
										</Button>
									</StyledTableCell>
									<StyledTableCell>{order.resume}</StyledTableCell>
									<StyledTableCell align={'right'}>{amountFormat(order.pending_amount)}</StyledTableCell>
									<StyledTableCell align={'right'}>{amountFormat(order.total)}</StyledTableCell>
									<StyledTableCell>
										<Chip 
											label={(order.status === 5 ? 'Pagado' : 'Pendiente')} 
											sx={{
												bgcolor: (order.status === 5 ? '#66bb6a' : '#ffa726')
											}}
										/>
									</StyledTableCell>
								</TableRow>
							))
						}
					</StyledTableBody>
				</Table>
			</TableContainer>
		</Paper>
	)

	return (
		<GridContainerEvent container xs={12} sm={12}>
			<CustomizedCardContainer width={"98%"} isbgbackgroundblack={"true"} margin={'0'}>
				{showAlert && <AlertCustom errorMessage={errorMessage} />}
				{
					(isLoading) ? <GridLoader/> : <Body/>
				}
			</CustomizedCardContainer>
		</GridContainerEvent>
	)
}

export default MyOrders;
import React from 'react';
import { Grid } from '@mui/material';
import { logo, logoBoletera } from '../../assets';
import './styles.css';

const LoginNavigation = () => {

  return (
    <Grid container item xs={12} className='containerText'>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
        <img src={logoBoletera} className='imgTicket' />
        <img src={logo} className='imgLogoMatatena' />
      </Grid>
    </Grid>
  );
};

export default LoginNavigation;

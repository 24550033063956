import React from 'react';
import { Box, Card, Divider, Grid } from '@mui/material';
import useStylesSx from './styles';
import { colors } from '../../values/colors';

const HeaderCard = ({ children, bgColor, borderTop=false }) => {
  return (
    <Grid
      container
      item
      xs={12}
      sx={{ 
        ...useStylesSx.headerCard, 
        background: bgColor ? bgColor : colors.redLight,
        borderTopRightRadius: (borderTop) ? 16 : 0,
        borderTopLeftRadius: (borderTop) ? 16 : 0,
      }}
    >
      {children}
    </Grid>
  );
};

const BodyCard = ({ children, bgColor }) => {
  return (
    <Grid
      container
      item
      xs={12}
      sx={{ ...useStylesSx.bodyCard, background: bgColor ? bgColor : colors.white }}>
      <Box sx={{ maxWidth: '100%', width: '100%' }}>{children}</Box>
    </Grid>
  );
};

const CardRootCustom = ({ children, bgColor }) => {
  return (
    <Card
      variant={'outlined'}
      sx={{ ...useStylesSx.cardCustomRoot, background: bgColor ? bgColor : colors.whiteGray }}>
      {children}
    </Card>
  );
};

const CardSectionColumn = ({ children }) => {
  return (
    <Grid container item xs={12} sx={useStylesSx.containerSelector}>
      {children}
    </Grid>
  );
};

const DividerCustom = () => {
  return <Divider sx={useStylesSx.dividerCustom} />;
};

export { HeaderCard, BodyCard, CardRootCustom, CardSectionColumn, DividerCustom };

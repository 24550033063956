import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

import { GridContainerEvent } from '../EventsScreen/styled';
import { CustomizedCardContainer, AlertCustom, CardHeaderDetailEvent, SelectComponent, CustomizedButton} from '../../../componets';
import { GridLoader } from '../MyEvents/components';
import { TitleEvent, SubtitleTitleEvent, EventDivider } from '../DetailsEventScreen';
import { StyledPaper, StyledTableHead, StyledTableBody, StyledTableCell } from '../MyOrders/components';

import { useCommission } from '../EventsScreen/hooks/useCommission';
import { colors } from '../../../values/colors';
import k from '../../../values/key';

import { 
	Grid, 
	Paper,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	Chip,
	Typography,
	RadioGroup,
	FormControlLabel,
	Radio
} from '@mui/material';


import { GET_ORDER_TRANSACTIONS, PAYMENT_EVENT_ORDER_API, PAYMENT_EVENT_API, GET_EVENT_PAY_METHODS, DEFAULT_ERROR_PROCESS } from '../../../api';
import { amountFormat, dateFormatDateTimeMX } from '../../../utils/fuctionsGeneral';

const dataPartial = [
	{ id: 0, name: 'Monto completo' },
	{ id: 2, name: '2 pagos' },
	{ id: 3, name: '3 pagos' },
	{ id: 4, name: '4 pagos' }
];

const special_BGColor = 'linear-gradient(45deg, rgba(184, 135, 10, 1) 0%, rgba(255, 206, 82, 1) 50%, rgba(245, 183, 24, 1) 100%)';
const specialColor = '#302F2F';

const MyTransactions = () => {
	const location = useLocation();
	const {state: {event, order}} = location;

	// location?.state?.event?.pay_methods
	// location?.state?.order
	

	const navigate = useNavigate();
	// const params = useParams();
	const { code, order_number } = useParams();
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(true);
	const [showAlert, setShowAlert] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	
	const [transactionList, setTransactionList] = useState([]);

	const [selectedMethod, setSelectedMethod] = useState(null);
	const [payMethods, setPayMethods] = useState([]);

	const [checkSelectedPartial, setCheckSelectedPartial] = useState(false);
	const [valuePartial, setValuePartial] = useState(dataPartial[0].id);
	const [paymentData, setPaymentData] = useState({amount: 0, taxes: 0, total: 0});

	const { calcularComision, calcularMontoRecibido, calcularMontoACobrar, roundNumber } = useCommission();

	useEffect(() => {

		GET_ORDER_TRANSACTIONS(
			order_number, 
			successTransactionList, 
			errorApi, 
			doneApi
		).then(
			(r) => console.log('api call =>', r)
		);

		if(order?.status != 5){
			GET_EVENT_PAY_METHODS(
				code,
				successEventPayment,
				errorApi,
				doneApi
			).then(
				(r) => console.log('api call2 =>', r)
			);
		}

		return () => {
			null;
		}

		}, 
		[]
	);

	const successTransactionList = (response) => {
		const {data: {data, success}} = response;
		setTransactionList(data);
	}

	const errorApi = (error) => {
		const msg = DEFAULT_ERROR_PROCESS(error);
		displayAlert(msg);
	};

	const doneApi = () => {
		setIsLoading(false);
	};

	const successEventPayment = (response) => {
		const { data: {success, data} } = response;
		
		setPayMethods(data);
		setSelectedMethod(selectedMethod || data[0]);
		
		calcCommission(valuePartial || dataPartial[0].id, selectedMethod?.id || data[0]);
	}

	const displayAlert = (msg) => {
		setErrorMessage(msg);
		setShowAlert(true);
	}
	
	const handleNewSelect = (event) => {
		setPaymentData(calcCommission(event.target.value, selectedMethod));
		setValuePartial(event.target.value);
	}

	const handleMethodChange = (event) => {
		const new_method = payMethods.find( item => item.id == event.target.value);
		setPaymentData(calcCommission(valuePartial, new_method));
		setSelectedMethod(new_method);
	};

	const calcCommission = (partials, current_method) => {
		const amount = (partials > 1) ? order.pending_amount / partials : order.pending_amount;
		
		const grandTotal = calcularMontoACobrar(
			amount,
			current_method.commission_percent,
			current_method.fixed_commission,
			current_method.taxes_on_commission,
			current_method.accept_cents
		);

		return {
			amount, 
			taxes: roundNumber(grandTotal - amount), 
			total: grandTotal
		}
	}

	const handleClickPay = (event) => { 
		console.log('handleClickPay =>', event);
		setIsLoading(true);
		const data = {
			amount: paymentData.amount,
			pay_provider: selectedMethod.id
		};
		PAYMENT_EVENT_ORDER_API(
			order_number,
			data,
			successAddPay,
			errorApi,
			doneApi
		).then(
			(r) => console.log('api call2 =>', r)
		);
	}

	const successAddPay = (response) => { 
		const { data: { data, success } } = response;
		console.log('response =>', response);
		console.log('data =>', data);
		if(success){
			navigate(k.navigation.paymentOrder, {
				state: {
					...location?.state,
					isScreenMain: true,
					dataSales: data,
				}
			});
		}
	}

	return (
		<GridContainerEvent container xs={12} sm={12}>
			<CustomizedCardContainer width={"98%"} isbgbackgroundblack={"true"} margin={'0'}>
				{showAlert && <AlertCustom errorMessage={errorMessage} />}
				{
					(isLoading) ? 
					<GridLoader/> : 
					<Grid container item xs={12} spacing={2}>
						<Grid item xs={12} sm={4} md={3} sx={{borderRadius: 16, background: 'transparent'}}>
							<StyledPaper>
								<CardHeaderDetailEvent 
									data={event} 
									colorTextCardF={specialColor}
									customBgColor={special_BGColor}
									rounded
								/>
								<Grid container xs={12} spacing={2} sx={{padding: 4}}>
									<TitleEvent size={'1.2rem'} title={'Detalles de compra'}/>
									
									<Grid container item xs={12}>
										<Grid item xs={6} className="containerRowShopping">
											<SubtitleTitleEvent subtitle={order.resume} />
										</Grid>
										<Grid item xs={6} className="containerRowRight">
											<SubtitleTitleEvent subtitle={amountFormat(order.total)} />
										</Grid>
									</Grid>
									<Grid container item xs={12}>
										<Grid item xs={6} className="containerRowShopping">
											<SubtitleTitleEvent subtitle="Pagos" />
										</Grid>
										<Grid item xs={6} className="containerRowRight">
											<SubtitleTitleEvent subtitle={amountFormat(order.total - order.pending_amount)} />
										</Grid>
									</Grid>
									
									<Grid container item xs={12}>
										<Grid item xs={6} className="containerRowShopping">
											<SubtitleTitleEvent subtitle="Monto restante" />
										</Grid>
										<Grid item xs={6} className="containerRowRight">
											<SubtitleTitleEvent subtitle={amountFormat(order.pending_amount)} />
										</Grid>
									</Grid>

									<EventDivider/>

									<TitleEvent size={'1.2rem'} title={'Pago pendiente'}/>

									<Grid container item xs={12}>
										<Grid item xs={6} className="containerRowShopping">
											<SubtitleTitleEvent subtitle="Monto restante" />
										</Grid>
										<Grid item xs={6} className="containerRowRight">
											<SubtitleTitleEvent subtitle={amountFormat(paymentData.amount)} />
										</Grid>
									</Grid>
									{
										selectedMethod?.id !== undefined && (
											<>
												<Grid container item xs={12}>
													<Grid item xs={6} className="containerRowShopping">
														<SubtitleTitleEvent subtitle={'Cargo por servicios'} />
													</Grid>
													<Grid item xs={6} className="containerRowRight">
														<SubtitleTitleEvent subtitle={amountFormat(paymentData.taxes)} />
													</Grid>
												</Grid>
												<Grid container item xs={12}>
													<Grid item xs={6} className="containerRowShopping">
														<SubtitleTitleEvent subtitle={'Total'} />
													</Grid>
													<Grid item xs={6} className="containerRowRight">
														<SubtitleTitleEvent subtitle={amountFormat(paymentData.total)} />
													</Grid>
												</Grid>
											</>
										)
									}

								</Grid>

								{
									(order?.status != 5 && payMethods?.length > 0) && (
										<Grid container xs={12} sx={{padding: 1, pb:4}}>
											<Grid item xs={12} sx={{pt: 1}}>
												<SubtitleTitleEvent
													fontSize={'1rem'}
													color={colors.black}
													subtitle={'Monto a pagar'}
													sx={{
														textAlign: 'center',
														align: 'center'
													}}
												/>
											</Grid>
											<Grid item xs={12} sx={{pb: 1}}>
												<SelectComponent
													widthSelect={'100%'}
													colorValue={colors.black}
													data={dataPartial}
													isRow={true}
													valor={valuePartial}
													handleChangeSelect={handleNewSelect}
												/>
											</Grid>

											<Grid item xs={12} sx={{pt: 1}}>
												<SubtitleTitleEvent
													fontSize={'1rem'}
													color={colors.black}
													subtitle={'Elige el método de pago'}
													sx={{
														textAlign: 'center',
														align: 'center'
													}}
												/>
											</Grid>
											<Grid item xs={12} sx={{pb: 1}}>
												<RadioGroup
													aria-label="Payment Method"
													name="paymentMethod"
													value={selectedMethod?.id}
													onChange={handleMethodChange}
													row
												>
													{
													payMethods.map(method=>(
														<FormControlLabel
															id={`method${method.id}`}
															value={method.id}
															control={<Radio checked={method.id === selectedMethod?.id}/>}
															label={
																<div className='labelContainer'>
																	<img src={method.icon} alt="Credit Card" className='labelImg'/>
																</div>
															}
														/>
													))
													}
												</RadioGroup>
											</Grid>

											<Grid container item xs={12} sx={{padding: 1}}>
												<CustomizedButton
													bgcolor={special_BGColor}
													onClick={handleClickPay}
													maxwidth={'100%'}
													colortext={specialColor}
													variant={'contained'}
												>
													{`Pagar ${amountFormat(paymentData.total)}`}
												</CustomizedButton>
											</Grid>
										</Grid>
									)
								}
							</StyledPaper>
						</Grid>
						<Grid item xs={12} sm={8} md={9}>
							<Typography variant="h3" align="center" gutterBottom>
								RESUMEN DE PAGOS
							</Typography>
							<Paper sx={{ width: '100%', overflow: 'hidden' }}>
								<TableContainer sx={{ maxHeight: 440 }}>
									<Table stickyHeader aria-label="sticky table">
										<StyledTableHead>
											<TableRow>
												<TableCell> Referencia </TableCell>
												<TableCell> Fecha </TableCell>
												<TableCell> Cantidad </TableCell>
												<TableCell> Estado </TableCell>
											</TableRow>
										</StyledTableHead>
										<StyledTableBody>
											{
												transactionList.map( transaction => {
													let color = '';
													let label = '';

													// expirado 16
													// aprobado 9
													// declinado 10
													// error 11
													// pending 3

													switch (transaction.status) {
														case 9:
															color = '#66bb6a';
															label = 'Aprobado';
															break;
															
														case 10:
															color = '#f44336';
															label = 'Declinado';
															break;
														
														case 11:
															color = '#f44336';
															label = 'Error';
															break;
													
														default:
															color = '#ffa726';
															label = 'Pendiente';
															break;
													}

													return (
														<TableRow key={`row${transaction.id}`}>
															<StyledTableCell>{transaction.reference}</StyledTableCell>
															<StyledTableCell>{dateFormatDateTimeMX(transaction.created_at)}</StyledTableCell>
															<StyledTableCell>{amountFormat(transaction.amount)}</StyledTableCell>
															<StyledTableCell>
																<Chip label={label} sx={{bgcolor: color}}/>
															</StyledTableCell>
														</TableRow>
													)
												})
											}
										</StyledTableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Grid>
					</Grid>
				}
			</CustomizedCardContainer>
		</GridContainerEvent>
	)
}

export default MyTransactions;
import { colors } from '../../values/colors';

const useStylesSx = {
  bodyCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    //alignItems: 'flex-start',
    p: 2
  },
  headerCard: {
    p: 1
  },
  cardCustomRoot: {
    borderRadius: 4,
    boxShadow: '0px 6px 13px rgba(0, 0, 0, 0.27)'
  },
  containerSelector: {
    display: 'flex',
    background: 'transparent',
    mt: 1,
    mb: 1
  },
  dividerCustom: {
    mb: 2.5,
    mt: 1.5,
    bgcolor: colors.grayBlack,
    border: '1px solid rgba(0, 0, 0, 0.3)'
  }
};

export default useStylesSx;

import React, { useState, useContext, useEffect, createContext } from 'react';
import k from '../values/key';

// Creamos el contexto;
const AuthContext = createContext();

// Hook para acceder al contexto
const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loader, showLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userLogged = localStorage.getItem(k.storage.session);
    if (userLogged) {
      setIsLoading(true);
      const userLoggedParse = JSON.parse(userLogged);
      console.log('userLoggedParse: ' + JSON.stringify(userLoggedParse));
      setUser(userLoggedParse);
      showLoader(false);
    } else {
      showLoader(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user: user, isLoading: isLoading }}>
      {!loader && children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext, useAuth };

import React, { useEffect, useState } from 'react';

import { 
	Grid, 
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from '@mui/material';

import {GridColumnAllCenter, GridRowAllCenter, Account, GridLoader} from './components';

import { GridContainerEvent } from '../EventsScreen/styled';
import { 
	CustomizedCardContainer, 
	AlertCustom, 
	CardHeaderDetailEvent
} from '../../../componets';

import ItemEvent from '../EventsScreen/ItemEvent';

import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { GET_MY_EVENTS, DEFAULT_ERROR_PROCESS } from '../../../api';
import k from '../../../values/key';

const MyEvents = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(true);
	const [showAlert, setShowAlert] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [eventList, setEventList] = useState([]);
	const [filteredEventList, setFilteredEventList] = useState([]);

	console.log('user =>', user);
	console.log('params =>', params);
	
	useEffect(() => {
		setIsLoading(true);
		GET_MY_EVENTS(
			user.id,
			successApi,
			errorApi,
			doneApi
		).then(
			(r) => console.log('api call =>', r)
		);
		
		return () => {
			null;
		}
	}, []);

	const displayAlert = (msg) => {
		setErrorMessage(msg);
		setShowAlert(true);
	}

	const successApi = (response) => {
		console.log('successApi =>', response);
		const {data: {success, data, message}} = response;
		console.log('data =>', data);
		if(success){
			setEventList(data);
			setFilteredEventList(data);
		} else {
			if(message){
				displayAlert(message);
			}
		}
	}

	const errorApi = (error) => {
		const msg = DEFAULT_ERROR_PROCESS(error);
		displayAlert(msg);
	};

	const doneApi = () => {
		setIsLoading(false);
	};

	const showOrders = (event) => {
		console.log('click =>', event);
		navigate(
			`${k.navigation.myEvents}${event.code}`,
			{
				// replace: true,
				state: {
					event: event,
				}
			}
		);
	}

	const searchEvent = (text) => { 
		setFilteredEventList( eventList.filter(event => event.title.toLowerCase().includes(text.toLowerCase())));
	}

	const searchClear = () => { 
		setFilteredEventList(eventList);
	}

	return (
		<GridContainerEvent container xs={12} sm={12}>
			<CustomizedCardContainer width={"98%"} isbgbackgroundblack={"true"} margin={'0'}>
				{showAlert && <AlertCustom errorMessage={errorMessage} />}
				{
					(isLoading) ? 
					<GridLoader/> : 
					<Grid container item xs={12}>
						<GridColumnAllCenter item xs={12} sm={4} md={3}>
							<Account user={user} onSearchChange={searchEvent} onSearchClear={searchClear}/>
						</GridColumnAllCenter>
						<Grid container item xs={12} sm={8} md={9} spacing={2}>
							{
								filteredEventList.map(
									(row) => (
										<ItemEvent 
											action={() => showOrders(row)}
											buttonBgColor={'#321D6C'}
											buttonTextColor={'#FFFFFF'}
											customActionText={'Ver detalles'}
											customBgColor={'linear-gradient(45deg, rgba(184, 135, 10, 1) 0%, rgba(255, 206, 82, 1) 50%, rgba(245, 183, 24, 1) 100%)'}
											customTextColor={'#302F2F'}
											data={row}
											forceEnableButton
											key={`myEvent_${row.id}`}
										/>
									)
								)
							}
						</Grid>
					</Grid>
				}
			</CustomizedCardContainer>
		</GridContainerEvent>
	)
}

export default MyEvents;
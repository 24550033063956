import * as React from 'react';
import { Grid, Box, Skeleton } from '@mui/material';

const LoaderSkeleton = ({ showHeader }) => {
  return (
    <Grid
      sx={{
        display: 'flex',
        background: 'transparent',
        justifyContent: 'flex-start',
        alignItems: 'center',
        zIndex: 200
      }}
      container
      wrap={'wrap'}>
      <Grid container item xs={12}>
        {showHeader && (
          <Grid item xs={12} sm={12}>
            <Box>
              <Skeleton width={'100%'} height={100} />
            </Box>
          </Grid>
        )}
        {showHeader && (
          <Grid item xs={12} sm={6} sx={{ pt: 0.5 }}>
            <Box sx={{ pt: 0.5 }}>
              <Skeleton width={'70%'} height={30} />
              <Skeleton width={'30%'} height={10} />
            </Box>
          </Grid>
        )}
        {showHeader && (
          <Grid item xs={12} sm={6}>
            <Box sx={{ pt: 0.5 }}>
              <Skeleton variant={'rectangular'} width={'100%'} height={30} />
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        wrap={'wrap'}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          alignSelf: 'center',
          background: 'transparent'
        }}>
        {Array.from(new Array(5)).map((item, index) => (
          // eslint-disable-next-line react/jsx-key
          <Grid key={index} item xs={12} md={6} sm={12} lg={4} xl={3}>
            <Box key={index} sx={{ marginLeft: 2, maxWidth: 360, my: 2 }}>
              <Skeleton
                sx={{ maxWidth: '100%', width: 360, height: 150, borderRadius: 6 }}
                variant={'rectangular'}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default LoaderSkeleton;

import React from 'react';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

const SimpleLoadingButton = styled(LoadingButton)(
	({ theme, ...props }) => ({
		borderRadius: '50px',
		boxShadow: 'none',
		fontFamily: 'Inter',
		fontSize: '1.3rem',
		fontStyle: 'normal',
		fontWeight: '700',
		lineHeight: 1.5,
		maxWidth: props.buttonWidth || '11.75rem',
		padding: 5,
		textTransform: 'none',
		width: props.buttonWidth || '11.75rem',
	})
)

export default SimpleLoadingButton;
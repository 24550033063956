import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import NavigationRoot from './navigations';
import { AuthProvider } from './contexts/AuthContext';

import { HeaderFabIcon } from './componets';
import { HelmetProvider } from 'react-helmet-async';
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Inter', 'san-serif']
  }
});

const helmetContext = {};

console.log('process.env.REACT_APP_ENV =>', process.env.REACT_APP_ENV);
if (process.env.REACT_APP_ENV === "production"){
  console.log = function no_console() {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <HelmetProvider context={helmetContext}>
        <HeaderFabIcon title="Graduaciones - Matatena"/>
        <NavigationRoot />
      </HelmetProvider>
    </AuthProvider>
  </React.StrictMode>
);

import React from 'react';
import MainNavigation from './MainNavigation';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import lightTheme from '../values/theme/light';
import darkTheme from '../values/theme/dark';
import boleteraTheme from '../values/theme/boletera';
import { colors } from '../values/colors';

const isDark = false;
/* const theme2 = createTheme({
  palette: {
	mode: isDark ? 'dark' : 'light',
	...(isDark ? darkTheme : lightTheme)
  }
}); */

const theme2 = createTheme({
  ...boleteraTheme
});

const theme = createTheme(theme2,{
	myStyles: {
		customGrid: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: 'transparent',
			justifyContent: 'flex-start',
			alignItems: 'center',
			[theme2.breakpoints.down('sm')]: {
				justifyContent: 'center'
			}
		},
		containerLoader: {
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		cardRoot: {
			flexGrow: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			maxWidth: '100%',
			background: 'transparent'
		},
		containerRoot: {
			justifyContent: 'center',
			alignItems: 'center',
			background: 'transparent',
			maxWidth: '100%',
			[theme2.breakpoints.down('sm')]: {
				maxWidth: '100%',
				padding: 5
			}
		},
		containerBodyCard: {
			display: 'flex',
			flexDirection: 'row',
			padding: '1.4rem',
			background: colors.white
		}
	}
});


const NavigationRoot = () => {
  return <ThemeProvider theme={theme}>
	<MainNavigation />
  </ThemeProvider>
};

export default NavigationRoot;

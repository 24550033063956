export default {
  login: 'Iniciar sesión',
  fieldRequired: 'Este campo es obligatorio',
  typeEmailRequired: 'Escribe un correo valido',
  maxLengthInput: 'La longitud máxima de este campo es de ',
  maxLengthInputMin: 'La longitud minima de este campo es de ',
  caracteres: ' carateres',
  password: 'Contraseña *',
  confirmPassword: 'Confirma tu contraseña *',
  errorConfirmPassword: 'Las contraseñas no coinciden',
  email: 'Correo *',
  phone: 'Teléfono *',
  country: 'Codigo *',
  name: 'Nombre (s) *',
  lastName: 'Apellidos *',
  motherName: 'Apellido materno *',
  requiredField: '*Campos obligatorios',
  enter: 'Entrar',
  singup: 'Crear cuenta',
  nameComplete: 'Nombre Completo',
  close: 'Cerrar sesión',
  code: 'Código o Nombre del evento',
  amount: 'Monto',
  codeEvent: 'Ingresa el código o nombre del evento',
  validCode: 'Buscar evento',
  errorGeneral: 'Ocurrió un error interno: ',
  networkState: 'Ocurrió un problema con la conexión ',
  internalError: 'Error interno',
  notSupport: 'Método no soportados',
  continuePay: 'Continaur con el pago completo',
  paymentsPartial: 'Pagar boletos por parcialidades',
  cancel: 'Cancelar',
  pay: 'Continuar',
  noMoreBalance: 'Ingresa un monto menor a ',
  onlyNumbers: 'Este campo no permite caracteres especiales (.,%$#-@).',
  detailEvent: 'Detalle del evento',
  selectDinner: 'Selecciona los boletos de ',
  selectAfter: 'Selecciona los boletos de after',
  qualityTicketDinner: ' Boletos para cena: ',
  priceTotalTickerDiner: ' Precio total de boletos para cena: ',
  qualityTicketAfter: ' Boletos para after ',
  tickets: 'Boletos de ',
  ticket1: 'Boleto de ',
  titleTicket: 'Boletos',
  ticketTitle: 'Boleto',
  priceTicket: 'Precio total de boletos para after: ',
  serviceProcess: 'Cargo por servicio: ',
  priceTotalService: 'Precio total sin cargo de servicio: ',
  total: 'Total a pagar',
  selectTicket: 'Selecciona tus boletos',
  costTicketDinner: 'Costo por boleto de cena: ',
  countTicketAfter: 'Costo por boleto de after : ',
  payTotalTicket: 'Pagar la totalidad de boletos',
  amountPayment: 'Ingresa el monto a pagar',
  place: 'Lugar del evento: ',
  dateEvent: 'Fecha y hora del evento: ',
  descriptions: 'Descripción del evento: ',
  ticket: 'Eventos',
  table: 'Reservar',
  orders: 'Compras',
  boletera: ' Boletera',
  singUpScreen: 'Registro',
  sendTickets: 'Enviar boletos por correo',
  amountPending: 'Monto pediente por pagar: ',
  amountTotal: 'Monto Total',
  totalTicket: 'Total de boletos comprados: ',
  detailsSales: 'Detalle de la orden',
  detailsOrder: 'Detalle de la orden',
  amountPaymentOrder: 'Monto pagado ',
  detailPay: 'Detalle del pago',
  amountTotalPaid: 'Monto total a pagar: ',
  amountPaid: 'Monto abonado: ',
  questionAccount: '¿Aún no tienes una cuenta? ',
  registre: 'Regístrate',
  terminus: 'Términos y condiciones',
  record: 'Recordar',
  acceptTermsAndConditions: 'Acepto los términos y condiciones'
};
